<template>
	<div>
	</div>
</template>

<script>

  import * as pdfMake from "pdfmake/build/pdfmake";
  import * as pdFonts from "pdfmake/build/vfs_fonts";
  pdfMake.vfs = pdFonts.pdfMake.vfs 

  import * as am4core from "@amcharts/amcharts4/core";

  export default {
    name: 'ReportsDownloader',
    data() {
      return {
        isDownloadLoading: false
      }
    },
    props: {
      reportsTitle: String,
	    documentTitle: String,
	    lowMediumIds: {
	    	type: Boolean,
	    	default: false
	    },
	    lowMediumRef: {
	    	type: String,
	    	default: ""
	    },
	    testListTitle: {
	    	type: Object,
	    	default() {
	    		return {}
	    	}
	    }
	},
    methods: {
      saveReportOfAllToPDF() {
      	this.isDownloadLoading = true;

        let graphContentTmp = [];
        let graphContent = [];
        let graphSize = "";
        let chartImage = "";
      	Object.entries(this.$parent.$refs).forEach(([chartRef, component]) => {
      		let chart = null;

      		if (Object.keys(this.testListTitle).length > 0 && Object.keys(this.testListTitle).includes(chartRef) || chartRef == this.lowMediumRef){
      			chart = null;
      		} else {
      			chart = component.$refs[component.chartId].chart;
      			chartImage = chart.exporting.getImage("svg")
      		}
      		
      		if (chartRef == this.lowMediumRef && this.lowMediumIds) {
      			chart = component.$refs[component.chartIdLow].chart; 
	      		chartImage = chart.exporting.getImage("svg")
	      		chart = component.$refs[component.chartIdMedium].chart; 
	      		chartImage = chart.exporting.getImage("svg")
	      		chart = component.$refs[component.chartIdHigh].chart; 
	      		chartImage = chart.exporting.getImage("svg")
	      		chart = component.$refs[component.chartIdVeryHigh].chart; 
	      		chartImage = chart.exporting.getImage("svg")
      		} 

          if (chart.className == "XYChart"){
            if (component.data.length >= 1 && component.data.length <= 3)
              graphSize = "single"
            else if (component.data.length >= 4)
              graphSize = "double"
          } else if (chart.className == "PieChart") {
            graphSize = "single"
          }

          graphContentTmp.push({
            title: component.graphTitle,
            className: chart.className,
            xAxisTitle: component.xAxesTitle,
            yAxisTitle: component.yAxesTitle,
            graphSize: graphSize,
            chartImage: chartImage
          });

      	});

      	if (Object.keys(this.testListTitle).length > 0) {
      		Object.entries(this.$parent.$refs).forEach(([chartRef, component]) => {
	      		if (Object.keys(this.testListTitle).includes(chartRef)) {
	      			if (component.data.length > 0){
		            component.data.forEach((test) => {
		              let graphTmp = component.$refs[component.chartId + test.id][0].chart; // note
                  let graphSize = "";
		              chartImage = graphTmp.exporting.getImage("svg");

                  if (component.className != "PieChart"){
                    if (component.data.length >= 1 && component.data.length <= 3)
                      graphSize = "single"
                    else if (component.data.length >= 4)
                      graphSize = "double"
                  } else {
                    graphSize = "single"
                  }

		              graphContentTmp.push({
                    title: this.testListTitle[chartRef] + test.id, 
                    question: test.question,
                    className: component.className,
                    xAxisTitle: component.xAxesTitle,
                    yAxisTitle: component.yAxesTitle,
                    graphSize: graphSize,
                    chartImage: chartImage
                  });
		            });
			        } 
	      		}
	      	});
      	}

        graphContent = graphContentTmp.sort((a, b) => (a.graphSize > b.graphSize) ? 1 : ((b.graphSize > a.graphSize) ? -1 : 0));

        const progradumLogoBase64 = this.$progradumLogoBase64;
        const progradumLetter = this.$progradumLetter;
        let date = new Date()
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        let hours = date.getHours()
        let minutes = date.getMinutes()
        let seconds = date.getSeconds()
        let currentDateTmp = day + "-" + month + "-" + year + " " + hours + ":" + minutes + ":" + seconds;
        const currentDate = this.formatDate(currentDateTmp);
        const companyName = this.$store.state.auth.user.company.name;
        const userName = this.$store.state.auth.user.username;
        const reportsTitle = this.reportsTitle;

        let currentAreaName = "";
        if (this.$store.state.auth.user.active_areas != undefined){
          const currentTabID = this.$session.get("tabID");
          if (currentTabID){
            const currentAreaId = this.$store.state.auth.user.active_areas[currentTabID];
            currentAreaName = this.$store.state.auth.user.areas.filter(area => parseInt(area.id) == parseInt(currentAreaId)).map(function(e){return e.name.toUpperCase()})[0]
          }
        }

        const documentTitle = this.documentTitle;
        const X_MARGIN = 42;
        let Y_MARGIN = 0;
        const NUM_MAX_CUADRANTS_FIRST_PAGE = 5;
        const NUM_MAX_CUADRANTS_OTHER_PAGES = 7;

        let graphImagesTmp = graphContent.map(function(e){ return e.chartImage; }); 

        Promise.all(graphImagesTmp).then((res) => {
          var doc = {
            pageSize: "A4",
            pageOrientation: "portrait",
            pageMargins: [30, 30, 30, 30],
            content: [],
            header: {
              image: progradumLogoBase64,
              fit: [150, 70],
              margin: [X_MARGIN - 10, 40, 0, 0],
            },
            footer: function (currentPage, pageCount) {
              return {
                columns: [
                  {
                    text: reportsTitle + " |  " + currentDate + " · " + companyName,
                    margin: [X_MARGIN, -10, 0, 0],
                    color: "grey",
                    fontSize: 10,
                  },
                  {
                    image: progradumLetter,
                    alignment: "right",
                    fit: [18, 18],
                    margin: [0, -3, -70, 0],
                  },
                  {
                    text: currentPage.toString() + "/" + pageCount,
                    alignment: "right",
                    color: "grey",
                    margin: [0, 0, 38, 0],
                    fontSize: 10,
                  },
                ],
              };
            },
          };

          doc.content.push({
            text: reportsTitle,
            fontSize: 12,
            absolutePosition: { x: X_MARGIN, y: 83 },
          });

          let table = {
            absolutePosition: { x: X_MARGIN, y: 116 },
            table: {
              widths: [0, 130, 355, 4],
              body: [
                [
                  {
                    border: [false, false, false, false],
                    text: " ",
                    fontSize: 0.01,
                  },
                  {
                    border: [false, false, false, false],
                    text: " ",
                    fontSize: 0.01,
                  },
                  {
                    border: [false, false, false, false],
                    text: " ",
                    fontSize: 0.01,
                  },
                  {
                    border: [false, false, false, false],
                    text: " ",
                    fontSize: 0.01,
                  },
                ],
                [
                  {
                    border: [false, false, false, false],
                    text: " ",
                    fontSize: 0.01,
                  },
                  {
                    border: [false, false, false, false],
                    text: "Date",
                    alignment: "right",
                    fontSize: 9,
                  },
                  {
                    border: [false, false, false, false],
                    canvas: [
                      {
                        type: "rect",
                        x: -3,
                        y: 0,
                        w: 320,
                        h: 11,
                        r: 3,
                        color: "#ffffff",
                      },
                    ],
                  },
                  {
                    border: [false, false, false, false],
                    text: " ",
                    fontSize: 0.01,
                  },
                ],
                [
                  {
                    border: [false, false, false, false],
                    text: " ",
                    fontSize: 0.01,
                  },
                  {
                    border: [false, false, false, false],

                    text: " ",
                    fontSize: 0.01,
                  },
                  {
                    border: [false, false, false, false],
                    text: " ",
                    fontSize: 0.01,
                  },
                  {
                    border: [false, false, false, false],
                    text: " ",
                    fontSize: 0.01,
                  },
                ],
                [
                  {
                    border: [false, false, false, false],
                    text: " ",
                    fontSize: 0.01,
                  },
                  {
                    border: [false, false, false, false],
                    text: "Company",
                    alignment: "right",
                    fontSize: 9,
                  },
                  {
                    border: [false, false, false, false],
                    canvas: [
                      {
                        type: "rect",
                        x: -3,
                        y: 0,
                        w: 320,
                        h: 11,
                        r: 3,
                        color: "#ffffff",
                      },
                    ],
                  },
                  {
                    border: [false, false, false, false],
                    text: " ",
                    fontSize: 0.01,
                  },
                ],
                [
                  {
                    border: [false, false, false, false],
                    text: " ",
                    fontSize: 0.01,
                  },
                  {
                    border: [false, false, false, false],
                    text: " ",
                    fontSize: 0.01,
                  },
                  {
                    border: [false, false, false, false],
                    text: " ",
                    fontSize: 0.01,
                  },
                  {
                    border: [false, false, false, false],
                    text: " ",
                    fontSize: 0.01,
                  },
                ],
                [
                  {
                    border: [false, false, false, false],
                    text: " ",
                    fontSize: 0.01,
                  },
                  {
                    border: [false, false, false, false],
                    text: "Requested by",
                    alignment: "right",
                    fontSize: 9,
                  },
                  {
                    border: [false, false, false, false],
                    canvas: [
                      {
                        type: "rect",
                        x: -3,
                        y: 0,
                        w: 320,
                        h: 11,
                        r: 3,
                        color: "#ffffff",
                      },
                    ],
                  },
                  {
                    border: [false, false, false, false],
                    text: " ",
                    fontSize: 0.01,
                  },
                ],
                [
                  {
                    border: [false, false, false, false],
                    text: " ",
                    fontSize: 0.01,
                  },
                  {
                    border: [false, false, false, false],
                    text: " ",
                    fontSize: 0.01,
                  },
                  {
                    border: [false, false, false, false],
                    text: " ",
                    fontSize: 0.01,
                  },
                  {
                    border: [false, false, false, false],
                    text: " ",
                    fontSize: 0.01,
                  },
                ],                
              ]
            }
          }

          if (currentAreaName != ""){
            let areaColumn = [
              {
                border: [false, false, false, false],
                text: " ",
                fontSize: 0.01,
              },
              {
                border: [false, false, false, false],
                text: "Area",
                alignment: "right",
                fontSize: 9,
              },
              {
                border: [false, false, false, false],
                canvas: [
                  {
                    type: "rect",
                    x: -3,
                    y: 0,
                    w: 320,
                    h: 11,
                    r: 3,
                    color: "#ffffff",
                  },
                ],
              },
              {
                border: [false, false, false, false],
                text: " ",
                fontSize: 0.01,
              },
            ]

            table.table.body.push(areaColumn)
          }

          doc.content.push(
            {
              absolutePosition: { x: X_MARGIN, y: 114 },
              canvas: [
                {
                  type: "rect",
                  x: 0,
                  y: 0,
                  w: 512,
                  h: currentAreaName != "" ? 100 : 75,
                  r: 5,
                  color: "#62c4e4",
                },
              ],
            },
            table
          );

          doc.content.push({
            absolutePosition: { x: X_MARGIN + 158, y: 124 },
            text: currentDate,
            fontSize: 9,
          });

          doc.content.push({
            absolutePosition: { x: X_MARGIN + 158, y: 145 },
            text: companyName,
            fontSize: 9,
          });
          
          doc.content.push({
            absolutePosition: { x: X_MARGIN + 158, y: 167 },
            text: userName,
            fontSize: 9,
          });
          
          if (currentAreaName != ""){
            Y_MARGIN = 22;
            doc.content.push({
              absolutePosition: { x: X_MARGIN + 158, y: 189 },
              text: currentAreaName,
              fontSize: 9,
            });
          }

          let graphCuadrantPosition = 0;
          let titleDotPosition = "";
          let titlePosition = "";
          let xAxisTitlePosition = "";
          let yAxisTitlePosition = "";
          let graphPosition = "";
          let firstPage = true;
          let nextPage = true;

          graphContent.forEach((content, index) => {
            if (res[index] != null) {

              if (graphCuadrantPosition > NUM_MAX_CUADRANTS_FIRST_PAGE) {
                graphCuadrantPosition = 0;
                firstPage = false;
                nextPage = true;
              }

              if (firstPage) {
                if ("single" == content.graphSize){
                  if (graphCuadrantPosition == 0 || graphCuadrantPosition == 1){
                    doc.content.push({
                      absolutePosition: { x: X_MARGIN, y: Y_MARGIN + 190 },
                      canvas: [
                        {
                          type: "line",
                          x1: 0,
                          y1: 20,
                          x2: 555 - X_MARGIN,
                          y2: 20,
                          lineWidth: 0.7,
                          lineColor: "black",
                        },
                      ],
                    });
                  }
                  
                  if (graphCuadrantPosition == 2 || graphCuadrantPosition == 3){
                    doc.content.push({
                      absolutePosition: { x: X_MARGIN, y: Y_MARGIN + 375 },
                      canvas: [
                        {
                          type: "line",
                          x1: 0,
                          y1: 20,
                          x2: 555 - X_MARGIN,
                          y2: 20,
                          lineWidth: 0.7,
                          lineColor: "black",
                        },
                      ],
                    });
                  }

                  if (graphCuadrantPosition == 4 || graphCuadrantPosition == 5){
                    doc.content.push({
                      absolutePosition: { x: X_MARGIN, y: Y_MARGIN + 553 },
                      canvas: [
                        {
                          type: "line",
                          x1: 0,
                          y1: 20,
                          x2: 555 - X_MARGIN,
                          y2: 20,
                          lineWidth: 0.7,
                          lineColor: "black",
                        },
                      ],
                    });
                  }

                  if (graphCuadrantPosition == 0 || graphCuadrantPosition == 1){
                    doc.content.push({
                      absolutePosition: { x: (565 + 30) / 2, y: Y_MARGIN + 210 },
                      canvas: [
                        {
                          type: "line",
                          x1: 0,
                          y1: 0,
                          x2: 0,
                          y2: 210,
                          lineWidth: 0.7,
                          lineColor: "black",
                        },
                      ],
                    });
                  }

                  if (graphCuadrantPosition == 2 || graphCuadrantPosition == 3){
                    doc.content.push({
                      absolutePosition: { x: (565 + 30) / 2, y: Y_MARGIN + 395 },
                      canvas: [
                        {
                          type: "line",
                          x1: 0,
                          y1: 0,
                          x2: 0,
                          y2: 178,
                          lineWidth: 0.7,
                          lineColor: "black",
                        },
                      ],
                    });
                  }

                  if (graphCuadrantPosition == 4 || graphCuadrantPosition == 5){
                    doc.content.push({
                      absolutePosition: { x: (565 + 30) / 2, y: Y_MARGIN + 395 },
                      canvas: [
                        {
                          type: "line",
                          x1: 0,
                          y1: 0,
                          x2: 0,
                          y2: 356,
                          lineWidth: 0.7,
                          lineColor: "black",
                        },
                      ],
                    });
                  }

                  if (0 == graphCuadrantPosition){
                    titleDotPosition = { x: X_MARGIN + 25, y: Y_MARGIN + 235 };
                    titlePosition = { x: X_MARGIN + 40, y: Y_MARGIN + 227 };
                    graphPosition = { x: X_MARGIN, y: Y_MARGIN + 262 };
                  } else if (1 == graphCuadrantPosition) {
                    titleDotPosition = { x: X_MARGIN + 555 / 2, y: Y_MARGIN + 235 };
                    titlePosition = { x: X_MARGIN + 555 / 2 + 15, y: Y_MARGIN + 227 };
                    graphPosition = { x: X_MARGIN + 555 / 2 - 10, y: Y_MARGIN + 262 };
                  } else if (2 == graphCuadrantPosition){
                    titleDotPosition = { x: X_MARGIN + 25, y: Y_MARGIN + 413 };
                    titlePosition = { x: X_MARGIN + 40, y: Y_MARGIN + 405 };
                    graphPosition = { x: X_MARGIN, y: Y_MARGIN + 440 };
                  } else if (3 == graphCuadrantPosition) {
                    titleDotPosition = { x: X_MARGIN + 555 / 2, y: Y_MARGIN + 413 };
                    titlePosition = { x: X_MARGIN + 555 / 2 + 15, y: Y_MARGIN + 405 };
                    graphPosition = { x: X_MARGIN + 555 / 2 - 10, y: Y_MARGIN + 440 };
                  } else if (4 == graphCuadrantPosition) {
                    titleDotPosition = { x: X_MARGIN + 15, y: Y_MARGIN + 591 };
                    titlePosition = { x: X_MARGIN + 30, y: Y_MARGIN + 583 };
                    graphPosition = { x: X_MARGIN, y: Y_MARGIN + 618 };
                  } else if (5 == graphCuadrantPosition) {
                    titleDotPosition = { x: X_MARGIN + 555 / 2, y: Y_MARGIN + 591 };
                    titlePosition = { x: X_MARGIN + 555 / 2 + 15, y: Y_MARGIN + 583 };
                    graphPosition = { x: X_MARGIN + 555 / 2 - 10, y: Y_MARGIN + 618 };
                  } 

                  doc.content.push({
                    absolutePosition: titleDotPosition,
                    canvas: [
                      {
                        type: "ellipse",
                        x: 0,
                        y: 0,
                        r1: 1,
                        r2: 1,
                        color: "red",
                      },
                    ],
                  });

                  if (content.title.includes(" in ")){
                    let title = content.title.split("in");
                    doc.content.push({
                      absolutePosition: titlePosition,
                      text: title[0],
                      fontSize: 11,
                      bold: true,
                    });

                    let titlePositionTmp = JSON.parse(JSON.stringify(titlePosition)); 
                    titlePositionTmp.y += 10;
                    doc.content.push({
                      absolutePosition: titlePositionTmp,
                      text: " in " + title[1],
                      fontSize: 11,
                      bold: true,
                    });
                  } else {
                    doc.content.push({
                      absolutePosition: titlePosition,
                      text: content.title,
                      fontSize: 11,
                      bold: true,
                    });
                  }
                  
                  if (content.className == "XYChart"){
                    doc.content.push({
                      absolutePosition: graphPosition,
                      image: res[index],
                      height: 120,
                      width: 250,
                    });
                  } else if (content.className == "PieChart") {
                    doc.content.push({
                      absolutePosition: graphPosition,
                      image: res[index],
                      height: 100,
                      width: 300,
                    });
                  }

                  if ("question" in content) {
                    doc.content.push({
                      absolutePosition: {
                        x: X_MARGIN + 40,
                        y: 405,
                      },
                      text: "Question: '" + content.question + "'",
                      fontSize: 15,
                      bold: true
                    }); 
                  }

                  if (graphCuadrantPosition == 4 || graphCuadrantPosition == 5) {
                    doc.content.push({
                      absolutePosition: { x: X_MARGIN, y: 731 },
                      canvas: [
                        {
                          type: "line",
                          x1: 0,
                          y1: 20,
                          x2: 555 - X_MARGIN,
                          y2: 20,
                          lineWidth: 0.7,
                          lineColor: "black",
                        },
                      ],
                    });
                  }

                  graphCuadrantPosition += 1;
                } else if ("double" == content.graphSize) {
                  doc.content.push({
                    absolutePosition: { x: X_MARGIN + 25, y: Y_MARGIN + 210 },
                    canvas: [
                      {
                        type: "ellipse",
                        x: 0,
                        y: 0,
                        r1: 1,
                        r2: 1,
                        color: "red",
                      },
                    ],
                  });

                  doc.content.push({
                    absolutePosition: { x: X_MARGIN + 40, y: Y_MARGIN + 203 },
                    text: content.title,
                    fontSize: 11,
                    bold: true,
                  });

                  doc.content.push({
                    absolutePosition: { x: X_MARGIN - 10, y: Y_MARGIN + 230 },
                    image: res[index],
                    height: 150,
                    width: 520
                  });

                  if ("question" in content) {
                    doc.content.push({
                      absolutePosition: {
                        x: X_MARGIN + 40,
                        y: 405,
                      },
                      text: "Question: '" + content.question + "'",
                      fontSize: 15,
                      bold: true
                    }); 
                  }

                  if (graphCuadrantPosition == 4 || graphCuadrantPosition == 5) {
                    let docObject = {
                      absolutePosition: { x: X_MARGIN, y: Y_MARGIN + 731 },
                      canvas: [
                        {
                          type: "line",
                          x1: 0,
                          y1: 20,
                          x2: 555 - X_MARGIN,
                          y2: 20,
                          lineWidth: 0.7,
                          lineColor: "black",
                        },
                      ],
                    }

                    if (graphCuadrantPosition == NUM_MAX_CUADRANTS_FIRST_PAGE)
                      docObject.pageBreak = "after";

                    doc.content.push(docObject);
                  }

                  graphCuadrantPosition += 2;
                }

              } else {
                if (nextPage){
                  graphCuadrantPosition = 0;
                  nextPage = false;
                  
                  doc.content.push({
                    absolutePosition: { x: X_MARGIN, y: 80 },
                    canvas: [
                      {
                        type: "line",
                        x1: 0,
                        y1: 20,
                        x2: 555 - X_MARGIN,
                        y2: 20,
                        lineWidth: 0.7,
                        lineColor: "black",
                      },
                    ],
                    pageBreak: "before",
                  });
                }

                doc.content.push({
                  absolutePosition: { x: X_MARGIN, y: 258 },
                  canvas: [
                    {
                      type: "line",
                      x1: 0,
                      y1: 20,
                      x2: 555 - X_MARGIN,
                      y2: 20,
                      lineWidth: 0.7,
                      lineColor: "black",
                    },
                  ],
                });
                doc.content.push({
                  absolutePosition: { x: (565 + 30) / 2, y: 100 },
                  canvas: [
                    {
                      type: "line",
                      x1: 0,
                      y1: 0,
                      x2: 0,
                      y2: 178,
                      lineWidth: 0.7,
                      lineColor: "black",
                    },
                  ],
                });

                if (graphContent.length > 8){
                  doc.content.push({
                    absolutePosition: { x: (565 + 30) / 2, y: 100 },
                    canvas: [
                      {
                        type: "line",
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 356,
                        lineWidth: 0.7,
                        lineColor: "black",
                      },
                    ],
                  });
                }

                if ("single" == content.graphSize){
                  if (0 == graphCuadrantPosition){
                    titleDotPosition = { x: X_MARGIN + 25, y: 118 };
                    titlePosition = { x: X_MARGIN + 40, y: 110 };
                    graphPosition = { x: X_MARGIN, y: 145 };
                  } else if (1 == graphCuadrantPosition) {
                    titleDotPosition = { x: X_MARGIN + 555 / 2, y: 118 };
                    titlePosition = { x: X_MARGIN + 555 / 2 + 15, y: 110 };
                    graphPosition = { x: X_MARGIN + 555 / 2 - 10, y: 145 };
                  } else if (2 == graphCuadrantPosition) {
                    titleDotPosition = { x: X_MARGIN + 25, y: 296 };
                    titlePosition = { x: X_MARGIN + 40, y: 288 };
                    graphPosition = { x: X_MARGIN, y: 323 };
                  } else if (3 == graphCuadrantPosition) {
                    titleDotPosition = { x: X_MARGIN + 555 / 2, y: 296 };
                    titlePosition = { x: X_MARGIN + 555 / 2 + 15, y: 288 };
                    graphPosition = { x: X_MARGIN + 555 / 2 - 10, y: 323 };
                  } else if (4 == graphCuadrantPosition) {
                  } else if (5 == graphCuadrantPosition) {
                  }

                  doc.content.push({
                    absolutePosition: titleDotPosition,
                    canvas: [
                      {
                        type: "ellipse",
                        x: 0,
                        y: 0,
                        r1: 1,
                        r2: 1,
                        color: "red",
                      },
                    ],
                  });

                  doc.content.push({
                    absolutePosition: titlePosition,
                    text: content.title,
                    fontSize: 11,
                    bold: true,
                  });

                  doc.content.push({
                    absolutePosition: graphPosition,
                    image: res[index],
                    height: 120,
                    width: 250,
                  });

                  if ("question" in content) {
                    doc.content.push({
                      absolutePosition: {
                        x: X_MARGIN + 40,
                        y: 405,
                      },
                      text: "Question: '" + content.question + "'",
                      fontSize: 15,
                      bold: true
                    }); 
                  }

                  graphCuadrantPosition += 1;
                } else if ("double" == graphSize[index]) {
                  doc.content.push({
                    absolutePosition: { x: X_MARGIN + 25, y: 413 },
                    canvas: [
                      {
                        type: "ellipse",
                        x: 0,
                        y: 0,
                        r1: 1,
                        r2: 1,
                        color: "red",
                      },
                    ],
                  });

                  doc.content.push({
                    absolutePosition: { x: X_MARGIN + 40, y: 405 },
                    text: content.title,
                    fontSize: 11,
                    bold: true,
                  });

                  doc.content.push({
                    image: res[index],
                    height: 120,
                    width: 540
                  });

                  if ("question" in content) {
                    doc.content.push({
                      absolutePosition: {
                        x: X_MARGIN + 40,
                        y: 405,
                      },
                      text: "Question: '" + content.question + "'",
                      fontSize: 15,
                      bold: true
                    }); 
                  }

                  if (graphCuadrantPosition >= 1) {
                    let docObject = {
                      absolutePosition: { x: X_MARGIN, y: 731 },
                      canvas: [
                        {
                          type: "line",
                          x1: 0,
                          y1: 20,
                          x2: 555 - X_MARGIN,
                          y2: 20,
                          lineWidth: 0.7,
                          lineColor: "black",
                        },
                      ],
                    }

                    if (graphPosition == NUM_MAX_CUADRANTS_OTHER_PAGES)
                      docObject.pageBreak = "after";

                    graphCuadrantPosition += 2;

                    doc.content.push(docObject);
                  }
                }
              }
            }
          })

          pdfMake.createPdf(doc).download(documentTitle);

          this.isDownloadLoading = false;
        });
      },
      formatDate (date) {
        var dateHourSplitSpace = date.split(' ')

        var dateSplit = dateHourSplitSpace[0].split('-')
        if (dateSplit[0].length === 1) dateSplit[0] = '0' + dateSplit[0]
        if (dateSplit[1].length === 1) dateSplit[1] = '0' + dateSplit[1]

        var hourSplit = dateHourSplitSpace[1].split(':')
        if (hourSplit[0].length === 1) hourSplit[0] = '0' + hourSplit[0]
        if (hourSplit[1].length === 1) hourSplit[1] = '0' + hourSplit[1]
        if (hourSplit[2].length === 1) hourSplit[2] = '0' + hourSplit[2]

        return dateSplit[0] + '-' + dateSplit[1] + '-' + dateSplit[2] + ' ' + hourSplit[0] + ':' + hourSplit[1] + ':' + hourSplit[2]
      }
    }
  };
</script>