<template>
    <div class="col-sm-12">
        <div class="row secondNavbar">
            <div class="col-5 links-container">
                <router-link :to="{ path: '/evaluation-360' }" class="btn btn-secondary float-right"><font-awesome-icon
                        icon="arrow-left" /> Back to BEI/360 Evaluation</router-link>
            </div>
            <div class="col-7 noMargin whiteColor">
                <router-link :to="{ path: '/modules' }" class="breadcumb"> Suite Tools > </router-link>
                <router-link :to="{ path: '/evaluation' }" class="breadcumb"> Evaluation > </router-link>
                <router-link :to="{ path: '/evaluation-360' }" class="breadcumb"> BEI/360 Evaluation > </router-link>
                <router-link :to="{ path: '/tests-ranking' }" class="final-breadcumb"> Ranking </router-link>
            </div>
        </div>

        <div class="col-sm-8 col-centered">
            <evaluation-360-ranking />
        </div>
    </div>
</template>

<script>
import Evaluation360Ranking from "@/components/SoftSkillsComponent/Ranking.vue";

export default {
    name: 'Ranking',
    components: {
        Evaluation360Ranking,
    }
};
</script>

<style scoped>
#app>.bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
}

/* .createClass{
        background: border-box;
        border-radius: 26px;
        padding: inherit;
        margin-top: 0.6%;
    	padding-right: 7px;
        padding-left: 7px;
    } */
</style>
