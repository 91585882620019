<template>
    <div v-if="dataSaving" class="text-center text-loading">
        <b-spinner class="align-middle analysis-spinner"></b-spinner>
        <strong class="text-loading-msg"> Saving data...</strong>
    </div>
    <div v-else-if="dataLoading" class="text-center text-loading">
        <b-spinner class="align-middle analysis-spinner"></b-spinner>
        <strong class="text-loading-msg"> Loading data...</strong>
    </div>
    <div v-else class="row technology-skills-div mt-2 mx-3">
        <div class="row container-fluid div-configuration px-0">
            <div class="col-2">
                <h6 class="order-title" style="margin-top: 10px !important;">Order competences by groups</h6>
                <div class="form-check form-switch dichotomic-switch div-dichotomic"
                    style="margin-top: 8px !important;"><input id="orderByGroupsCheckbox"
                        class="form-check-input checkbox-required" type="checkbox" v-model="byGroups"
                        :disabled="started ? true : false"></div>
            </div>
            <div class="col-3">
                <h6 class="order-title" style="margin-top: 10px !important;">Number of levels</h6>
                <b-input id="numLevelsSelect" class="input-num-levels div-dichotomic" type="number" v-model="numLevels"
                    min="1" max="99" :disabled="started ? true : false"></b-input>
                <b-button class="btn btn-info" v-b-tooltip.hover style="float: left;"
                    title="The number of levels is the same for all competences."><font-awesome-icon
                    icon="info-circle" /></b-button>
            </div>
            <div class="col-7 justify-content-end">
                <button v-if="!started" class="btn btn-primary start-button" type="button"
                    @click="openStartModal"><font-awesome-icon icon="play" class="white-color" /> Start</button>
                <button v-if="started" class="btn btn-primary save-configuration-button" type="button"
                    @click="handleSave"><font-awesome-icon icon="save" class="white-color" /> Save</button>
                <button v-if="started" class="btn btn-primary reset-button" type="button"
                    @click="showResetModal"><font-awesome-icon icon="undo" class="white-color" /> Reset</button>
            </div>
        </div>

        <div id="message" v-if="message !== ''" class="alert mt-3 mb-3"
            :class="successfull ? 'alert-success' : 'alert-danger'">
            {{ message }} <b-button @click="close" class="cross-button"><font-awesome-icon class="cross" icon="times" />
            </b-button>
        </div>

        <div class="row container-fluid px-0" v-if="!byGroups && started">
            <div class="col-sm-12 div-competences px-0">
                <div class="row px-0" v-for="(competence, index) in competencesWithoutGroups" :key="competence.id">
                    <div class="row d-flex align-items-center" style="margin-bottom: 15px;">
                        <div class="col-sm-1">
                            <p class="title-first">Competence {{ index + 1 }}</p>
                        </div>
                        <div class="col-sm-10">
                            <b-input v-if="competence.id !== undefined" class="input-competence"
                                v-model="competence.name" disabled></b-input>
                            <b-input v-else class="input-competence" v-model="competence.name"></b-input>
                        </div>
                        <div class="col-sm-1">
                            <button v-b-tooltip.hover title="Delete competence" class="btn btn-secondary"
                                @click="showDeleteModal(competence, index, 'competence')"
                                type="button"><font-awesome-icon class="trash-icon" icon="trash" /></button>
                            <button class="btn btn-secondary" type="button" v-b-toggle="'toggleC-' + index.toString()"
                                @click="changeShowCompetenceState(index)">
                                <font-awesome-icon style="display: block;" v-bind:id="'iconShowC-' + index.toString()"
                                    class="trash-icon" v-b-tooltip.hover title="Expand" icon="chevron-down" />
                                <font-awesome-icon style="display: none;"
                                    v-bind:id="'iconNotShowC-' + index.toString()" class="trash-icon" v-b-tooltip.hover
                                    title="Contract" icon="chevron-up" />
                            </button>
                        </div>
                    </div>
                    <b-collapse v-bind:id="'toggleC-' + index.toString()">
                        <div class="row mb-3">
                            <div class="col-sm-1">
                            </div>
                            <div class="col-sm-10">
                                <p class="title-description-competence">Description (Optional)</p>
                                <textarea class="input-description-competence" rows="3" v-model="competence.description"
                                    maxlength="512"></textarea>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-1">
                            </div>
                            <div class="col-sm-10">
                                <div class="row">
                                    <div class="col-sm-1"
                                        style="text-align: left !important; padding-left: 0 !important;">
                                        <p class="title-levels">Levels</p>
                                    </div>
                                    <div class="col-sm-11"></div>
                                </div>

                                <div class="row" v-for="(level, index_level) in competence.levels" :key="level.id">
                                    <div class="row d-flex align-items-center" style="margin-bottom: 15px;" v-if="index_level != 0">
                                        <div class="col-sm-1">
                                            <template v-if="level.id !== undefined">
                                                <p v-if="index_level != 0" class="title-level">Level {{
        index_level }}</p>
                                            </template>
                                            <template v-else>
                                                <p class="title-level">Level {{ index_level +
        1
                                                    }}</p>
                                            </template>
                                        </div>
                                        <div class="col-sm-10">
                                            <b-input v-if="level.id !== undefined" v-model="level.name"
                                                disabled></b-input>
                                            <b-input v-else v-model="level.name"></b-input>
                                        </div>
                                        <div class="col-sm-1" style="justify-content: right; display: flex;">
                                            <button class="btn btn-secondary" type="button"
                                                v-b-toggle="'toggleL-' + index.toString() + '-' + index_level.toString()"
                                                @click="changeShowLevelState(index, index_level)">
                                                <font-awesome-icon style="display: block;"
                                                    v-bind:id="'iconShowL-' + index.toString() + '-' + index_level.toString()"
                                                    class="trash-icon" v-b-tooltip.hover title="Expand"
                                                    icon="chevron-down" />
                                                <font-awesome-icon style="display: none;"
                                                    v-bind:id="'iconNotShowL-' + index.toString() + '-' + index_level.toString()"
                                                    class="trash-icon" v-b-tooltip.hover title="Contract"
                                                    icon="chevron-up" />
                                            </button>
                                        </div>
                                    </div>
                                    <b-collapse
                                        v-bind:id="'toggleL-' + index.toString() + '-' + index_level.toString()">
                                        <div class="row mb-3">
                                            <div class="col-sm-1">
                                            </div>
                                            <div class="col-sm-10">
                                                <p class="level-description">Description (Optional)</p>
                                                <textarea class="input-level-description" rows="3"
                                                    v-model="level.description"
                                                    style="width: 99% !important; float: left !important;"
                                                    maxlength="512"></textarea>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-1">
                                            </div>
                                            <div class="col-sm-10">
                                                <p class="level-description">Behavorial indicators (Optional)</p>
                                                <textarea class="input-level-description" rows="3"
                                                    v-model="level.behavioral_indicators"
                                                    style="width: 99% !important; float: left !important;"
                                                    maxlength="512"></textarea>
                                            </div>
                                        </div>
                                    </b-collapse>
                                </div>
                            </div>
                        </div>
                    </b-collapse>
                    <hr v-if="index != (competencesWithoutGroups.length - 1)">
                </div>

                <button class="btn btn-primary add-competence-button mb-3" type="button"
                    @click="addCompetence"><font-awesome-icon icon="plus" class="white-color" /> Add competence</button>
            </div>
        </div>

        <div class="row container-fluid px-0" v-if="byGroups && started">
            <div class="col-sm-12 div-competences px-0">
                <div class="row px-0" v-for="(group, index) in competencesByGroups" :key="group.id">
                    <div class="row d-flex align-items-center" style="margin-bottom: 15px;">
                        <div class="col-sm-1">
                            <p class="title-first">Group {{ index + 1 }}</p>
                        </div>
                        <div class="col-sm-10">
                            <b-input v-if="group.id !== undefined" v-model="group.name" disabled></b-input>
                            <b-input v-else v-model="group.name"></b-input>
                        </div>
                        <div class="col-sm-1">
                            <button v-b-tooltip.hover title="Delete group" class="btn btn-secondary"
                                @click="showDeleteModal(group, index, 'group')" type="button"><font-awesome-icon
                                    class="trash-icon" icon="trash" /></button>
                            <button class="btn btn-secondary" type="button" v-b-toggle="'toggleG-' + index.toString()"
                                @click="changeShowGroupState(index)">
                                <font-awesome-icon style="display: block;" v-bind:id="'iconShowG-' + index.toString()"
                                    class="trash-icon" v-b-tooltip.hover title="Expand" icon="chevron-down" />
                                <font-awesome-icon style="display: none;"
                                    v-bind:id="'iconNotShowG-' + index.toString()" class="trash-icon" v-b-tooltip.hover
                                    title="Contract" icon="chevron-up" />
                            </button>
                        </div>
                    </div>
                    <b-collapse v-bind:id="'toggleG-' + index.toString()">
                        <div class="row mb-3">
                            <div class="col-sm-1">
                            </div>
                            <div class="col-sm-10">
                                <p class="title-description-group">Description (Optional)</p>
                                <textarea class="input-description-group" rows="3" v-model="group.description"
                                    maxlength="512"></textarea>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-1">
                            </div>
                            <div class="col-sm-10">
                                <div class="row">
                                    <div class="col-sm-1">
                                        <p class="title-competences">Competences</p>
                                    </div>
                                    <!--<div class="col-sm-9"></div>-->
                                    <div class="col-sm-2">
                                        <button class="btn btn-primary add-competence-to-group-button mb-3"
                                            type="button" @click="addCompetenceToGroup(index)"><font-awesome-icon
                                                icon="plus" class="white-color" /> Add competence</button>
                                    </div>
                                </div>
                                <div class="row" v-for="(competence, index_competence) in group.competences"
                                    :key="competence.id">
                                    <div class="row d-flex align-items-center" style="margin-bottom: 15px;">
                                        <div class="col-sm-2">
                                            <p class="title-competence">Competence {{ index_competence + 1 }}</p>
                                        </div>
                                        <div class="col-sm-9">
                                            <b-input v-if="competence.id !== undefined" class="input-competence"
                                                v-model="competence.name" disabled></b-input>
                                            <b-input v-else class="input-competence"
                                                v-model="competence.name"></b-input>
                                        </div>
                                        <div class="col-sm-1" style="justify-content: right; display: flex;">
                                            <button v-b-tooltip.hover title="Delete competence"
                                                class="btn btn-secondary"
                                                @click="showDeleteCompetenceFromGroupModal(competence, index, index_competence, 'group competence')"
                                                type="button"><font-awesome-icon class="trash-icon"
                                                    icon="trash" /></button>
                                            <button class="btn btn-secondary" type="button"
                                                v-b-toggle="'toggleC-' + index.toString() + '-' + index_competence.toString()"
                                                @click="changeShowCompetenceFromGroupState(index, index_competence)">
                                                <font-awesome-icon style="display: block;"
                                                    v-bind:id="'iconShowC-' + index.toString() + '-' + index_competence.toString()"
                                                    class="trash-icon" v-b-tooltip.hover title="Expand"
                                                    icon="chevron-down" />
                                                <font-awesome-icon style="display: none;"
                                                    v-bind:id="'iconNotShowC-' + index.toString() + '-' + index_competence.toString()"
                                                    class="trash-icon" v-b-tooltip.hover title="Contract"
                                                    icon="chevron-up" />
                                            </button>
                                        </div>
                                    </div>
                                    <b-collapse
                                        v-bind:id="'toggleC-' + index.toString() + '-' + index_competence.toString()">
                                        <div class="row mb-3">
                                            <div class="col-sm-2">
                                            </div>
                                            <div class="col-sm-9">
                                                <p class="title-description-competence">Description (Optional)</p>
                                                <textarea class="input-description-competence" rows="3"
                                                    v-model="competence.description" maxlength="512"></textarea>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-2">
                                            </div>
                                            <div class="col-sm-9">
                                                <div class="row">
                                                    <div class="col-sm-1">
                                                        <p class="title-levels-competence">Levels</p>
                                                    </div>
                                                    <div class="col-sm-11"></div>
                                                </div>

                                                <div class="row" v-for="(level, index_level) in competence.levels"
                                                    :key="level.id">
                                                    <div class="row d-flex align-items-center" v-if="index_level != 0"
                                                        style="margin-bottom: 15px;">
                                                        <div class="col-sm-1">
                                                            <template v-if="level.id !== undefined">
                                                                <p v-if="index_level != 0"
                                                                    class="title-level-competence">Level {{
        index_level }}</p>
                                                            </template>
                                                            <template v-else>
                                                                <p class="title-level-competence">Level {{ index_level +
        1
                                                                    }}</p>
                                                            </template>
                                                        </div>
                                                        <div class="col-sm-10">
                                                            <b-input v-if="level.id !== undefined"
                                                                class="input-level-competence" v-model="level.name"
                                                                disabled></b-input>
                                                            <b-input v-else class="input-level-competence"
                                                                v-model="level.name"></b-input>
                                                        </div>
                                                        <div class="col-sm-1"
                                                            style="justify-content: right; display: flex;">
                                                            <button class="btn btn-secondary" type="button"
                                                                v-b-toggle="'toggleL-' + index.toString() + '-' + index_competence.toString() + '-' + index_level.toString()"
                                                                @click="changeShowLevelFromCompetenceFromGroupState(index, index_competence, index_level)">
                                                                <font-awesome-icon style="display: block;"
                                                                    v-bind:id="'iconShowL-' + index.toString() + '-' + index_competence.toString() + '-' + index_level.toString()"
                                                                    class="trash-icon" v-b-tooltip.hover title="Expand"
                                                                    icon="chevron-down" />
                                                                <font-awesome-icon style="display: none;"
                                                                    v-bind:id="'iconNotShowL-' + index.toString() + '-' + index_competence.toString() + '-' + index_level.toString()"
                                                                    class="trash-icon" v-b-tooltip.hover
                                                                    title="Contract" icon="chevron-up" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <b-collapse
                                                        v-bind:id="'toggleL-' + index.toString() + '-' + index_competence.toString() + '-' + index_level.toString()">
                                                        <div class="row mb-3">
                                                            <div class="col-sm-1">
                                                            </div>
                                                            <div class="col-sm-10">
                                                                <p class="level-description-competence">Description
                                                                    (Optional)</p>
                                                                <textarea class="input-level-description-competence"
                                                                    rows="3" v-model="level.description"
                                                                    maxlength="512"></textarea>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-3">
                                                            <div class="col-sm-1">
                                                            </div>
                                                            <div class="col-sm-10">
                                                                <p class="level-description-competence">Behavorial
                                                                    indicators (Optional)</p>
                                                                <textarea class="input-level-description-competence"
                                                                    rows="3" v-model="level.behavioral_indicators"
                                                                    maxlength="512"></textarea>
                                                            </div>
                                                        </div>
                                                    </b-collapse>
                                                </div>
                                            </div>
                                        </div>
                                    </b-collapse>
                                </div>
                            </div>
                        </div>
                    </b-collapse>
                    <hr v-if="index != (competencesByGroups.length - 1)">
                </div>

                <button class="btn btn-primary add-competence-button mb-3" type="button"
                    @click="addGroup"><font-awesome-icon icon="plus" class="white-color" /> Add group</button>
            </div>
        </div>

        <div class="form-group row justify-content-center mt-4 pb-4" v-if="started">
            <div class="col-sm-12 justify-content-center py-0">
                <button class="btn btn-primary table-selection-button save-changes-button"
                    style="float: right; margin-left: 0;" type="button" @click="scrollToTop"><font-awesome-icon
                        icon="arrow-up" class="white-color" /> Scroll to
                    top</button>
            </div>
        </div>

        <b-modal id="reset-modal" ref="reset-modal" title="Reset-modal" hide-footer>
            <template #modal-title>
                Reset all
            </template>
            <div>
                <p>Do you really want to reset all Soft Skills configuration? </p>
            </div>
            <input class="btn btn-primary form-submit" style="margin-right: 1%;" type="button"
                @click="$bvModal.hide('reset-modal')" value="No">
            <input class="btn btn-primary form-submit" type="button" @click="reset()" value="Yes">
        </b-modal>

        <b-modal id="delete-modal" ref="delete-modal" title="Delete-modal" hide-footer>
            <template #modal-title>
                Delete {{ itemToDelete }}
            </template>
            <div>
                <p>Do you really want to delete the selected {{ itemToDelete }}? </p>
            </div>
            <input class="btn btn-primary form-submit" style="margin-right: 1%;" type="button"
                @click="$bvModal.hide('delete-modal')" value="No">
            <input class="btn btn-primary form-submit" type="button" @click="deleteItem()" value="Yes">
        </b-modal>

        <b-modal id="delete-competence-from-group-modal" ref="delete-competence-from-group-modal"
            title="Delete-competence-from-group-modal" hide-footer>
            <template #modal-title>
                Delete {{ itemToDelete }}
            </template>
            <div>
                <p>Do you really want to delete the selected {{ itemToDelete }}? </p>
            </div>
            <input class="btn btn-primary form-submit" style="margin-right: 1%;" type="button"
                @click="$bvModal.hide('delete-competence-from-group-modal')" value="No">
            <input class="btn btn-primary form-submit" type="button" @click="deleteItem()" value="Yes">
        </b-modal>

        <b-modal id="start-modal" ref="start-modal"
            title="Start-modal" hide-footer>
            <template #modal-title>
                Start configuration
            </template>
            <div>
                <p>Once you start the configuration, you will not be able to change the number of levels or whether the competitions are divided into groups or not.</p>
            </div>
            <input class="btn btn-primary form-submit" style="margin-right: 1%;" type="button"
                @click="$bvModal.hide('start-modal')" value="Cancel">
            <input class="btn btn-primary form-submit" type="button" @click="start()" value="Start">
        </b-modal>
    </div>
</template>

<script>
import SoftskillsDataService from '@/services/SoftskillsDataService';
import Tiptap from '@/components/Others/Tiptap'

export default {
    name: 'SoftskillsConfigurationComponent',
    data() {
        return {
            dataLoading: false,
            competencesWithoutGroups: [],
            competencesByGroups: [],
            allSoftskillsItems: [],
            idsToDeactivate: [],
            byGroups: false,
            started: false,
            itemToDelete: '',
            selectedItem: null,
            selectedItemIndex: null,
            selectedItemIndexGroup: null,
            selectedItemIndexCompetence: null,
            numLevels: 1,
            successfull: false,
            dataSaving: false,
            message: "",
        }
    },
    components: {
        Tiptap
    },
    mounted() {
        this.dataLoading = true
        SoftskillsDataService.findCompanyCompetencesDirectory(this.$store.state.auth.user.id_company).then(
            response => {
                this.allSoftskillsItems = response.data

                console.log('response.data: ', response.data)

                if (this.allSoftskillsItems.length > 0) {
                    console.log('mayor que 0')
                    if (this.allSoftskillsItems[0].type === 'group') {
                        console.log('group')
                        this.byGroups = true
                        this.numLevels = this.allSoftskillsItems[0].competences[0].levels.length - 1

                        for (let j = 0; j < this.allSoftskillsItems.length; j++) {
                            let tmpCompetences = []
                            for (let i = 0; i < this.allSoftskillsItems[j].competences.length; i++) {
                                if (this.allSoftskillsItems[j].competences[i].is_active) {
                                    tmpCompetences.push(this.allSoftskillsItems[j].competences[i])
                                }
                            }

                            this.allSoftskillsItems[j].competences = tmpCompetences
                        }

                        this.competencesByGroups = this.allSoftskillsItems

                        this.started = true
                    } else if (this.allSoftskillsItems[0].type === 'competence') {
                        console.log('competence')
                        this.byGroups = false
                        this.numLevels = this.allSoftskillsItems[0].levels.length - 1

                        let tmpCompetences = []

                        for (let i = 0; i < this.allSoftskillsItems.length; i++) {
                            console.log('i: ', i)
                            if (this.allSoftskillsItems[i].is_active) {
                                console.log('active')
                                tmpCompetences.push(this.allSoftskillsItems[i])
                            }
                        }

                        this.allSoftskillsItems = tmpCompetences

                        this.competencesWithoutGroups = this.allSoftskillsItems

                        console.log('competencesWithoutGroups: ', this.competencesWithoutGroups)

                        this.started = true
                    }
                } else {
                    this.started = false
                }
                this.dataLoading = false
            },
            error => {
                console.log((error.response && error.response.data) ||
                    error.message ||
                    error.toString())

                if (error.response.status === 300) {
                    console.log('Logout: Token expired')
                    localStorage.removeItem('user');
                    localStorage.removeItem('company');
                    this.$store.state.auth.user = null
                    this.$store.dispatch('auth/logout');
                    this.$router.push('/login');
                }
                this.dataLoading = false
            }
        );
    },
    watch: {
        numLevels(val) {
            // console.log('val: ', val)
            // if (!this.byGroups) {
            //     for (let i = 0; i < this.competencesWithoutGroups.length; i++) {
            //         if (this.competencesWithoutGroups[i].levels.length > val) {
            //             while (this.competencesWithoutGroups[i].levels.length > val) {
            //                 this.competencesWithoutGroups[i].levels.pop()
            //             }
            //         } else if (this.competencesWithoutGroups[i].levels.length < val) {
            //             while (this.competencesWithoutGroups[i].levels.length < val) {
            //                 this.competencesWithoutGroups[i].levels.push({
            //                     id_company: this.$store.state.auth.user.id_company,
            //                     name: '',
            //                     type: 'level',
            //                     description: '',
            //                     behavioral_indicators: '',
            //                     order: 0
            //                 })
            //             }
            //         }
            //     }
            // }
        },
    },
    methods: {
        start() {
            this.started = true
        },
        openStartModal() {
            this.$refs['start-modal'].show();
        },
        changeShowGroupState(index) {
            if (document.getElementById('iconShowG-' + index.toString()).style.display == "block") {
                document.getElementById('iconShowG-' + index.toString()).style.display = "none"
                document.getElementById('iconNotShowG-' + index.toString()).style.display = "block"
            } else {
                document.getElementById('iconShowG-' + index.toString()).style.display = "block"
                document.getElementById('iconNotShowG-' + index.toString()).style.display = "none"
            }
        },
        changeShowCompetenceState(index) {
            if (document.getElementById('iconShowC-' + index.toString()).style.display == "block") {
                document.getElementById('iconShowC-' + index.toString()).style.display = "none"
                document.getElementById('iconNotShowC-' + index.toString()).style.display = "block"
            } else {
                document.getElementById('iconShowC-' + index.toString()).style.display = "block"
                document.getElementById('iconNotShowC-' + index.toString()).style.display = "none"
            }
        },
        changeShowCompetenceFromGroupState(index, index_competence) {
            if (document.getElementById('iconShowC-' + index.toString() + '-' + index_competence.toString()).style.display == "block") {
                document.getElementById('iconShowC-' + index.toString() + '-' + index_competence.toString()).style.display = "none"
                document.getElementById('iconNotShowC-' + index.toString() + '-' + index_competence.toString()).style.display = "block"
            } else {
                document.getElementById('iconShowC-' + index.toString() + '-' + index_competence.toString()).style.display = "block"
                document.getElementById('iconNotShowC-' + index.toString() + '-' + index_competence.toString()).style.display = "none"
            }
        },
        changeShowLevelState(index, index_level) {
            if (document.getElementById('iconShowL-' + index.toString() + '-' + index_level.toString()).style.display == "block") {
                document.getElementById('iconShowL-' + index.toString() + '-' + index_level.toString()).style.display = "none"
                document.getElementById('iconNotShowL-' + index.toString() + '-' + index_level.toString()).style.display = "block"
            } else {
                document.getElementById('iconShowL-' + index.toString() + '-' + index_level.toString()).style.display = "block"
                document.getElementById('iconNotShowL-' + index.toString() + '-' + index_level.toString()).style.display = "none"
            }
        },
        changeShowLevelFromCompetenceFromGroupState(index, index_competence, index_level) {
            if (document.getElementById('iconShowL-' + index.toString() + '-' + index_competence.toString() + '-' + index_level.toString()).style.display == "block") {
                document.getElementById('iconShowL-' + index.toString() + '-' + index_competence.toString() + '-' + index_level.toString()).style.display = "none"
                document.getElementById('iconNotShowL-' + index.toString() + '-' + index_competence.toString() + '-' + index_level.toString()).style.display = "block"
            } else {
                document.getElementById('iconShowL-' + index.toString() + '-' + index_competence.toString() + '-' + index_level.toString()).style.display = "block"
                document.getElementById('iconNotShowL-' + index.toString() + '-' + index_competence.toString() + '-' + index_level.toString()).style.display = "none"
            }
        },
        showDeleteModal(item, index, tipo) {
            this.itemToDelete = tipo
            this.selectedItem = item
            this.selectedItemIndex = index
            this.$refs['delete-modal'].show();
        },
        showDeleteCompetenceFromGroupModal(item, index_group, index_competence, tipo) {
            this.itemToDelete = tipo
            this.selectedItem = item
            this.selectedItemIndexGroup = index_group
            this.selectedItemIndexCompetence = index_competence
            this.$refs['delete-competence-from-group-modal'].show();
        },
        showResetModal() {
            this.$refs['reset-modal'].show();
        },
        deleteItem() {
            if (this.itemToDelete === 'group') {
                if (this.competencesByGroups[this.selectedItemIndex].id !== undefined)
                    this.idsToDeactivate.push(this.competencesByGroups[this.selectedItemIndex].id)

                this.competencesByGroups.splice(this.selectedItemIndex, 1)
                this.$refs['delete-modal'].hide();

                this.$forceUpdate()
            } else if (this.itemToDelete === 'group competence') {
                if (this.competencesByGroups[this.selectedItemIndexGroup].competences[this.selectedItemIndexCompetence].id !== undefined)
                    this.idsToDeactivate.push(this.competencesByGroups[this.selectedItemIndexGroup].competences[this.selectedItemIndexCompetence].id)

                this.competencesByGroups[this.selectedItemIndexGroup].competences.splice(this.selectedItemIndexCompetence, 1)
                this.$refs['delete-competence-from-group-modal'].hide();

                this.$forceUpdate()
            } else if (this.itemToDelete === 'competence') {
                if (this.competencesWithoutGroups[this.selectedItemIndex].id !== undefined)
                    this.idsToDeactivate.push(this.competencesWithoutGroups[this.selectedItemIndex].id)

                this.competencesWithoutGroups.splice(this.selectedItemIndex, 1)
                this.$refs['delete-modal'].hide();

                this.$forceUpdate()
            }
        },
        reset() {
            if (!this.byGroups) {
                this.competencesWithoutGroups.forEach(competence => {
                    this.idsToDeactivate.push(competence.id)
                })
                this.competencesWithoutGroups = []
            } else {
                this.competencesByGroups.forEach(group => {
                    this.idsToDeactivate.push(group.id)
                })
                this.competencesByGroups = []
            }

            this.started = false
            this.byGroups = false
            this.numLevels = 1

            this.$refs['reset-modal'].hide();
        },
        addGroup() {
            this.competencesByGroups.push({
                id_company: this.$store.state.auth.user.id_company,
                name: '',
                type: 'group',
                description: '',
                order: 0
            })

            this.competencesByGroups[this.competencesByGroups.length - 1].competences = []

            this.competencesByGroups[this.competencesByGroups.length - 1].competences.push({
                id_company: this.$store.state.auth.user.id_company,
                name: '',
                type: 'competence',
                description: '',
                order: 0
            })

            this.competencesByGroups[this.competencesByGroups.length - 1].competences[this.competencesByGroups[this.competencesByGroups.length - 1].competences.length - 1].levels = []

            for (let i = 0; i < this.numLevels; i++) {
                this.competencesByGroups[this.competencesByGroups.length - 1].competences[this.competencesByGroups[this.competencesByGroups.length - 1].competences.length - 1].levels.push({
                    id_company: this.$store.state.auth.user.id_company,
                    name: '',
                    type: 'level',
                    description: '',
                    behavioral_indicators: '',
                    order: 0,
                    id_competence: 0
                })
            }
        },
        addCompetence() {
            this.competencesWithoutGroups.push({
                id_company: this.$store.state.auth.user.id_company,
                name: '',
                type: 'competence',
                description: '',
                order: 0
            })

            this.competencesWithoutGroups[this.competencesWithoutGroups.length - 1].levels = []

            for (let i = 0; i < this.numLevels; i++) {
                this.competencesWithoutGroups[this.competencesWithoutGroups.length - 1].levels.push({
                    id_company: this.$store.state.auth.user.id_company,
                    name: '',
                    type: 'level',
                    description: '',
                    behavioral_indicators: '',
                    order: 0,
                    id_competence: 0
                })
            }
        },
        addCompetenceToGroup(index_group) {
            this.competencesByGroups[index_group].competences.push({
                id_company: this.$store.state.auth.user.id_company,
                name: '',
                type: 'competence',
                description: '',
                order: 0
            })

            this.competencesByGroups[index_group].competences[this.competencesByGroups[index_group].competences.length - 1].levels = []

            for (let i = 0; i < this.numLevels; i++) {
                this.competencesByGroups[index_group].competences[this.competencesByGroups[index_group].competences.length - 1].levels.push({
                    id_company: this.$store.state.auth.user.id_company,
                    name: '',
                    type: 'level',
                    description: '',
                    behavioral_indicators: '',
                    order: 0,
                    id_competence: 0
                })
            }
            this.$forceUpdate()
        },
        handleSave() {
            this.successfull = true

            if (!this.byGroups) {
                if (this.competencesWithoutGroups.length > 0) {
                    this.competencesWithoutGroups.forEach((competence) => {
                        if (competence.name.trim() === '') {
                            this.successfull = false
                            this.message = 'Some competence name is empty'
                        }
                    })

                    if (this.successfull) {
                        this.competencesWithoutGroups.forEach((competence) => {
                            competence.levels.forEach((level) => {
                                if (level.name.trim() === '') {
                                    this.successfull = false
                                    this.message = 'Some level name is empty'
                                }
                            })
                        })

                        if (this.successfull) {
                            this.createSkillMap()
                        }
                    }
                } else {
                    this.successfull = false
                    this.message = 'The softskills map cannot be empty'
                }
            } else {
                if (this.competencesByGroups.length > 0) {
                    this.competencesByGroups.forEach((group) => {
                        if (group.name.trim() === '') {
                            this.successfull = false
                            this.message = 'Some group name is empty'
                        }
                    })

                    if (this.successfull) {
                        this.competencesByGroups.forEach((group) => {
                            group.competences.forEach((competence) => {
                                if (competence.name.trim() === '') {
                                    this.successfull = false
                                    this.message = 'Some competence name is empty'
                                }
                            })

                            if (this.successfull) {
                                group.competences.forEach((competence) => {
                                    competence.levels.forEach((level) => {
                                        if (level.name.trim() === '') {
                                            this.successfull = false
                                            this.message = 'Some level name is empty'
                                        }
                                    })
                                })
                            }
                        })

                        if (this.successfull) {
                            this.createSkillMap()
                        }
                    }
                } else {
                    this.successfull = false
                    this.message = 'The softskills map cannot be empty'
                }
            }
        },
        async createSkillMap() {
            console.log('createSkillMap')
            this.dataSaving = true

            let deactivateResponseStatus = 200
            if (!this.byGroups) {
                console.log('sin grupos')
                if (this.idsToDeactivate.length > 0) {
                    await SoftskillsDataService.deactivateSoftSkills(this.idsToDeactivate).then(
                        async response => {
                            console.log('soft skills desactivadas: ', response.status)
                            deactivateResponseStatus = response.status
                        },
                        error => {
                            this.dataSaving = false
                            console.log((error.response && error.response.data) ||
                                error.message ||
                                error.toString())

                            if (error.response.status === 300) {
                                console.log('Logout: Token expired')
                                localStorage.removeItem('user');
                                localStorage.removeItem('company');
                                this.$store.state.auth.user = null
                                this.$store.dispatch('auth/logout');
                                this.$router.push('/login');
                            }
                        }
                    );
                }

                if (deactivateResponseStatus === 200) {
                    for (let i = 1; i <= this.competencesWithoutGroups.length; i++) {
                        this.competencesWithoutGroups[i - 1].order = i

                        var competence = {
                            id_company: this.competencesWithoutGroups[i - 1].id_company,
                            name: this.competencesWithoutGroups[i - 1].name,
                            type: this.competencesWithoutGroups[i - 1].type,
                            description: this.competencesWithoutGroups[i - 1].description,
                            behavioral_indicators: this.competencesWithoutGroups[i - 1].behavioral_indicators,
                            order: parseInt(this.competencesWithoutGroups[i - 1].order),
                            id_group: null,
                            id_competence: null
                        }

                        if (this.competencesWithoutGroups[i - 1].id !== undefined) {
                            console.log('si la competencia existe, se actualiza')
                            competence.id = this.competencesWithoutGroups[i - 1].id

                            await SoftskillsDataService.updateCompanyCompetencesDirectory(competence).then(
                                async response => {
                                    var idCompetence = competence.id
                                    console.log('respuesta de la actualizacion')

                                    for (let k = 1; k <= this.competencesWithoutGroups[i - 1].levels.length; k++) {
                                        this.competencesWithoutGroups[i - 1].levels[k - 1].order = k

                                        var level = {
                                            id: this.competencesWithoutGroups[i - 1].levels[k - 1].id,
                                            id_company: this.competencesWithoutGroups[i - 1].levels[k - 1].id_company,
                                            name: this.competencesWithoutGroups[i - 1].levels[k - 1].name,
                                            type: this.competencesWithoutGroups[i - 1].levels[k - 1].type,
                                            description: this.competencesWithoutGroups[i - 1].levels[k - 1].description,
                                            behavioral_indicators: this.competencesWithoutGroups[i - 1].levels[k - 1].behavioral_indicators,
                                            order: parseInt(this.competencesWithoutGroups[i - 1].levels[k - 1].order),
                                            id_group: null,
                                            id_competence: parseInt(idCompetence)
                                        }

                                        await SoftskillsDataService.updateCompanyCompetencesDirectory(level).then(
                                            async response => {
                                                console.log('')
                                            },
                                            error => {
                                                this.dataSaving = false
                                                console.log((error.response && error.response.data) ||
                                                    error.message ||
                                                    error.toString())

                                                if (error.response.status === 300) {
                                                    console.log('Logout: Token expired')
                                                    localStorage.removeItem('user');
                                                    localStorage.removeItem('company');
                                                    this.$store.state.auth.user = null
                                                    this.$store.dispatch('auth/logout');
                                                    this.$router.push('/login');
                                                }
                                            }
                                        );

                                        if (k === this.competencesWithoutGroups[i - 1].levels.length &&
                                            i === this.competencesWithoutGroups.length) {
                                            this.dataSaving = false
                                            this.$router.push('/admin-configuration');
                                        }
                                    }
                                },
                                error => {
                                    this.dataSaving = false
                                    console.log((error.response && error.response.data) ||
                                        error.message ||
                                        error.toString())

                                    if (error.response.status === 300) {
                                        console.log('Logout: Token expired')
                                        localStorage.removeItem('user');
                                        localStorage.removeItem('company');
                                        this.$store.state.auth.user = null
                                        this.$store.dispatch('auth/logout');
                                        this.$router.push('/login');
                                    }
                                }
                            );
                        } else {
                            console.log('si la competencia no existe se crea')
                            await SoftskillsDataService.createElement(competence).then(
                                async response => {
                                    var idCompetence = response.data.id
                                    console.log('respuesta de la creación')

                                    for (let k = 0; k <= this.competencesWithoutGroups[i - 1].levels.length; k++) {
                                        let level = {}

                                        if (k == 0) {
                                            level = {
                                                id_company: this.competencesWithoutGroups[i - 1].levels[k].id_company,
                                                name: "No level reached",
                                                type: this.competencesWithoutGroups[i - 1].levels[k].type,
                                                description: "",
                                                behavioral_indicators: "",
                                                order: parseInt(k),
                                                id_competence: parseInt(idCompetence)
                                            }
                                        } else {
                                            this.competencesWithoutGroups[i - 1].levels[k - 1].order = k

                                            level = {
                                                id_company: this.competencesWithoutGroups[i - 1].levels[k - 1].id_company,
                                                name: this.competencesWithoutGroups[i - 1].levels[k - 1].name,
                                                type: this.competencesWithoutGroups[i - 1].levels[k - 1].type,
                                                description: this.competencesWithoutGroups[i - 1].levels[k - 1].description,
                                                behavioral_indicators: this.competencesWithoutGroups[i - 1].levels[k - 1].behavioral_indicators,
                                                order: parseInt(this.competencesWithoutGroups[i - 1].levels[k - 1].order),
                                                id_competence: parseInt(idCompetence)
                                            }
                                        }

                                        await SoftskillsDataService.createElement(level).then(
                                            async response => {
                                                console.log('')
                                            },
                                            error => {
                                                this.dataSaving = false
                                                console.log((error.response && error.response.data) ||
                                                    error.message ||
                                                    error.toString())

                                                if (error.response.status === 300) {
                                                    console.log('Logout: Token expired')
                                                    localStorage.removeItem('user');
                                                    localStorage.removeItem('company');
                                                    this.$store.state.auth.user = null
                                                    this.$store.dispatch('auth/logout');
                                                    this.$router.push('/login');
                                                }
                                            }
                                        );

                                        if (k === this.competencesWithoutGroups[i - 1].levels.length &&
                                            i === this.competencesWithoutGroups.length) {
                                            this.dataSaving = false
                                            this.$router.push('/admin-configuration');
                                        }
                                    }
                                },
                                error => {
                                    this.dataSaving = false
                                    console.log((error.response && error.response.data) ||
                                        error.message ||
                                        error.toString())

                                    if (error.response.status === 300) {
                                        console.log('Logout: Token expired')
                                        localStorage.removeItem('user');
                                        localStorage.removeItem('company');
                                        this.$store.state.auth.user = null
                                        this.$store.dispatch('auth/logout');
                                        this.$router.push('/login');
                                    }
                                }
                            );
                        }
                    }
                } else {
                    this.successfull = false
                    this.message = 'Internal Server Error. Please, contact the website admin.'
                }

            } else {
                console.log('con grupos')
                if (this.idsToDeactivate.length > 0) {
                    await SoftskillsDataService.deactivateSoftSkills(this.idsToDeactivate).then(
                        async response => {
                            console.log('soft skills desactivadas: ', response.status)
                            deactivateResponseStatus = response.status
                        },
                        error => {
                            this.dataSaving = false
                            console.log((error.response && error.response.data) ||
                                error.message ||
                                error.toString())

                            if (error.response.status === 300) {
                                console.log('Logout: Token expired')
                                localStorage.removeItem('user');
                                localStorage.removeItem('company');
                                this.$store.state.auth.user = null
                                this.$store.dispatch('auth/logout');
                                this.$router.push('/login');
                            }
                        }
                    );
                }

                if (deactivateResponseStatus === 200) {
                    for (let i = 1; i <= this.competencesByGroups.length; i++) {
                        this.competencesByGroups[i - 1].order = i

                        var group = {
                            id_company: this.competencesByGroups[i - 1].id_company,
                            name: this.competencesByGroups[i - 1].name,
                            type: this.competencesByGroups[i - 1].type,
                            description: this.competencesByGroups[i - 1].description,
                            behavioral_indicators: null,
                            order: parseInt(this.competencesByGroups[i - 1].order),
                            id_group: null,
                            id_competence: null
                        }

                        if (this.competencesByGroups[i - 1].id !== undefined) {
                            console.log(' si el grupo existe, se actualiza')
                            group.id = this.competencesByGroups[i - 1].id

                            await SoftskillsDataService.updateCompanyCompetencesDirectory(group).then(
                                async response => {
                                    var idGroup = group.id
                                    console.log('respuesta de la actualizacion')

                                    for (let j = 1; j <= this.competencesByGroups[i - 1].competences.length; j++) {
                                        this.competencesByGroups[i - 1].competences[j - 1].order = j

                                        var competence = {
                                            id_company: this.competencesByGroups[i - 1].competences[j - 1].id_company,
                                            name: this.competencesByGroups[i - 1].competences[j - 1].name,
                                            type: this.competencesByGroups[i - 1].competences[j - 1].type,
                                            description: this.competencesByGroups[i - 1].competences[j - 1].description,
                                            behavioral_indicators: this.competencesByGroups[i - 1].competences[j - 1].behavioral_indicators,
                                            order: parseInt(this.competencesByGroups[i - 1].competences[j - 1].order),
                                            id_group: parseInt(idGroup),
                                            id_competence: null
                                        }

                                        if (this.competencesByGroups[i - 1].competences[j - 1].id !== undefined) {
                                            competence.id = this.competencesByGroups[i - 1].competences[j - 1].id

                                            console.log('si la competencia existe se actualiza')

                                            await SoftskillsDataService.updateCompanyCompetencesDirectory(competence).then(
                                                async response => {
                                                    var idCompetence = competence.id

                                                    console.log('respuesta de la actualizacion')

                                                    for (let k = 1; k <= this.competencesByGroups[i - 1].competences[j - 1].levels.length; k++) {
                                                        this.competencesByGroups[i - 1].competences[j - 1].levels[k - 1].order = k

                                                        var level = {
                                                            id: this.competencesByGroups[i - 1].competences[j - 1].levels[k - 1].id,
                                                            id_company: this.competencesByGroups[i - 1].competences[j - 1].levels[k - 1].id_company,
                                                            name: this.competencesByGroups[i - 1].competences[j - 1].levels[k - 1].name,
                                                            type: this.competencesByGroups[i - 1].competences[j - 1].levels[k - 1].type,
                                                            description: this.competencesByGroups[i - 1].competences[j - 1].levels[k - 1].description,
                                                            behavioral_indicators: this.competencesByGroups[i - 1].competences[j - 1].levels[k - 1].behavioral_indicators,
                                                            order: parseInt(this.competencesByGroups[i - 1].competences[j - 1].levels[k - 1].order),
                                                            id_group: null,
                                                            id_competence: parseInt(idCompetence)
                                                        }

                                                        await SoftskillsDataService.updateCompanyCompetencesDirectory(level).then(
                                                            async response => {
                                                                console.log('')
                                                            },
                                                            error => {
                                                                this.dataSaving = false
                                                                console.log((error.response && error.response.data) ||
                                                                    error.message ||
                                                                    error.toString())

                                                                if (error.response.status === 300) {
                                                                    console.log('Logout: Token expired')
                                                                    localStorage.removeItem('user');
                                                                    localStorage.removeItem('company');
                                                                    this.$store.state.auth.user = null
                                                                    this.$store.dispatch('auth/logout');
                                                                    this.$router.push('/login');
                                                                }
                                                            }
                                                        );

                                                        if (k === this.competencesByGroups[i - 1].competences[j - 1].levels.length &&
                                                            j === this.competencesByGroups[i - 1].competences.length &&
                                                            i === this.competencesByGroups.length) {
                                                            this.dataSaving = false
                                                            this.$router.push('/admin-configuration');
                                                        }
                                                    }
                                                },
                                                error => {
                                                    this.dataSaving = false
                                                    console.log((error.response && error.response.data) ||
                                                        error.message ||
                                                        error.toString())

                                                    if (error.response.status === 300) {
                                                        console.log('Logout: Token expired')
                                                        localStorage.removeItem('user');
                                                        localStorage.removeItem('company');
                                                        this.$store.state.auth.user = null
                                                        this.$store.dispatch('auth/logout');
                                                        this.$router.push('/login');
                                                    }
                                                }
                                            );
                                        } else {
                                            console.log('si la competencia no existe, se crea')
                                            await SoftskillsDataService.createElement(competence).then(
                                                async response => {
                                                    console.log('respuesta de la creacion')

                                                    var idCompetence = response.data.id

                                                    for (let k = 0; k <= this.competencesByGroups[i - 1].competences[j - 1].levels.length; k++) {
                                                        let level = {}

                                                        if (k == 0) {
                                                            level = {
                                                                id_company: this.competencesByGroups[i - 1].competences[j - 1].levels[k].id_company,
                                                                name: "No level reached",
                                                                type: this.competencesByGroups[i - 1].competences[j - 1].levels[k].type,
                                                                description: "",
                                                                behavioral_indicators: "",
                                                                order: parseInt(k),
                                                                id_competence: parseInt(idCompetence)
                                                            }
                                                        } else {
                                                            this.competencesByGroups[i - 1].competences[j - 1].levels[k - 1].order = k

                                                            level = {
                                                                id_company: this.competencesByGroups[i - 1].competences[j - 1].levels[k - 1].id_company,
                                                                name: this.competencesByGroups[i - 1].competences[j - 1].levels[k - 1].name,
                                                                type: this.competencesByGroups[i - 1].competences[j - 1].levels[k - 1].type,
                                                                description: this.competencesByGroups[i - 1].competences[j - 1].levels[k - 1].description,
                                                                behavioral_indicators: this.competencesByGroups[i - 1].competences[j - 1].levels[k - 1].behavioral_indicators,
                                                                order: parseInt(this.competencesByGroups[i - 1].competences[j - 1].levels[k - 1].order),
                                                                id_competence: parseInt(idCompetence)
                                                            }
                                                        }

                                                        await SoftskillsDataService.createElement(level).then(
                                                            async response => {
                                                                console.log('')
                                                            },
                                                            error => {
                                                                this.dataSaving = false
                                                                console.log((error.response && error.response.data) ||
                                                                    error.message ||
                                                                    error.toString())

                                                                if (error.response.status === 300) {
                                                                    console.log('Logout: Token expired')
                                                                    localStorage.removeItem('user');
                                                                    localStorage.removeItem('company');
                                                                    this.$store.state.auth.user = null
                                                                    this.$store.dispatch('auth/logout');
                                                                    this.$router.push('/login');
                                                                }
                                                            }
                                                        );

                                                        if (k === this.competencesByGroups[i - 1].competences[j - 1].levels.length &&
                                                            j === this.competencesByGroups[i - 1].competences.length &&
                                                            i === this.competencesByGroups.length) {
                                                            this.dataSaving = false
                                                            console.log('mapa de competencias establecido con éxito')
                                                            this.$router.push('/admin-configuration');
                                                        }
                                                    }
                                                },
                                                error => {
                                                    this.dataSaving = false
                                                    console.log((error.response && error.response.data) ||
                                                        error.message ||
                                                        error.toString())

                                                    if (error.response.status === 300) {
                                                        console.log('Logout: Token expired')
                                                        localStorage.removeItem('user');
                                                        localStorage.removeItem('company');
                                                        this.$store.state.auth.user = null
                                                        this.$store.dispatch('auth/logout');
                                                        this.$router.push('/login');
                                                    }
                                                }
                                            );
                                        }
                                    }
                                },
                                error => {
                                    this.dataSaving = false
                                    console.log((error.response && error.response.data) ||
                                        error.message ||
                                        error.toString())

                                    if (error.response.status === 300) {
                                        console.log('Logout: Token expired')
                                        localStorage.removeItem('user');
                                        localStorage.removeItem('company');
                                        this.$store.state.auth.user = null
                                        this.$store.dispatch('auth/logout');
                                        this.$router.push('/login');
                                    }
                                }
                            );
                        } else {
                            console.log('si el grupo no existe, se crea')
                            await SoftskillsDataService.createElement(group).then(
                                async response => {
                                    console.log('respuesta de la creacion')

                                    var idGroup = response.data.id

                                    for (let j = 1; j <= this.competencesByGroups[i - 1].competences.length; j++) {
                                        this.competencesByGroups[i - 1].competences[j - 1].order = j

                                        var competence = {
                                            id_company: this.competencesByGroups[i - 1].competences[j - 1].id_company,
                                            name: this.competencesByGroups[i - 1].competences[j - 1].name,
                                            type: this.competencesByGroups[i - 1].competences[j - 1].type,
                                            description: this.competencesByGroups[i - 1].competences[j - 1].description,
                                            order: parseInt(this.competencesByGroups[i - 1].competences[j - 1].order),
                                            id_group: parseInt(idGroup)
                                        }

                                        console.log('se crea la competencia')

                                        await SoftskillsDataService.createElement(competence).then(
                                            async response => {
                                                var idCompetence = response.data.id

                                                console.log('respuesta de la creacion')
                                                for (let k = 0; k <= this.competencesByGroups[i - 1].competences[j - 1].levels.length; k++) {
                                                    let level = {}

                                                    if (k == 0) {
                                                        level = {
                                                            id_company: this.competencesByGroups[i - 1].competences[j - 1].levels[k].id_company,
                                                            name: "No level reached",
                                                            type: this.competencesByGroups[i - 1].competences[j - 1].levels[k].type,
                                                            description: "",
                                                            behavioral_indicators: "",
                                                            order: parseInt(k),
                                                            id_competence: parseInt(idCompetence)
                                                        }
                                                    } else {
                                                        this.competencesByGroups[i - 1].competences[j - 1].levels[k - 1].order = k

                                                        level = {
                                                            id_company: this.competencesByGroups[i - 1].competences[j - 1].levels[k - 1].id_company,
                                                            name: this.competencesByGroups[i - 1].competences[j - 1].levels[k - 1].name,
                                                            type: this.competencesByGroups[i - 1].competences[j - 1].levels[k - 1].type,
                                                            description: this.competencesByGroups[i - 1].competences[j - 1].levels[k - 1].description,
                                                            behavioral_indicators: this.competencesByGroups[i - 1].competences[j - 1].levels[k - 1].behavioral_indicators,
                                                            order: parseInt(this.competencesByGroups[i - 1].competences[j - 1].levels[k - 1].order),
                                                            id_competence: parseInt(idCompetence)
                                                        }
                                                    }

                                                    await SoftskillsDataService.createElement(level).then(
                                                        async response => {
                                                            console.log('')
                                                        },
                                                        error => {
                                                            this.dataSaving = false
                                                            console.log((error.response && error.response.data) ||
                                                                error.message ||
                                                                error.toString())

                                                            if (error.response.status === 300) {
                                                                console.log('Logout: Token expired')
                                                                localStorage.removeItem('user');
                                                                localStorage.removeItem('company');
                                                                this.$store.state.auth.user = null
                                                                this.$store.dispatch('auth/logout');
                                                                this.$router.push('/login');
                                                            }
                                                        }
                                                    );

                                                    if (k === this.competencesByGroups[i - 1].competences[j - 1].levels.length &&
                                                        j === this.competencesByGroups[i - 1].competences.length &&
                                                        i === this.competencesByGroups.length) {
                                                        this.dataSaving = false
                                                        this.$router.push('/admin-configuration');
                                                    }
                                                }
                                            },
                                            error => {
                                                this.dataSaving = false
                                                console.log((error.response && error.response.data) ||
                                                    error.message ||
                                                    error.toString())

                                                if (error.response.status === 300) {
                                                    console.log('Logout: Token expired')
                                                    localStorage.removeItem('user');
                                                    localStorage.removeItem('company');
                                                    this.$store.state.auth.user = null
                                                    this.$store.dispatch('auth/logout');
                                                    this.$router.push('/login');
                                                }
                                            }
                                        );
                                    }
                                },
                                error => {
                                    this.dataSaving = false
                                    console.log((error.response && error.response.data) ||
                                        error.message ||
                                        error.toString())

                                    if (error.response.status === 300) {
                                        console.log('Logout: Token expired')
                                        localStorage.removeItem('user');
                                        localStorage.removeItem('company');
                                        this.$store.state.auth.user = null
                                        this.$store.dispatch('auth/logout');
                                        this.$router.push('/login');
                                    }
                                }
                            );
                        }
                    }
                } else {
                    this.successfull = false
                    this.message = 'Internal Server Error. Please, contact the website admin.'
                }
            }
        },
        close() {
            document.getElementById("message").style.display = "none"
            this.message = ''
        },
        scrollToTop() {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    }
};
</script>