<template>
  <div class="col-sm-12">
    <div class="row secondNavbar">
      <div class="col-5 links-container">
        <router-link :to="{ path: '/settings-exams' }" class="btn btn-secondary float-right"><font-awesome-icon
            icon="arrow-left" /> Back to exams settings</router-link>
        <router-link to="/create-language" class="btn btn-secondary float-right createClass"><font-awesome-icon
            icon="plus" /> CREATE LANGUAGE</router-link>
      </div>
      <div class="col-7 noMargin whiteColor">
        <router-link :to="{ path: '/settings' }" class="breadcumb"> Settings > </router-link>
        <router-link :to="{ path: '/settings-exams' }" class="breadcumb"> Exams settings > </router-link>
        <router-link :to="{ path: '/languages' }" class="final-breadcumb"> Languages List</router-link>
      </div>
    </div>

    <div class="col-sm-12 col-centered mt-2">
      <div id="message" v-if="languageRegistered" class="alert alert-success align-item-center">
        Language registered succesfully! <b-button @click="close" class="cross-button"><font-awesome-icon class="cross"
            icon="times" /> </b-button>
      </div>
      <div id="message2" v-if="languageUpdated" class="alert alert-success align-item-center">
        Language updated succesfully! <b-button @click="close2" class="cross-button"><font-awesome-icon class="cross"
            icon="times" /> </b-button>
      </div>
      <div id="message3" v-if="languageDeleted" class="alert alert-success align-item-center">
        Language deleted succesfully! <b-button @click="close3" class="cross-button"><font-awesome-icon class="cross"
            icon="times" /> </b-button>
      </div>
      <list-languages :languages="languages" @deleteLanguage="deleteLanguage" />
    </div>
  </div>
</template>

<script>
import LanguageDataService from "@/services/LanguageDataService";
import ListLanguages from "@/components/LanguageComponents/List.vue";
import CreateLanguage from "@/components/LanguageComponents/Create.vue";

export default {
  name: 'ListLanguagesView',
  data() {
    return {
      languages: [],
      languageDeleted: false
    }
  },
  components: {
    ListLanguages,
    CreateLanguage
  },
  methods: {
    getLanguages() {
      LanguageDataService.findByArea(this.$store.state.auth.user.active_areas[this.$session.get("tabID")]).then(
        response => {
          this.languages = response.data
        },
        error => {
          console.log((error.response && error.response.data) ||
            error.message ||
            error.toString())

          if (error.response.status === 300) {
            console.log('Logout: Token expired')
            localStorage.removeItem('user');
            localStorage.removeItem('company');
            this.$store.state.auth.user = null
            this.$store.dispatch('auth/logout');
            this.$router.push('/login');
          }
        }
      );
    },
    handleCreate(info) {
      this.getLanguages();
    },
    deleteLanguage() {
      this.languageDeleted = true
      this.getLanguages()
    },
    close() {
      document.getElementById("message").style.display = "none"
    },
    close2() {
      document.getElementById("message2").style.display = "none"
    },
    close3() {
      document.getElementById("message3").style.display = "none"
    }
  },
  mounted() {
    this.getLanguages();
  },
  props: {
    languageRegistered: {
      type: Boolean,
      default: false
    },
    languageUpdated: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
#app>.bg-white .main-container {
  max-width: 100%;
  float: none;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
}

/* .createClass{
    background: border-box;
    border-radius: 26px;
    padding: inherit;
    margin-top: 0.6%;
	padding-right: 7px;
    padding-left: 7px;
} */

.alert {
  margin: 0 30px;
}
</style>
