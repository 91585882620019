<template>
    <div class="col-sm-12">
        <div class="row secondNavbar">
            <div class="col-5 links-container">
                <router-link :to="{ path: '/modules' }" class="btn btn-secondary float-right"><font-awesome-icon
                        icon="arrow-left" /> Back to Modules</router-link>
                <router-link to="/create-business-process"
                    class="btn btn-secondary float-right createClass"><font-awesome-icon icon="plus" /> CREATE
                    EVALUATION
                    PROCESS</router-link>
            </div>
            <div class="col-7 noMargin whiteColor">
                <router-link :to="{ path: '/modules' }" class="breadcumb"> Suite Tools > </router-link>
                <router-link :to="{ path: '/business-process' }" class="final-breadcumb"> Evaluation Process
                </router-link>
            </div>
        </div>

        <div class="col-sm-12 col-centered">
            <list-business-process :selectionProcessList="selectionProcessList" />
        </div>
    </div>
</template>

<script>
import BusinessProcessService from "@/services/BusinessProcessService";
import ListBusinessProcess from "@/components/BusinessProcessComponents/List.vue";

export default {
    name: 'Home',
    data() {
        return {
            selectionProcessList: []
        }
    },
    components: {
        ListBusinessProcess,
    },
    methods: {
        async getProcessSelectionList() {
            const currentAreaID = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
            await BusinessProcessService.findByArea(currentAreaID).then(
                response => {
                    if (response.status == 200) {
                        this.selectionProcessList = response.data;

                        this.selectionProcessList.sort((a, b) => a.created_at - b.created_at)

                        console.log('selectionProcessList: ', this.selectionProcessList)
                    } else {
                        console.error("An eror ocurred while getting selection process from area")
                        console.error(response)
                    }
                },
                error => {
                    this.dataLoading = false;
                    console.error((error.response && error.response.data) ||
                        error.message ||
                        error.toString())

                    if (error.response.status === 300) {
                        console.error('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );
        },
        close() {
            document.getElementById("message").style.display = "none"
        },
        close2() {
            document.getElementById("message2").style.display = "none"
        }
    },
    mounted() {
        this.getProcessSelectionList();
    },
    props: {
        processSelectionRegistered: {
            type: Boolean,
            default: false
        },
        processSelectionUpdated: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style scoped>
#app>.bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
}

/* .createClass {
    background: border-box;
    border-radius: 26px;
    padding: inherit;
    margin-top: 0.6%;
    padding-right: 7px;
    padding-left: 7px;
} */
</style>
