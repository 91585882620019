<template>
	<div :id="chartId" class="horizontal-stacked-barr-chart" ref="chartdiv"></div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

export default {
	name: 'HorizontalStackedBarr',
	data() {
		return {
			chart: null,
		}
	},
	props: {
		chartId: String,
		data: Array,
		xAxesCategory: String,
		yAxesValue: String,
		xAxesTitle: String,
		yAxesTitle: String,
		isHighValueSet: {
			type: Boolean,
			default: false
		},
		isDichotomic: {
			type: Boolean,
			default: false
		},
		isUserResult: {
			type: Boolean,
			default: false
		},
		isLabelPercentage: {
			type: Boolean,
			default: false
		},
		isLabelColor: {
			type: Boolean,
			default: false
		},
		tooltipNoDataMsg: {
			type: String,
			default: "No data available"
		},
		isCorrectFalse: Boolean
	},
	mounted() {
		let data = []
		if (this.data != undefined)
			data = JSON.parse(JSON.stringify(this.data))

		this.chart = am4core.create(this.chartId, am4charts.XYChart);

		if (0 == data.length) {
			let label = this.chart.createChild(am4core.Label);
			label.text = this.tooltipNoDataMsg;
			label.fontSize = 20;
			label.align = "center";
			label.isMeasured = false;
			label.x = am4core.percent(50);
			label.y = 100;
			label.horizontalCenter = "middle";
		} else {
			this.chart.data = this.data;

			this.chart.padding(40, 40, 40, 40);

			var categoryAxis = this.chart.yAxes.push(new am4charts.CategoryAxis());
			categoryAxis.renderer.grid.template.location = 0;
			categoryAxis.dataFields.category = this.yAxesValue;
			categoryAxis.renderer.minGridDistance = 1;
			categoryAxis.renderer.inversed = true;
			categoryAxis.renderer.grid.template.disabled = true;
			categoryAxis.title.text = this.yAxesTitle
			categoryAxis.title.fontWeight = "bold"

			// // Calculate how we need to adjust chart height
			// var adjustHeight = this.chart.data.length * 30 - categoryAxis.pixelHeight;

			// // get current chart height
			// var targetHeight = this.chart.pixelHeight + adjustHeight;

			// // Set it on chart's container
			// this.chart.svgContainer.htmlElement.style.height = targetHeight + "px";

			var valueAxis = this.chart.xAxes.push(new am4charts.ValueAxis());
			valueAxis.title.text = this.xAxesTitle
			valueAxis.title.fontWeight = "bold"
			valueAxis.min = 0;
			valueAxis.max = 100;
			valueAxis.strictMinMax = true;

			var series = this.chart.series.push(new am4charts.ColumnSeries());
			series.dataFields.categoryY = this.yAxesValue;
			series.dataFields.valueX = this.xAxesCategory;
			series.columns.template.strokeOpacity = 0;
			series.columns.template.column.cornerRadiusBottomRight = 5;
			series.columns.template.column.cornerRadiusTopRight = 5;

			var labelBullet = series.bullets.push(new am4charts.LabelBullet())
			labelBullet.label.horizontalCenter = "left";
			labelBullet.label.dx = 10;

			if (this.isLabelPercentage)
				labelBullet.label.text = "{valueX.value} % ({ocurrences})";
			else
				labelBullet.label.text = "{valueX.value} %";
			labelBullet.locationX = 1;

			const isHighValueSet = this.isHighValueSet;
			const isDichotomic = this.isDichotomic;
			const isUserResult = this.isUserResult;

			labelBullet.label.adapter.add("fontWeight", function (fontWeight, target) {
				if ((isHighValueSet && isUserResult && target.dataItem.dataContext.userAnswer) || (isDichotomic && target.dataItem.dataContext.userAnswer)) {
					return "bold"
				}
			});
			labelBullet.label.adapter.add("text", function (text, target) {
				if ((isHighValueSet && isUserResult && target.dataItem.dataContext.userAnswer) || (isDichotomic && target.dataItem.dataContext.userAnswer)) {
					return text + " (selected)"
				} else {
					return text
				}
			});

			const numVariables = this.data.length

			series.columns.template.adapter.add("fill", function (fill, target) {
					if (isDichotomic) {
						if ("Correct" === target.dataItem.categoryY) {
							return am4core.color("#C5E0B4");
						} else {
							return am4core.color("#FF5656");
						}
					}
					else{
						return am4core.color("#67b7dc");
					}
				
			});

			// series.columns.template.adapter.add("fill", function (fill, target) {
			// 	if (isHighValueSet) {
			// 		if (isUserResult) {
			// 			if ((target.dataItem.dataContext.correct && target.dataItem.dataContext.userAnswer) || (target.dataItem.dataContext.correct && !target.dataItem.dataContext.userAnswer)) {
			// 				return am4core.color("#C5E0B4");
			// 			} else if (!target.dataItem.dataContext.correct && target.dataItem.dataContext.userAnswer) {
			// 				return am4core.color("#FF5656");
			// 			} else {
			// 				if (numVariables == 6) {
			// 					let colors = ["#8FF9FF", "#70DCFF", "#4FC0E8", "#26A5CC", "#007195", "#13283B"]
			// 					let returnColor = colors[colors.length - 1]
			// 					colors.pop()
			// 					return returnColor
			// 				} else {
			// 					let colors = ["#8FF9FF", "#70DCFF", "#4FC0E8", "#26A5CC", "#007195", "#004162", "#13283B"]
			// 					return colors[Math.floor(Math.random() * colors.length)];
			// 				}
			// 			}
			// 		} else {
			// 			if (target.dataItem.dataContext.correct) {
			// 				return am4core.color("#C5E0B4");
			// 			} else {
			// 				if (numVariables == 6) {
			// 					let colors = ["#8FF9FF", "#70DCFF", "#4FC0E8", "#26A5CC", "#007195", "#13283B"]
			// 					let returnColor = colors[colors.length - 1]
			// 					colors.pop()
			// 					return returnColor
			// 				} else {
			// 					let colors = ["#8FF9FF", "#70DCFF", "#4FC0E8", "#26A5CC", "#007195", "#004162", "#13283B"]
			// 					return colors[Math.floor(Math.random() * colors.length)];
			// 				}
			// 			}
			// 		}
			// 	} else {
			// 		if (isDichotomic) {
			// 			if ("Correct" === target.dataItem.categoryY) {
			// 				return am4core.color("#C5E0B4");
			// 			} else {
			// 				return am4core.color("#FF5656");
			// 			}
			// 		} else {
			// 			let colors = ["#8FF9FF", "#70DCFF", "#4FC0E8", "#26A5CC", "#007195", "#004162", "#13283B"]
			// 			return colors[Math.floor(Math.random() * colors.length)];
			// 		}
			// 	}
			// });
		}
	}
};
</script>
