import { Radar, mixins } from "vue-chartjs";

export default {
  extends: Radar,
  props: ["data", "options"],
  mounted() {
    this.renderRadarChart();
  },
  methods: {
    renderRadarChart: function() {
      this.renderChart(this.data, this.options);    
    }
  },
  watch: {
    data: function() {
      this.$data._chart.destroy();
      this.renderRadarChart();
    }
  }
};