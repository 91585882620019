<template>
    <div class="form-container technologiesStyle">
        <div class="row mt-2">
            <h5 class="mb-0" style="text-align: left !important;">General information</h5>
        </div>
        <form action class="form" @submit.prevent="handleUpdate">
            <div class="row">
                <div class="col-sm-4">
                    <label class="mt-2" for="exampleFormControlSelect1"
                        style="float: left !important; margin-left: 1px !important;">Username *<b-button
                            class="btn btn-info" v-b-tooltip.hover.right
                            title="You must choose a username to login"><font-awesome-icon
                                icon="info-circle" /></b-button></label>

                    <input type="text" class="form-control inputStyle" id="username" required v-model="user.username"
                        name="username" v-validate="'required|min:6|max:20'" data-vv-name="username"
                        placeholder="Username" disabled />
                    <div v-if="veeErrors.has('username')" class="alert alert-danger py-0 mt-2 mb-0">
                        {{ veeErrors.first('username') }}
                    </div>
                </div>

                <div class="col-sm-4">
                    <label class="mt-2" for="exampleFormControlSelect1"
                        style="float: left !important; margin-left: 1px !important;">Name *<b-button
                            class="btn btn-info" v-b-tooltip.hover title="You must enter your name"><font-awesome-icon
                                icon="info-circle" /></b-button></label>

                    <input type="text" class="form-control inputStyle" id="name" required v-model="user.name"
                        name="name" v-validate="'required'" data-vv-name="name" placeholder="Name" />
                    <div v-if="veeErrors.has('name')" class="alert alert-danger py-0 mt-2 mb-0">
                        {{ veeErrors.first('name') }}
                    </div>
                </div>
                <div class="col-sm-4">
                    <label class="mt-2" for="exampleFormControlSelect1"
                        style="float: left !important; margin-left: 1px !important;">Surname *<b-button
                            class="btn btn-info" v-b-tooltip.hover
                            title="You must enter your surname"><font-awesome-icon
                                icon="info-circle" /></b-button></label>

                    <input type="text" class="form-control inputStyle" id="surname" required v-model="user.surname"
                        name="surname" v-validate="'required'" data-vv-name="surname" placeholder="Surname" />
                    <div v-if="veeErrors.has('surname')" class="alert alert-danger py-0 mt-2 mb-0">
                        {{ veeErrors.first('surname') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4">
                    <label class="mt-2" for="exampleFormControlSelect1"
                        style="float: left !important; margin-left: 1px !important;">Mail *<b-button
                            class="btn btn-info" v-b-tooltip.hover.right
                            title="You must enter a valid email address"><font-awesome-icon
                                icon="info-circle" /></b-button></label>
                    <input class="form-control inputStyle" id="mail" required v-model="user.email"
                        v-validate="'required|email|max:50'" type="email" data-vv-name="email" placeholder="Email"
                        name="mail" disabled />
                    <div v-if="veeErrors.has('email')" class="alert alert-danger py-0 mt-2 mb-0">
                        {{ veeErrors.first('email') }}
                    </div>
                </div>
                <div class="col-sm-4">
                    <label class="mt-2" for="exampleFormControlSelect1"
                        style="float: left !important; margin-left: 1px !important;">Role *<b-button
                            class="btn btn-info" v-b-tooltip.hover title="You must choose a role"><font-awesome-icon
                                icon="info-circle" /></b-button></label>
                    <select class="form-select" id="role" v-validate="'required'" data-vv-name="role"
                        v-model="user.role">
                        <option :key="-1" :value="null">Select an option...</option>
                        <option v-for="item in roles" v-bind:key="item.id" v-bind:value="item">
                            {{ item.name.toUpperCase() }}
                        </option>
                    </select>
                    <div v-if="veeErrors.has('role')" class="alert alert-danger py-0 mt-2 mb-0">
                        {{ veeErrors.first('role') }}
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="row">
                        <label class="mt-2" for="exampleFormControlSelect1"
                            style="text-align: left !important; margin-left: 1px !important;">Areas *<b-button
                                class="btn btn-info" v-b-tooltip.hover
                                title="You must select at least one area"><font-awesome-icon
                                    icon="info-circle" /></b-button></label>
                    </div>
                    <div class="row">
                        <multiselect id="area" v-validate="'required'" data-vv-name="area"
                            style="width: 98% !important;" :options="areasToShow" :multiple="true"
                            :close-on-select="false" :preserve-search="true" :taggable="true" :preselect-first="false"
                            placeholder="Search or add an area" v-model="selectedAreas" label="label" track-by="value"
                            @select="selectOption" @remove="removeOption"></multiselect>
                    </div>

                    <div v-if="veeErrors.has('area')" class="alert alert-danger py-0 mt-2 mb-0">
                        {{ veeErrors.first('area') }}
                    </div>
                </div>
            </div>

            <div class="form-group row justify-content-center">
                <div class="col-sm-1">
                    <input class="btn btn-primary form-submit" type="submit" :disabled="formDidntChange" value="Update">
                </div>
                <div class="col-sm-1">
                    <button class="btn btn-secondary btn-cancel" type="button"
                        @click="backToRepository()"><font-awesome-icon icon="ban" /> Cancel</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import UserDataService from "@/services/UserDataService";
import AuthDataService from "@/services/AuthDataService"
import AreasDataService from "@/services/AreasDataService"
import $ from 'jquery'
import Multiselect from 'vue-multiselect'

export default {
    name: 'UpdateUserComponent',
    data() {
        return {
            users: [],
            roles: [],
            roleSelected: [],
            areas: [],
            areasToShow: [],
            areasToAdd: [],
            idsAreasPrev: [],
            selectedAreas: [],
            user: {
                username: "",
                name: "",
                surname: "",
                password: "",
                email: "",
                role: {},
                areas: []
            },
            languages: [],
            image: "",
            fileName: "",
            submitted: false,
            successfull: false,
            message: "",
            selectedTags: [],
            tags: [],
            repositories: [],
            multiselection: null,
            visibility: 'password',
            files: null,
            avatar: '',
            preview: null,
            chosenFile: null,
            formDidntChange: false,
        }
    },
    components: {
        Multiselect
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        AuthDataService.registerBoard().then(
            response => {
                this.roles = response.data
                this.roles = this.roles.filter(item => item.name != 'superadmin');
            },
            error => {
                console.log((error.response && error.response.data) ||
                    error.message ||
                    error.toString())

                if (error.response.status === 300) {
                    console.log('Logout: Token expired')
                    localStorage.removeItem('user');
                    localStorage.removeItem('company');
                    this.$store.state.auth.user = null
                    this.$store.dispatch('auth/logout');
                    this.$router.push('/login');
                }
            }
        )

        UserDataService.findByCompany(this.$store.state.auth.user.id_company).then(
            response => {
                this.users = response.data
            },
            error => {
                console.log((error.response && error.response.data) ||
                    error.message ||
                    error.toString())

                if (error.response.status === 300) {
                    console.log('Logout: Token expired')
                    localStorage.removeItem('user');
                    localStorage.removeItem('company');
                    this.$store.state.auth.user = null
                    this.$store.dispatch('auth/logout');
                    this.$router.push('/login');
                }
            }
        );

        UserDataService.findOne(this.$route.query.id).then(
            response => {
                this.user = response.data;
                this.avatar = this.$awsBucketRoute + this.user.avatar
                for (let i = 0; i < this.user.areas.length; i++) {
                    this.selectedAreas.push({ label: this.user.areas[i].name.toUpperCase(), value: this.user.areas[i].name + '-' + this.user.areas[i].id })
                    this.areasToAdd.push({ id: this.user.areas[i].id, label: this.user.areas[i].name.toUpperCase(), value: this.user.areas[i].name + '-' + this.user.areas[i].id })
                    this.idsAreasPrev.push(this.user.areas[i].id)
                }
            },
            error => {
                console.log((error.response && error.response.data) ||
                    error.message ||
                    error.toString())

                if (error.response.status === 300) {
                    console.log('Logout: Token expired')
                    localStorage.removeItem('user');
                    localStorage.removeItem('company');
                    this.$store.state.auth.user = null
                    this.$store.dispatch('auth/logout');
                    this.$router.push('/login');
                }
            }
        )

        AreasDataService.findByCompany(this.$store.state.auth.user.id_company).then(
            response => {
                let tmpAreas = response.data
                for (let i = 0; i < tmpAreas.length; i++) {
                    this.areas.push(tmpAreas[i])
                    this.areasToShow.push({ label: tmpAreas[i].name.toUpperCase(), value: tmpAreas[i].name + '-' + tmpAreas[i].id })
                }
            },
            error => {
                console.log((error.response && error.response.data) ||
                    error.message ||
                    error.toString())

                if (error.response.status === 300) {
                    console.log('Logout: Token expired')
                    localStorage.removeItem('user');
                    localStorage.removeItem('company');
                    this.$store.state.auth.user = null
                    this.$store.dispatch('auth/logout');
                    this.$router.push('/login');
                }
            }
        );

    },
    methods: {
        backToRepository() {
            this.$router.replace({ name: "admin-configuration-users", params: {} });
        },
        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.fileName = files[0].name;
            this.createImage(files[0]);
        },
        createImage(file) {
            var image = new Image();
            var reader = new FileReader();
            var vm = this;
            reader.onload = function (e) {
                var img = document.createElement("img");
                img.onload = function (event) {
                    var maxW = 200;
                    var maxH = 200;
                    var canvas = document.createElement("canvas");
                    var iw = img.width;
                    var ih = img.height;
                    var scale = Math.min((maxW / iw), (maxH / ih));
                    var iwScaled = iw * scale;
                    var ihScaled = ih * scale;
                    canvas.width = iwScaled;
                    canvas.height = ihScaled;
                    var ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
                    let fileToUpload = canvas.toDataURL(file.type)
                    vm.saveImage(fileToUpload)
                }
                vm.avatar = e.target.result;
                img.src = e.target.result;
            }
            reader.readAsDataURL(file);

        },
        saveImage(base64Image) {
            var imageName = "profiles/" + this.user.access_token + "_" + this.user.id + "_logo.png"
            var file = this.dataURLtoFile(base64Image, "imageName.jpeg");
            UserDataService.updateAvatar(file, imageName, this.user.id, this.user.access_token, event => { }).then(
                response => {
                    if (response.status == 200) {
                        this.avatar = this.$awsBucketRoute + imageName
                        this.user.avatar = this.avatar
                        let tmpUser = this.$store.state.auth.user
                        tmpUser.avatar = this.avatar
                        localStorage.setItem('user', JSON.stringify(tmpUser));
                    } else {
                        this.message = response.data.message
                    }
                })
                .then(files => {
                })
                .catch((error) => {
                    console.log(error.message)
                    this.message = "There was an error while processing your image";
                }
                );
        },
        dataURLtoFile(dataurl, filename) {
            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }

            return new File([u8arr], filename, { type: mime });
        },
        filteredRoles() {
            return this.roles.filter(field => field.name != 'superadmin');
        },
        selectOption(option) {
            var object = {}

            var parsedOption = JSON.parse(JSON.stringify(option))

            object.label = parsedOption.label
            object.value = parsedOption.value
            var valueSplit = parsedOption.value.split('-')
            object.id = parseInt(valueSplit[1])
            this.areasToAdd.push(object)

            this.user.areas = this.areasToAdd
        },
        removeOption(option) {
            var parsedOption = JSON.parse(JSON.stringify(option))

            var valueSplit = parsedOption.value.split('-')

            const index = this.areasToAdd.map(object => object.id).indexOf(parseInt(valueSplit[1]));
            this.areasToAdd.splice(index, 1)

            this.user.areas = this.areasToAdd
        },
        handleUpdate() {
            this.submitted = true;
            this.successfull = true;

            if (!this.checkUsernameExists(this.user)) {
                if (!this.checkEmailExists(this.user)) {
                    if (this.user.name != '' || this.user.surname != '' || this.user.username != '' || this.user.password != '' || this.user.email != '' || this.user.roles != '' || this.selectedAreas.length > 0) {
                        this.$validator.validate().then(isValid => {
                            if (isValid) {
                                this.roleSelected.push(this.user.role);
                                var data = { user: this.user, roles: this.roleSelected, id_company: this.$store.state.auth.user.id_company };
                                UserDataService.updateUser(data)
                                    .then(response => {
                                        let idsAreas = []

                                        this.areasToAdd.forEach((item) => {
                                            idsAreas.push(item.id)
                                        })

                                        AreasDataService.removeUserFromAreas(this.user.id, this.idsAreasPrev).then(
                                            response => {
                                                if (response.status == 200) {
                                                    AreasDataService.addUserToAreas(this.user.id, idsAreas).then(
                                                        response => {
                                                            if (response.status == 200) {
                                                                this.$router.push({ name: "admin-configuration-users", params: { userRegistered: true } });
                                                            }
                                                        },
                                                        error => {
                                                            console.log((error.response && error.response.data) ||
                                                                error.message ||
                                                                error.toString())

                                                            if (error.response.status === 300) {
                                                                console.log('Logout: Token expired')
                                                                localStorage.removeItem('user');
                                                                localStorage.removeItem('company');
                                                                this.$store.state.auth.user = null
                                                                this.$store.dispatch('auth/logout');
                                                                this.$router.push('/login');
                                                            }
                                                        }
                                                    );
                                                }
                                            },
                                            error => {
                                                console.log((error.response && error.response.data) ||
                                                    error.message ||
                                                    error.toString())

                                                if (error.response.status === 300) {
                                                    console.log('Logout: Token expired')
                                                    localStorage.removeItem('user');
                                                    localStorage.removeItem('company');
                                                    this.$store.state.auth.user = null
                                                    this.$store.dispatch('auth/logout');
                                                    this.$router.push('/login');
                                                }
                                            }
                                        );
                                    });
                            }
                        });
                    }
                } else {
                    this.message = "Email already exists!"
                    this.successfull = false
                }
            } else {
                this.message = "Username already exists!"
                this.successfull = false
            }
        },
        checkEmailExists(user) {
            let exists = false

            for (let i = 0; i < this.users.length; i++) {
                if (this.users[i].email === user.email && this.users[i].id !== this.user.id)
                    exists = true
            }

            return exists
        },
        checkUsernameExists(user) {
            let exists = false

            for (let i = 0; i < this.users.length; i++) {
                if (this.users[i].username === user.username && this.users[i].id !== this.user.id)
                    exists = true
            }

            return exists
        },
        close() {
            document.getElementById("message").style.display = "none"
        }
    },
    props: {
        id_user: Number
    }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>