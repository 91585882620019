<template>
	<div class="col-sm-12">
		<div class="row secondNavbar">
			<div class="col-5 links-container">
				<router-link v-if="isAdmin || isTM" :to="{ path: '/settings-exams' }"
					class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to
					exams settings</router-link>
				<router-link v-else :to="{ path: '/questions-validator' }"
					class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to
					Home</router-link>
				<router-link to="/create-question" class="btn btn-secondary float-right createClass"><font-awesome-icon
						icon="plus" /> ADD QUESTION</router-link>
			</div>
			<div v-if="isAdmin" class="col-7 noMargin whiteColor">
				<router-link :to="{ path: '/settings' }" class="breadcumb"> Settings > </router-link>
				<router-link :to="{ path: '/settings-exams' }" class="breadcumb"> Exams settings > </router-link>
				<router-link :to="{ path: '/questions' }" class="final-breadcumb"> Questions </router-link>
			</div>
			<div v-else class="col-7 noMargin whiteColor">
				<router-link :to="{ path: '/questions' }" class="final-breadcumb"> Questions </router-link>
			</div>
		</div>

		<div class="col-sm-12 col-centered mt-2">
			<div id="message" v-if="questionRegistered" class="alert alert-success mt-4">
				Question registered succesfully! <b-button @click="close" class="cross-button"><font-awesome-icon
						class="cross" icon="times" /> </b-button>
			</div>
			<div id="message2" v-if="questionUpdated" class="alert alert-success mt-4">
				Question updated succesfully! <b-button @click="close2" class="cross-button"><font-awesome-icon
						class="cross" icon="times" /> </b-button>
			</div>
			<div id="message3" v-if="questionDeleted" class="alert alert-success mt-4">
				Questions deleted succesfully! <b-button @click="close3" class="cross-button"><font-awesome-icon
						class="cross" icon="times" /> </b-button>
			</div>
			<questions-list :questions="questions" :headers="headers" @deleteQuestion="deleteQuestion" />
		</div>
	</div>
</template>

<script>
import QuestionDataService from '@/services/QuestionDataService';
import QuestionsList from "@/components/QuestionComponents/List.vue";

export default {
	name: 'QuestionsView',
	data() {
		return {
			questions: [],
			questionDeleted: false
		}
	},
	components: {
		QuestionsList
	},
	computed: {
		headers() {
			return [
				{ key: 'question_title', label: 'Question title' }, { key: 'correct_answered_average', label: 'Average Correct Answers (%)', sortable: true, }, { key: 'question_tags', label: 'Tags' }, { key: 'difficulty', label: 'Level' }, { key: 'language', label: 'Language' }, /*{ key: 'created_at', label: 'Created at' },*/ { key: 'updated_at', label: 'Last edited' }, { key: 'options', label: 'Options' }
			];
		},
		currentUser() {
			return this.$store.state.auth.user;
		},
		isAdmin() {
			if (this.currentUser && this.currentUser.role) {
				if (this.currentUser.role.name === 'admin') {
					return true;
				}
			}
			return false;
		},
		isTM() {
			if (this.currentUser && this.currentUser.role) {
				if (this.currentUser.role.name === 'talent_manager' || this.currentUser.role.name === 'manager') {
					return true;
				}
			}
			return false
		}
	},
	mounted() {
		this.getQuestions();
	},
	props: {
		questionRegistered: {
			type: Boolean,
			default: false
		},
		questionUpdated: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		getQuestions() {
			QuestionDataService.findByArea(this.$store.state.auth.user.active_areas[this.$session.get("tabID")]).then(
				response => {
					this.questions = response.data
				},
				error => {
					console.log((error.response && error.response.data) ||
						error.message ||
						error.toString())

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		handleCreate(info) {
			this.getQuestions();
		},
		deleteQuestion() {
			this.questionDeleted = true
			this.getQuestions()
		},
		close() {
			document.getElementById("message").style.display = "none"
		},
		close2() {
			document.getElementById("message2").style.display = "none"
		},
		close3() {
			document.getElementById("message3").style.display = "none"
		}
	}
};
</script>

<style scoped>
#app>.bg-white .main-container {
	max-width: 100%;
	float: none;
	padding-left: 0px !important;
	padding-right: 0px !important;
	padding-top: 0px !important;
}

/* .createClass{
    background: border-box;
    border-radius: 26px;
    padding: inherit;
    margin-top: 0.6%;
	padding-right: 7px;
    padding-left: 7px;
} */

.alert {
	margin-left: 30px;
	margin-right: 30px;
}
</style>