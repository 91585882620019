<template>
	<div class="col-sm-12">
		<div class="row secondNavbar">
			<div class="col-5 links-container">
				<router-link v-if="isAdmin" :to="{ path: '/admin-configuration-areas' }"
					class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to Areas
					Configuration</router-link>
				<router-link
					:to="'/admin-configuration-users-create-user?area=' + area.name + '&id=' + area.id + '&previousPage=admin-configuration-areas-add-users'"
					class="btn btn-secondary float-right createClass"><font-awesome-icon icon="user-plus" /> CREATE
					USER</router-link>
			</div>
			<div v-if="isAdmin" class="col-7 noMargin whiteColor">
				<router-link :to="{ path: '/admin-configuration' }" class="breadcumb"> Configuration > </router-link>
				<router-link :to="{ path: '/admin-configuration-areas' }" class="breadcumb"> Areas > </router-link>
				<router-link :to="{ path: '/admin-configuration-areas-add-users' }" class="final-breadcumb"> Add Users
					to "{{ area.name }}"</router-link>
			</div>
		</div>

		<div class="col-sm-12 col-centered">
			<add-users-to-area />
		</div>
	</div>
</template>

<script>
import AreasDataService from '@/services/AreasDataService';
import AddUsersToArea from "@/components/AreasComponents/AddUsersToArea";

export default {
	name: 'CreateAreaView',
	data() {
		return {
			area: {}
		}
	},
	components: {
		AddUsersToArea
	},
	props: {
	},
	computed: {
		currentUser() {
			return this.$store.state.auth.user;
		},
		isAdmin() {
			if (this.currentUser && this.currentUser.role) {
				if (this.currentUser.role.name === 'admin') {
					return true;
				}
			}
			return false;
		}
	},
	mounted() {
		AreasDataService.findOne(this.$route.query.id).then(
			response => {
				this.area = response.data
			},
			error => {
				console.log((error.response && error.response.data) ||
					error.message ||
					error.toString())

				if (error.response.status === 300) {
					console.log('Logout: Token expired')
					localStorage.removeItem('user');
					localStorage.removeItem('company');
					this.$store.state.auth.user = null
					this.$store.dispatch('auth/logout');
					this.$router.push('/login');
				}
			}
		);
	},
	methods: {
	}
};
</script>

<style scoped>
#app>.bg-white .main-container {
	max-width: 100%;
	float: none;
	padding-left: 0px !important;
	padding-right: 0px !important;
	padding-top: 0px !important;
}

/* .createClass{
    background-color: #FFFF !important;
    background: border-box;
    border-radius: 26px;
    color: black !important;
    padding: inherit;
    margin-top: 0.6%;
	padding-right: 7px;
    padding-left: 7px;
} */

.nav-link-user-submenu {
	color: #13283B !important;
}
</style>
