<template>
    <div class="col-sm-12 col-centered">
        <add-s4 :s4Processes="s4Processes" />
    </div>
</template>

<script>
import S4DataService from "@/services/S4DataService";
import AddS4 from "@/components/BusinessProcessComponents/AddS4.vue";

export default {
    name: 'AddS4BusinessProcess',
    components: {
        AddS4,
    },
    props: ['clickedNext', 'currentStep'],
    data() {
        return {
            s4Processes: []
        }
    },
    mounted() {
    },
    methods: {
        async getS4Processes() {
            await S4DataService.getProcessesByArea(this.$store.state.auth.user.active_areas[this.$session.get("tabID")]).then(
                response => {
                    this.s4Processes = response.data;
                },
                error => {
                    this.dataLoading = false;
                    console.error((error.response && error.response.data) ||
                        error.message ||
                        error.toString())

                    if (error.response.status === 300) {
                        console.error('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );
        }
    }
}
</script>

<style scoped>
#app>.bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
}

/* .createClass{
    background: border-box;
    border-radius: 26px;
    padding: inherit;
    margin-top: 0.6%;
    padding-right: 7px;
    padding-left: 7px;
} */
</style>