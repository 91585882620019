import http from "../http-common";
import authHeader from './AuthHeaderService';

const API_URL_PATH = 'upload/';

class UploadDataService {

  uploadBoard() {
    return http.get(API_URL_PATH + 'uploadBoard', { headers: authHeader() });
  }

  uploadProfile(files, idUser, idArea, idCompany, email, anonymize, token, onUploadProgress) {
    let formData = new FormData();

    for (const i of Object.keys(files)) {
      formData.append('files', files[i])
    }

    let filename = ""
    if (idUser == null)
      filename = idUser+"_"+Date.now()+".pdf"
    else
      filename = idUser+"_"+Date.now()+".pdf"

    return http.post(API_URL_PATH + 'uploadCV', formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "id_user": idUser,
        "id_company": idCompany,
        "id_area": idArea,
        "email": email,
        "anonymize_cvs": anonymize,
        'x-access-token': token,
        'filename': filename
      },
      onUploadProgress
    });
  }

  uploadImage(imageFile, imageName, idUser, idArea, token, onUploadImage) {
    let formData = new FormData();

    formData.append("file", imageFile);

    return http.post(API_URL_PATH + 'uploadImage', formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "id_user": idUser,
        "id_area": idArea,
        'x-access-token': token, 
        'filename': imageName
      },
      onUploadImage
    });
  }

}

export default new UploadDataService();