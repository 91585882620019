<template>
  	<div class="wrapper">		
  		<div class="test-container" v-bind:id="'question-container_'+index" v-for="(question, index) in questions" v-bind:key="question.id">
			<div class="question-container">
				<!-- <button type="button" class="btn btn-secondary" @click="redirectToQuestionEdit(question.test_question.id_question , index)"><font-awesome-icon icon="edit" /> Edit question</button> -->
				<p class="title">{{question.question_title}}</p>
				<div class="content" v-if="question.question_content != '' && question.question_title != null">
					<div class="code" v-html="question.question_content"></div>
				</div>
				<div class="list" v-if="question.multiselection">
		            <div class="col-sm-12" v-bind:class = "{'alert alert-success':question.answer_one_correct}">
		                <label><input type="checkbox" disabled> {{question.answer_one}}</label>
		                <p v-if="question.answer_one_value != 0 && question.answer_one_correct">[{{question.answer_one_value}} %]</p>
		            </div>
		            <div class="col-sm-12" v-bind:class = "{'alert alert-success':question.answer_two_correct}">
		                <label><input type="checkbox" disabled> {{question.answer_two}}</label>
		                <p v-if="question.answer_two_value != 0 && question.answer_two_correct">[{{question.answer_two_value}} %]</p>
		            </div>
		            <div class="col-sm-12" v-bind:class = "{'alert alert-success':question.answer_three_correct}">
		                <label><input type="checkbox" disabled> {{question.answer_three}}</label>
		                <p v-if="question.answer_three_value != 0 && question.answer_three_correct">[{{question.answer_three_value}} %]</p>
		            </div>
		            <div class="col-sm-12" v-bind:class = "{'alert alert-success':question.answer_four_correct}">
		                <label><input type="checkbox" disabled> {{question.answer_four}}</label>
		                <p v-if="question.answer_four_value != 0 && question.answer_four_correct">[{{question.answer_four_value}} %]</p>
		            </div>
		        </div>

		        <div class="list" v-else>
		            <div class="col-sm-12" v-bind:class = "{'alert alert-success':question.answer_one_correct}">
		                <label><input class="form-input" disabled type="radio" name="multiselection" value="false" v-model="multiselection"> {{question.answer_one}}</label>
		                <p v-if="question.answer_one_value != 0 && question.answer_one_correct">[{{question.answer_one_value}} %]</p>
		            </div>
		            <div class="col-sm-12" v-bind:class = "{'alert alert-success':question.answer_two_correct}">
		                <label><input class="form-input" disabled type="radio" name="multiselection" value="false" v-model="multiselection"> {{question.answer_two}}</label>
		                <p v-if="question.answer_two_value != 0 && question.answer_two_correct">[{{question.answer_two_value}} %]</p>
		            </div>
		            <div class="col-sm-12" v-bind:class = "{'alert alert-success':question.answer_three_correct}">
		                <label><input class="form-input" disabled type="radio" name="multiselection" value="false" v-model="multiselection"> {{question.answer_three}}</label>
		                <p v-if="question.answer_three_value != 0 && question.answer_three_correct">[{{question.answer_three_value}} %]</p>
		            </div>
		            <div class="col-sm-12" v-bind:class = "{'alert alert-success':question.answer_four_correct}">
		                <label><input class="form-input" disabled type="radio" name="multiselection" value="false" v-model="multiselection"> {{question.answer_four}}</label>
		                <p v-if="question.answer_four_value != 0 && question.answer_four_correct">[{{question.answer_four_value}} %]</p>
		            </div>
		        </div>
			</div>
  		</div>
	</div>
</template>

<script>
	import TestDataService from "@/services/TestDataService";
	import Test from '@/models/test';

	export default {
	    name: "QuestionsPreview",
	    data: () => ({
	    	test: new Test('','','','','','','',
			  	'','','','','','','',
			  	'',''),
	    	questions: [],
	    	submitted: false,
	    	multiselection: null
	    }),
		mounted() {
			TestDataService.getTestQuestionsPreview(this.$route.query.id).then(
				response => {
					this.questions = response.data.questions
					for ( var i=0; i<this.questions.length; i++) {
						this.questions[i].answer_one_selected = 0;
						this.questions[i].answer_two_selected = 0;
						this.questions[i].answer_three_selected = 0;
						this.questions[i].answer_four_selected = 0;
						console.log(this.questions[i])

					}
				},
				error => {
					console.log((error.response && error.response.data) ||
						error.message ||
						error.toString())
					
					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
		    );

		    TestDataService.findOne(this.$route.query.id).then(
				response => {
					this.test = response.data;
				},
				error => {
					console.log((error.response && error.response.data) ||
						error.message ||
						error.toString())
					
					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
		    );

		    if (this.testFlag && !isNaN(this.questionIndex)) {
				const interval = setInterval(() => {
					if (document.getElementsByClassName('page-item').lenght != 0) {
				      	this.navigateTest(this.questionIndex)
				      	clearInterval(interval);
				      	return;
					}
				}, 100)
		    }
	  	},
		methods: {
			nextQuestion(index) {
				var nextIndex = index+1
				document.getElementById('question-container_'+index).style.display = 'none'
				document.getElementById('question-container_'+nextIndex).style.display = 'block'
				let navigationItems = document.getElementsByClassName('page-item');
				for (var i=0; i<navigationItems.length; i++) {
					navigationItems[i].classList.remove('active')
				}
				document.getElementsByClassName('page-item')[nextIndex].classList.add('active')
			},
			previousQuestion(index) {
				var previousIndex = index-1
				document.getElementById('question-container_'+index).style.display = 'none'
				document.getElementById('question-container_'+previousIndex).style.display = 'block'
				let navigationItems = document.getElementsByClassName('page-item');
				for (var i=0; i<navigationItems.length; i++) {
					navigationItems[i].classList.remove('active')
				}
				document.getElementsByClassName('page-item')[previousIndex].classList.add('active')
			},
			navigateTest(index) {
				let navigationItems = document.getElementsByClassName('page-item');
				for (var i=0; i<navigationItems.length; i++) {
					navigationItems[i].classList.remove('active')
				}
				document.getElementsByClassName('page-item')[index].classList.add('active')
				let questionsContainers = document.getElementsByClassName('question-container');
				for (var j=0; j<questionsContainers.length; j++) {
					questionsContainers[j].style.display = 'none';
				}
				document.getElementById('question-container_'+index).style.display = 'block'
			},
			redirectToQuestionEdit(id, index) {
				this.$router.replace({ name: "update-question", params: {testFlag:true, questionIndex: parseInt(index), id_test: parseInt(this.$route.query.id)}, query: {id: id} })
			}
		},
		props: {
		    testFlag: Boolean,
		    questionIndex: Number,
		}
	};
</script>