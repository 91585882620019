<template>
	<div class="col-sm-12">
		<div class="row secondNavbar">
			<div class="col-5 links-container">
				<router-link v-if="isAdmin || isTM" :to="{ path: '/settings-certifications' }"
					class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" />
					Back to certifications settings</router-link>
				<router-link v-else :to="{ path: '/certifications-validator' }"
					class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to
					Home</router-link>
				<router-link to="/create-certification"
					class="btn btn-secondary float-right createClass"><font-awesome-icon icon="plus" /> ADD
					CERTIFICATION</router-link>
			</div>
			<div v-if="isAdmin || isTM" class="col-7 noMargin whiteColor">
				<router-link :to="{ path: '/settings' }" class="breadcumb"> Settings > </router-link>
				<router-link :to="{ path: '/settings-certifications' }" class="breadcumb"> Certifications settings > </router-link>
				<router-link :to="{ path: '/certifications' }" class="final-breadcumb"> Certifications </router-link>
			</div>
			<div v-else class="col-7 noMargin whiteColor">
				<router-link :to="{ path: '/certifications' }" class="final-breadcumb"> Certifications </router-link>
			</div>
		</div>

		<div class="col-sm-12 col-centered mt-2">
			<div id="message" v-if="certificationRegistered" class="alert alert-success align-item-center">
				Certification registered succesfully! <b-button @click="close" class="cross-button"><font-awesome-icon
						class="cross" icon="times" /> </b-button>
			</div>
			<div id="message2" v-if="certificationUpdated" class="alert alert-success align-item-center">
				Certification updated succesfully! <b-button @click="close2" class="cross-button"><font-awesome-icon
						class="cross" icon="times" /> </b-button>
			</div>
			<div id="message3" v-if="certificationDeleted" class="alert alert-success align-item-center">
				Certification deleted succesfully! <b-button @click="close3" class="cross-button"><font-awesome-icon
						class="cross" icon="times" /> </b-button>
			</div>
			<certification-configuration :certifications="certifications" @deleteCertification="deleteCertification" />
		</div>
	</div>
</template>

<script>
import CertificationsDataService from '@/services/CertificationsDataService';
import CertificationConfiguration from "@/components/CertificationsComponents/List.vue";

export default {
	name: 'Certifications',
	data() {
		return {
			certifications: [],
			certificationDeleted: false
		}
	},
	components: {
		CertificationConfiguration
	},
	computed: {
		currentUser() {
			return this.$store.state.auth.user;
		},
		isAdmin() {
			if (this.currentUser && this.currentUser.role) {
				if (this.currentUser.role.name === 'admin') {
					return true;
				}
			}
			return false;
		},
		isTM() {
			if (this.currentUser && this.currentUser.role) {
				if (this.currentUser.role.name === 'talent_manager' || this.currentUser.role.name === 'manager') {
					return true;
				}
			}
			return false
		}
	},
	props: {
		certificationRegistered: {
			type: Boolean,
			default: false
		},
		certificationUpdated: {
			type: Boolean,
			default: false
		}
	},
	mounted() {
		this.getCertifications()
	},
	methods: {
		getCertifications() {
			CertificationsDataService.findByArea(this.$store.state.auth.user.active_areas[this.$session.get("tabID")]).then(
				response => {
					let certificationsObject = response.data
					let certificationsTmp = []
					for (var i = 0; i < certificationsObject.length; i++) {
						certificationsTmp.push(certificationsObject[i])
					}

					this.certifications = certificationsTmp
				},
				error => {
					console.log((error.response && error.response.data) ||
						error.message ||
						error.toString())

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		handleCreate(info) {
			this.getCertifications();
		},
		deleteCertification() {
			this.certificationDeleted = true
			this.getCertifications()
		},
		close() {
			document.getElementById("message").style.display = "none"
		},
		close2() {
			document.getElementById("message2").style.display = "none"
		},
		close3() {
			document.getElementById("message3").style.display = "none"
		}
	}
};
</script>

<style scoped>
#app>.bg-white .main-container {
	max-width: 100%;
	float: none;
	padding-left: 0px !important;
	padding-right: 0px !important;
	padding-top: 0px !important;
}

/* .createClass{
    background-color: #FFFF !important;
    background: border-box;
    border-radius: 26px;
  	margin-top: 0.6%;
    color: black !important;
    padding: inherit;
} */

.alert {
	margin: 0 30px;
}
</style>
