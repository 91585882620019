<template>
  	<div class="form-container technologiesStyle">
  		<form action class="form" @submit.prevent="handleUpdate">
			<div id="message" v-if="message != ''" class="col-md-12 align-item-center alert" :class="successfull ? 'alert-success' : 'alert-danger'">
		  		{{message}} <b-button @click="close" class="cross-button"><font-awesome-icon  class="cross" icon="times" /> </b-button>
		  	</div>

  			<div class="row form-group align-item-center">
  				<div class="row">
  					<div class="col-sm-6">
						<input class="btn btn-primary" type="submit" value="Update">
  					</div>
  					<div class="col-sm-6">
						<button class="btn btn-secondary btn-cancel" @click="backToTests()"><font-awesome-icon icon="ban" /> Cancel</button>
  					</div>
  				</div>
			</div>

			<div class="row mt-3">
				<h4>General Settings <b-button class="btn btn-info" v-b-tooltip.hover title="You must specify the name and definition of the test"><font-awesome-icon icon="info-circle" /></b-button></h4>
				<div class="row">
					<div class="row form-group">
						<label>Title*</label>
				      	<input class="form-input" type="text" id="title" v-validate="'required'" data-vv-name="title" v-model="test.title">
				      	<div v-if="submitted && veeErrors.has('title')" class="alert alert-danger">
					    	{{veeErrors.first('title')}}
					    </div>
			    	</div>
	  				<div class="row form-group">
						<label>Description</label>
				      	<textarea class="form-input col-sm-12" id="description" rows="6" data-vv-name="description" v-model="test.description" maxlength="512"></textarea>
				      	<div v-if="submitted && veeErrors.has('description')" class="alert alert-danger">
					    	{{veeErrors.first('description')}}
					    </div>
			    	</div>
				</div>
  			</div>

  			<div class="row mt-3">
				<h4>Filters* <b-button class="btn btn-info" v-b-tooltip.hover title="In the filters section you must specify the tags of the test"><font-awesome-icon icon="info-circle" /></b-button></h4>
  				<multiselect disabled
					:options="tagsToShow"
					:multiple="true"
					:close-on-select="false" 
	            	:preserve-search="true"
					:taggable="true"
					:preselect-first="false"
					placeholder="Search or add a tag"
					v-model="selectedTags"
					label="label"
					track-by="value"
					@select="selectOption"
					@remove="removeOption"></multiselect>
  			</div>

  			<div class="row mt-3">
				<h4>Timing <b-button class="btn btn-info" v-b-tooltip.hover title="In the timing section you must specify the dates the test"><font-awesome-icon icon="info-circle" /></b-button></h4>
  				<div class="row">
  					<div class="col-sm-4">
	  					<div class="row form-group">
							<label>Start date*</label>
					      	<input class="form-input" type="date" data-vv-name="start_date" v-model="test.start_date">
				    	</div>
  					</div>
  					<div class="col-sm-4">
	  					<div class="row form-group">
							<label>End date*</label>
					      	<input class="form-input" type="date" data-vv-name="end_date" v-model="test.end_date" :min="test.start_date">
				    	</div>
  					</div>
  					<div class="col-sm-4">
	  					<div class="row form-group">
							<label>Time limit (minutes)</label>
					      	<input class="form-input" type="text" data-vv-name="time_limit" v-model="test.time_limit">
				    	</div>
  					</div>
  				</div>
  			</div>

  			<div class="row mt-3">
				<h4>Other configurations <b-button class="btn btn-info" v-b-tooltip.hover title="In the other configurations section you must specify additional parameters of the test"><font-awesome-icon icon="info-circle" /></b-button></h4>
  				<div class="row">
  					<div class="col-sm-6">
  						<div class="row">
	  						<div class="row form-group">
								<label>Level of difficulty*</label>
								<select disabled class="form-select" v-validate="'required'" v-model="test.id_difficulty_level"  data-vv-name="difficulty" id="difficulty">
						      		<option v-for="item in difficulties" v-bind:key="item.id" v-bind:value="item.id">
								       {{ item.name.toUpperCase() }}
								    </option>
						      	</select>
						      	<div v-if="submitted && veeErrors.has('difficulty')" class="alert alert-danger">
							    	{{veeErrors.first('difficulty')}}
							    </div>
					    	</div>
	  					</div>
						<div class="row">
  							<div class="row form-group">
								<label>Number of questions</label>
						      	<input disabled class="form-input right-short-input" type="number" data-vv-name="number_questions" v-model="test.number_questions">
					    	</div>
  						</div>
	  					<div class="row">
	  						<div class="row form-group">
								<label>Questions level of difficulty*</label>
								<br>
						      	<label><input disabled class="form-input" type="radio" name="difficulty_configuration" value="1" v-model="test.questions_same_difficulty"> Same level of difficulty for all questions</label>
						      	<br>
						      	<label><input disabled class="form-input" type="radio"  name="difficulty_configuration" value="0" v-model="test.questions_same_difficulty"> Average difficulty for questions</label>
					    	</div>
	  					</div>	
  					</div>
  					<div class="col-sm-6">
						<div class="row">
	  						<div class="row form-group">
								<label>Language*</label>
								<select disabled class="form-select" v-validate="'required'" v-model="test.id_language"  data-vv-name="language" id="language">
						      		<option v-for="item in languages" v-bind:key="item.id" v-bind:value="item.id">
								       {{ item.name.toUpperCase() }}
								    </option>
						      	</select>
						      	<div v-if="submitted && veeErrors.has('language')" class="alert alert-danger">
							    	{{veeErrors.first('language')}}
							    </div>
					    	</div>
	  					</div>
	  						
	  					<div class="row">
	  						<div class="row form-group">
								<label>Wrong answer penalty*</label>
						      	<input class="form-input right-short-input" type="number" step="any" data-vv-name="incorrect_value" v-model="test.incorrect_value">
					    	</div>
	  					</div>

	  					<div class="row">
	  						<div class="row form-group">
								<label>Maximum attempts</label>
						      	<input class="form-input right-short-input" type="number" data-vv-name="number_attempts" v-model="test.number_attempts">
					    	</div>
	  					</div>

	  					<div class="row">
	  						<div class="row form-group">
						      	<label><input class="form-input right-short-input" type="checkbox" data-vv-name="shuffle" v-model="test.shuffle">Shuffle questions</label>
					    	</div>
	  					</div>
  					</div>
  				</div>
  			</div>
		</form>
	</div>
</template>

<script>
	import TestDataService from "@/services/TestDataService";
	import Test from '@/models/test';
	import $ from 'jquery'
	import Multiselect from 'vue-multiselect';

	export default {
	    name: "TestListComponent",
	    data() {
		  	return {
		  		title: "Update test",
		    	test: new Test('','','','','','','',
					'','','','','','','',
					'','',''),
		        message: "",
			  	successfull: false,
			  	submitted: false,
			  	difficulties: [],
			  	languages: [],
			  	selectedTags: [],
			  	tags: [],
				tagsToShow: [],
				tagsToAdd: [],
			  	questionsDifficultySameLevel: true,
	    	}
		},
		components: {
		    Multiselect
		},
		methods: {
			getTestBoard(){
				const currentAreaID = this.$store.state.auth.user.active_areas[this.$session.get("tabID")]
				TestDataService.getTestBoard(currentAreaID).then(
					response => {
						if (response.status == 200) {
							this.difficulties = response.data.difficulty_levels
							this.languages = response.data.languages
							
							let tempTags = response.data.tags;
							tempTags.forEach((tag) => {
								this.tags.push(tag)
								this.tagsToShow.push({label: tag.name, value: tag.name + '-' + tag.id, id: tag.id})
							});
						}
					},
					error => {
						console.log((error.response && error.response.data) ||
							error.message ||
							error.toString())
						
						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
			    );
			},
			getTags(){
				TestDataService.getTags(this.$route.query.id).then(
					response => {
						if (response.status == 200) {
							let tempTags = response.data;
							tempTags.forEach((tag) => {
								this.tagsToAdd.push({label: tag.name, value: tag.name + '-' + tag.id, id: tag.id});
								this.selectedTags.push({label: tag.name, value: tag.name + '-' + tag.id, id: tag.id});
							});
						}
					},
					error => {
						console.log((error.response && error.response.data) ||
							error.message ||
							error.toString())
						
						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
			    );
			},
			getTest(){
				TestDataService.findOne(this.$route.query.id).then(
					response => {
						if (response.status == 200) {
							this.test = response.data;

							let startDateTmp = new Date(this.test.start_date);
							let monthSeparator = "-0";
							if (startDateTmp.getMonth()+1 >= 10)
								monthSeparator = "-";
							this.test.start_date = startDateTmp.getFullYear() + monthSeparator + (startDateTmp.getMonth()+1) + "-" + startDateTmp.getDate();

							let endDateTmp = new Date(this.test.end_date);
							if (endDateTmp.getMonth()+1 >= 10)
								monthSeparator = "-";
							this.test.end_date = endDateTmp.getFullYear()  + monthSeparator + (endDateTmp.getMonth()+1) + "-" + endDateTmp.getDate();

							if (this.test.questions_average_difficulty) {
								this.questionsDifficultySameLevel = false
							}
							
							if (this.questionsDifficultySameLevel) {
								this.test.questions_same_difficulty = 1
								this.test.questions_average_difficulty = 0
							} else {
								this.test.questions_same_difficulty = 0
								this.test.questions_average_difficulty = 1
							}
						}
					},
					error => {
						console.log((error.response && error.response.data) ||
							error.message ||
							error.toString())
						
						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
			    );
			},
			selectOption(option) {
				var object = {}
				var parsedOption = JSON.parse(JSON.stringify(option))

				object.label = parsedOption.label
				object.value = parsedOption.value
				var valueSplit = parsedOption.value.split('-')
				object.id = parseInt(valueSplit[1])
				this.tagsToAdd.push(object)				
			},
			removeOption(option) {
				var parsedOption = JSON.parse(JSON.stringify(option))
				var valueSplit = parsedOption.value.split('-')
				const index = this.tagsToAdd.map(object => object.id).indexOf(parseInt(valueSplit[1]));
				
				this.tagsToAdd.splice(index, 1)
			},
			backToTests() {
				this.$router.push({ path: 'tests'})
			},
			handleUpdate() {
	  			this.submitted = true;
	  			this.$validator.validate().then(isValid => {
	  				if (!isValid) {
	  					this.message = "Please, fill all the fields correctlly";
	  					return;
	  				}

	  				if (this.questionsDifficultySameLevel) {
  						this.test.questions_same_difficulty = 1
  						this.test.questions_average_difficulty = 0
  					} else {
  						this.test.questions_same_difficulty = 0
  						this.test.questions_average_difficulty = 1
  					}

					if(this.test.start_date == "" && this.test.end_date == "") {
						this.message = "Please, set the date range in which the test is available";
						return;
					}

					if(new Date(this.test.end_date) < new Date(this.test.start_date)) {
						this.message = "Please, set the date range correctly. End date is before start date";
						return;
					}

					if(this.test.incorrect_value < 0) {
						this.message = "Please, set the value of an incorrect question punishment"
						return;
					}

					if (this.test.questions_average_difficulty == "" && this.test.questions_same_difficulty == "") {
						this.message = "Please, select the questions difficulty configuration"
						return;
					}

					if (this.tagsToAdd.length == 0) {
						this.message = "Select at least one tag for test's categorization";
						return;
					}

					const currentAreaID = this.$store.state.auth.user.active_areas[this.$session.get("tabID")]
  					TestDataService.update(this.test, this.tagsToAdd, currentAreaID).then(
  						response => {
  							if (response.status == 200) {
  								this.successful = true;
  								this.$router.push({ name: "tests", params: {testUpdated: true} });
  							} else {
  								this.message = response.data.message
  							}
  						},
  						error => {
  							console.log((error.response && error.response.data) ||
								error.message ||
								error.toString())
				          	this.message = error.response.data.message
							
							if (error.response.status === 300) {
								console.log('Logout: Token expired')
								localStorage.removeItem('user');
								localStorage.removeItem('company');
								this.$store.state.auth.user = null
								this.$store.dispatch('auth/logout');
								this.$router.push('/login');
							}
  						}
					);
	  			});
	  		},
			close() {
				this.message = "";
			}
		},
		mounted() {
			this.getTestBoard();
		    this.getTags();
		    this.getTest();
	  	}
	};
</script>