<template>
	<div class="form-container my-2">
		<div v-if="fileReceived" class="progress">
			<div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
				:aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100" :style="{ width: progress + '%' }">
				{{ progress }}%
			</div>
		</div>

		<div class="col-sm-12 offset-sm-12">
			<div class="row" style="margin-bottom: 1%; margin-top: 1%;">
				<div class="col-sm-9" style="padding-top: 0px !important;">
					<h5>
						Upload your CVs here <b-button class="btn btn-info" v-b-tooltip.hover title="More info"
							v-b-modal.profile-info><font-awesome-icon icon="info-circle" /></b-button>
					</h5>
				</div>
				<div class="col-sm-2" style="padding-top: 10px !important;">
					<input v-if="isAdmin" class="form-check-input" v-model="anonymizeCVs" type="checkbox"
						id="gridCheckCV" style="margin: 5px">Anonymize CVs</input>
				</div>


				<div class="col-sm-1">
					<button class="btn btn-primary" :disabled="uploadDisactive || moreMaxFiles" @click="upload">
						Upload {{ fileRecordsForUpload.length }} files
					</button>
				</div>

				<div id="message" v-if="message" class="col-md-12 align-item-center alert"
					:class="successfull ? 'alert-success' : 'alert-danger'">
					{{ message }}
					<b-button @click="close" class="cross-button"><font-awesome-icon class="cross" icon="times" />
					</b-button>
				</div>
			</div>
			<div class="row">
				<VueFileAgent ref="vueFileAgent" :theme="'list'" :multiple="isAdmin" :deletable="true" :meta="true"
					:disabled="fileReceived" :accept="'application/pdf'" :maxSize="'10MB'" :editable="true"
					:helpText="'Choose PDF files to upload your CVs (not compressed)'" :errorText="{
			type: 'Invalid file type. Only .pdf files Allowed',
			size: 'Files should not exceed 10MB in size',
		}" @select="filesSelected($event)" @beforedelete="onBeforeDelete($event)" @delete="fileDeleted($event)"
					v-model="fileRecords"></VueFileAgent>
			</div>
		</div>

		<b-modal id="profile-info" title="Professional Profile Analysis Info" size="lg">
			<div class="col-sm-12">
				Only .pdf files are available for the profile analyzer. If you want to upload several CVs, they all have
				to be in PDF format and cannot be compressed. The best option for uploading a new file, is downloading
				your CV from your linkedin profile. This image show where you can do it on your linkedin profile.
				<div class="col-sm-12">
					<br>
					<img src="images/linkedin_cv.png" class="container-fluid">
				</div>
			</div>
		</b-modal>

		<div class="col-sm-12" v-if="progress == 100">
			<br>
			<h5 class="text-center">Files uploaded succesfully. Please wait while processing data...</h5>
			<img src="images/processing.gif">
		</div>
	</div>
</template>

<script>
import UploadDataService from "@/services/UploadDataService";
import ProfileAnalysisDataService from "@/services/ProfileAnalysisDataService";

export default {
	name: "UploadCVComponent",
	data() {
		return {
			title: "Upload New CV",
			fileRecords: [],
			fileRecordsForUpload: [],
			progress: 0,
			isAdmin: false,
			message: "",
			successfull: false,
			file: '',
			uploadDisactive: true,
			moreMaxFiles: false,
			fileReceived: false,
			numOfFiles: 0,
			exitPage: false,
			tokenChat: null,
			currentToken: null,
			profile: {},
			definedPercentage: 0,
			anonymizeCVs: false
		}
	},
	directives: {
		uploader: {
			bind(el, binding, vnode) {
				el.addEventListener('change', e => {
					vnode.context.file = e.target.files[0];
				});
			}
		},
	},
	watch: {
		file(val) {
			this.$emit('file-chosen', val);
		},
		fileRecords() {
			if (this.fileRecords.length > this.currentUser.company_settings.max_cv_uploads) {
				this.moreMaxFiles = true
				this.message = 'Maximum number of CVs that can be uploaded at one time is ' + this.currentUser.company_settings.max_cv_uploads
			} else {
				this.moreMaxFiles = false
				this.message = ""
			}
		}
	},
	computed: {
		currentUser() {
			return this.$store.state.auth.user;
		},
		showAdminBoard() {
			if (this.currentUser && this.currentUser.role) {
				if (this.currentUser.role.name == 'admin') {
					return true;
				}
			}
			return false;
		}
	},
	mounted() {
		this.anonymizeCVs = this.$store.state.auth.user.company_settings.anonymize_token_use

		if (this.$store.state.auth.user.role.name == 'admin')
			this.isAdmin = true
		else
			this.isAdmin = false
	},
	methods: {
		launchFilePicker() {
			this.$refs.file.click();
		},
		addFile(e) {
			this.file = e.dataTransfer.files[e.dataTransfer.files.length - 1]
		},
		loadParameters(token) {
			ProfileAnalysisDataService.findByChatToken(token).then(
				response => {
					if (response.status != 200) {
						this.$router.push({ name: "analyses" });
					} else {
						this.idProfile = response.data.id
						this.profile = response.data
						this.currentToken = token;

						var totalFields = 6
						var countDefinedFields = 0

						if (this.profile.certifications != '') {
							let certifications = this.profile.certifications
							certifications = certifications.replaceAll("'", "\"");
							certifications = JSON.parse(certifications)
							this.certifications = certifications
							countDefinedFields++;
						}

						if (this.profile.skills != '') {
							let skills = this.profile.skills
							skills = skills.replaceAll("'", "\"");
							skills = JSON.parse(skills)
							this.skills = skills
							countDefinedFields++;
						}

						if (this.profile.education != '') {
							let education = this.profile.education
							education = education.replaceAll("'", "\"");
							education = JSON.parse(education)
							this.education = education
							countDefinedFields++;
						}

						if (this.profile.experience_jobs != '') {
							let jobs = this.profile.experience_jobs;
							jobs = jobs.replaceAll("'", "\"");
							jobs = JSON.parse(jobs)
							this.jobs = jobs
							countDefinedFields++;
						}

						if (this.profile.experience != '') {
							let experience = this.profile.experience_time;
							experience = experience.replaceAll("'", "\"");
							experience = JSON.parse(experience)
							this.experience = experience
							countDefinedFields++;
						}

						if (this.profile.languages != '') {
							let languages = this.profile.languages;
							languages = languages.replaceAll("'", "\"");
							languages = JSON.parse(languages)
							this.languages = languages
							countDefinedFields++;
						}

						this.definedPercentage = (countDefinedFields / totalFields) * 100;
						this.definedPercentage = Math.round(this.definedPercentage, -1);

						if (this.definedPercentage == 100) {
							this.$router.replace({ name: "profile-analysis", query: { id: response.data.id } })
						} else {
							this.$refs['ask-type'].show();
						}
					}
				},
				error => {
					this.$router.push({ name: "analyses" });
					console.log((error.response && error.response.data) ||
						error.message ||
						error.toString())

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		upload() {
			this.progress = 0;
			this.uploadDisactive = false;
			this.fileReceived = false;
			var idUser = null;
			var idArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];

			if (!this.showAdminBoard) {
				idUser = this.$store.state.auth.user.id
			}

			let filesToUpload = this.fileRecordsForUpload.map(function (f) { return f.file })
			UploadDataService.uploadProfile(filesToUpload, idUser, idArea, this.$store.state.auth.user.id_company, this.currentUser.email, this.anonymizeCVs, this.$store.state.auth.user.accessToken, event => {
				this.uploadDisactive = true;
				this.fileReceived = true;
				this.progress = Math.round((100 * event.loaded) / event.total);

				this.$refs['ask-modal'].show();
			})
				.then(response => {
					if (response.status == 200) {
						this.successfull = true
						if (this.showAdminBoard) {
							if (this.$store.state.auth.user.role.name == 'admin')
								this.$router.push({ name: "user-analysis", params: { companyAnalysisRegistered: true } });
							else
								this.$router.push({ name: "analyses", params: { companyAnalysisRegistered: true } });
						} else {
							if (this.exitPage) {
								this.showStartChatModal(response.data.token);
							} else {
								if (response.data.token != undefined) {
									if (this.fileRecordsForUpload.length > 1) {
										this.message = response.data.message
										if (this.$store.state.auth.user.role.name == 'admin')
											this.$router.push({ name: "user-analysis", params: { companyAnalysisRegistered: true } });
										else
											this.$router.push({ name: "analyses", params: { companyAnalysisRegistered: true } });
									} else {
										this.loadParameters(response.data.token);
									}
								} else {
									this.message = "Error.No token found to process information"
									this.message = response.data.message
									if (this.$store.state.auth.user.role.name == 'admin')
										this.$router.push({ name: "user-analysis", params: { companyAnalysisRegistered: true } });
									else
										this.$router.push({ name: "analyses", params: { companyAnalysisRegistered: true } });
								}
							}
						}
					} else {
						this.message = response.data.message
						if (this.$store.state.auth.user.role.name == 'admin')
							this.$router.push({ name: "user-analysis", params: { companyAnalysisRegistered: true, errorMsg: this.message } });
						else
							this.$router.push({ name: "analyses", params: { companyAnalysisRegistered: true, errorMsg: this.message } });
					}
				})
				.then(files => {
					console.log('')
				})
				.catch((error) => {
					console.log(error.message)
					this.progress = 0;
					this.message = "There was an error while processing your profile. Please, remenber that only .pdf files are allowed!";
					this.successfull = false;
					this.fileRecordsForUpload = [];
					this.uploadDisactive = this.fileRecordsForUpload.length <= 0 ? true : false
					this.fileReceived = false;
					if (this.$store.state.auth.user.role.name == 'admin')
						this.$router.push({ name: "user-analysis", params: { errorMsg: this.errorMsg, companyAnalysisRegistered: true }, query: {} });
					else
						this.$router.push({ name: "analyses", params: { errorMsg: this.errorMsg, companyAnalysisRegistered: true }, query: {} });
				});
		},
		deleteUploadedFile: function (fileRecord) {
			this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
		},
		filesSelected: function (fileRecordsNewlySelected) {
			var validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);
			if (validFileRecords.length > 0) {
				this.uploadDisactive = false;
				this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
			} else {
				this.uploadDisactive = true;
			}
		},
		onBeforeDelete: function (fileRecord) {
			var i = this.fileRecordsForUpload.indexOf(fileRecord);
			if (i !== -1) {
				this.fileRecordsForUpload.splice(i, 1);
				var k = this.fileRecords.indexOf(fileRecord);
				if (k !== -1) this.fileRecords.splice(k, 1);
			} else {
				if (confirm('Are you sure you want to delete?')) {
					this.$refs.vueFileAgent.deleteFileRecord(fileRecord);
				}
			}
		},
		fileDeleted: function (fileRecord) {
			var i = this.fileRecordsForUpload.indexOf(fileRecord);
			if (i !== -1) {
				this.fileRecordsForUpload.splice(i, 1);
			} else {
				this.deleteUploadedFile(fileRecord);
			}

			this.uploadDisactive = this.fileRecordsForUpload.length <= 0 ? true : false
		},
		formRedirect() {
			this.$router.replace({ name: "interview-form", params: { analysisRegistered: true }, query: { token: this.currentToken } })
		},
		chatbotRedirect() {
			this.$router.replace({ name: "chat", params: { analysisRegistered: true }, query: { token: this.currentToken } })
		},
		redirect() {
			this.exitPage = true;
			if (this.isAdmin) this.$router.replace({ name: "user-analysis" });
			else this.$router.replace({ name: "analyses" });
		},
		showStartChatModal(token) {
			this.tokenChat = token
			this.$refs['start-chat-modal'].show();
		},
		startChat() {
			ProfileAnalysisDataService.findByChatToken(response.data.token).then(
				response2 => {
					if (response.status != 200) {
						this.$router.push({ name: "analyses" });
					} else {
						this.idProfile = response.data.id
						this.profile = response.data

						var totalFields = 6
						var countDefinedFields = 0

						if (this.profile.certifications != '') {
							let certifications = this.profile.certifications
							certifications = certifications.replaceAll("'", "\"");
							certifications = JSON.parse(certifications)
							this.certifications = certifications
							countDefinedFields++;
						}

						if (this.profile.skills != '') {
							let skills = this.profile.skills
							skills = skills.replaceAll("'", "\"");
							skills = JSON.parse(skills)
							this.skills = skills
							countDefinedFields++;
						}

						if (this.profile.education != '') {
							let education = this.profile.education
							education = education.replaceAll("'", "\"");
							education = JSON.parse(education)
							this.education = education
							countDefinedFields++;
						}

						if (this.profile.experience_jobs != '') {
							let jobs = this.profile.experience_jobs;
							jobs = jobs.replaceAll("'", "\"");
							jobs = JSON.parse(jobs)
							this.jobs = jobs
							countDefinedFields++;
						}

						if (this.profile.experience != '') {
							let experience = this.profile.experience_time;
							experience = experience.replaceAll("'", "\"");
							experience = JSON.parse(experience)
							this.experience = experience
							countDefinedFields++;
						}

						if (this.profile.languages != '') {
							let languages = this.profile.languages;
							languages = languages.replaceAll("'", "\"");
							languages = JSON.parse(languages)
							this.languages = languages
							countDefinedFields++;
						}

						this.definedPercentage = (countDefinedFields / totalFields) * 100;
						this.definedPercentage = Math.round(this.definedPercentage, -1);

						if (this.definedPercentage == 100) {
							this.$router.replace({ name: "profile-analysis", query: { id: response.data.id } })
						} else {
							this.$router.replace({ name: "chat", params: {}, query: { token: this.tokenChat } })
						}
					}
				},
				error => {
					this.$router.push({ name: "analyses" });
					console.log((error.response && error.response.data) ||
						error.message ||
						error.toString())

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		close() {
			document.getElementById("message").style.display = "none"
		}
	}
};
</script>

<style scoped>
.suite-tools-submenu {
	color: #13283B !important;
}
</style>