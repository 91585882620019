<template>
	<div v-bind:class="{ 'table-container': !home, 'col-sm-12': home }" class="technologiesStyle my-2">
		<!-- <div class="row mt-3">
			<router-link v-b-tooltip.hover title="Go to ranking for hard skills evaluated candidates"
				:to="{ path: '/tests-ranking' }"
				class="btn btn-primary ranking-btn white-color align-item-center"><font-awesome-icon class="white-color"
					icon="trophy" /> Hard Skills Ranking</router-link>
		</div> -->
		<template v-if="tests.length > 0">
			<div id="message" v-if="message != ''" class="alert"
				:class="successfull ? 'alert-success' : 'alert-danger'">
				{{ message }} <b-button @click="close" class="cross-button"><font-awesome-icon class="cross"
						icon="times" /> </b-button>
			</div>
			<div class="row" style="margin-top: 1%;">
				<div class="col-xl-3 col-lg-3">
					<b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
						aria-controls="testsList"></b-pagination>
				</div>
				<div class="col-xl-6 col-lg-6">
					<b-form-select class="numPerPage paginationSelectorTable" v-model="perPage"
						:options="pageOptions"></b-form-select>
					<div class="numPerPage paginationSelectorTable mx-3 my-2">Level of difficulty: </div>
					<b-form-select class="numPerPage paginationSelectorTable" v-model="difficultyModel"
						@change="filterTests" :options="levels"> </b-form-select>

					<button v-b-tooltip.hover title="Select all rows" class="btn btn-primary table-selection-button"
						type="button" @click="selectAllRows"><font-awesome-icon icon="check" class="white-color" />
						Select all rows </button>
					<button v-b-tooltip.hover title="Clear All" class="btn btn-primary table-selection-button"
						type="button" @click="clearSelected"><font-awesome-icon icon="ban" class="white-color" /> Clear
						all </button>
					<button v-b-tooltip.hover :title="'Delete ' + selectedItems.length + ' rows'"
						v-if="selectedItems.length > 0"
						class="btn btn-primary table-selection-button delete-button my-1" type="button"
						@click="showDeleteModalMultiple"><font-awesome-icon icon="trash" class="white-color" /> Delete
						{{ selectedItems.length }} rows</button>
				</div>
				<div class="col-xl-3 col-lg-3 search-input">
					<b-form-input id="filter-input" v-model="filter" type="search"
						:placeholder="$t('others.search-placeholder-msg')"></b-form-input>
				</div>
			</div>
			<b-table ref="testsTable" id="testsList" :per-page="perPage" :current-page="currentPage" :fields="headers"
				:items="filterTests()" :filter="filter" select-mode="multi" responsive="sm" selectable
				@row-selected="onRowSelected">
				<template #cell(selected)="{ rowSelected }">
					<template v-if="rowSelected">
						<span aria-hidden="true">&check;</span>
						<span class="sr-only">Selected</span>
					</template>
					<template v-else>
						<span aria-hidden="true">&nbsp;</span>
						<span class="sr-only">Not Selected</span>
					</template>
				</template>
				<template #cell(title)="data">
					{{ data.item.title }}
					<ul class="ti-tags small ps-0">
						<li class="ti-tag ti-valid" v-for="item in data.item.tags" v-bind:key="item.id">
							<div class="ti-content">
								<div class="ti-tag-center">
									<span>{{ item.name }}</span>
								</div>
							</div>
						</li>
					</ul>
				</template>
				<template #cell(done)="data">
					{{ data.item.done }}
				</template>
				<template #cell(average_qualification)="data">
					<div v-if="data.item.average_qualification != null || data.item.average_qualification == ''">
						{{ data.item.average_qualification.toFixed(2) }}
					</div>
					<div v-else>
						Not available
					</div>
				</template>
				<template #cell(difficulty_level)="data">
					<p v-if="data.item.numberQuestions != 0" class="difficultyLevelTag"
						:style="'background-color: ' + colorDiff[data.item.difficulty_level]">
						{{ data.item.difficulty_level.toUpperCase() }}
					</p>
					<p v-else class="difficultyLevelTag"
						:style="'background-color: ' + colorDiff[data.item.difficulty_level]">
						{{ data.item.difficulty_level.toUpperCase() }}
					</p>
				</template>
				<template #cell(created_at)="data">
					{{ data.item.created_at | formatEnglishDate }}
				</template>
				<template #cell(updated_at)="data">
					{{ data.item.updated_at | formatEnglishDate }}
				</template>
				<template #cell(options)="data">
					<router-link v-b-tooltip.hover title="Add questions" v-if="data.item.number_questions == 0"
						:to="{ path: '/test-add-questions?id=' + data.item.id }"
						class="btn btn-secondary"><font-awesome-icon icon="plus" /></router-link>
					<router-link v-b-tooltip.hover title="Preview" v-else
						:to="{ path: '/test-preview-questions?id=' + data.item.id }"
						class="btn btn-secondary"><font-awesome-icon icon="eye" /></router-link>
					<router-link v-b-tooltip.hover title="Edit" :to="{ path: '/update-test?id=' + data.item.id }"
						class="btn btn-secondary"><font-awesome-icon icon="edit" /></router-link>
					<router-link v-b-tooltip.hover title="Summary" :to="{ path: '/results?id=' + data.item.id }"
						class="btn btn-secondary"><font-awesome-icon icon="list" /></router-link>
				</template>
			</b-table>
		</template>
		<template v-else>
			<h3 class="no-results-msg"><font-awesome-icon icon="times-circle" class="black-color" /> No results found.
			</h3>
		</template>

		<b-modal id="delete-modal" ref="delete-modal" title="Delete-modal" hide-footer>
			<template #modal-title>
				Delete test
			</template>
			<div>
				<p v-if="selectedItems.length > 1"> Do you really want to delete {{ selectedItems.length }} tests? </p>
				<p v-else> Do you really want to delete this test? </p>
			</div>
			<input class="btn btn-primary form-submit" style="margin-right: 1%;" type="button"
				@click="$bvModal.hide('delete-modal')" value="No">
			<input class="btn btn-primary form-submit" type="button" @click="deleteTest()" value="Yes">
		</b-modal>
	</div>
</template>

<script>
import TestDataService from "@/services/TestDataService";

export default {
	name: "TestListComponent",
	data() {
		return {
			tests: [],
			successfull: false,
			message: "",
			perPage: 10,
			pageOptions: this.$paginationOptions,
			currentPage: 1,
			filter: null,
			difficulties: [],
			colorDiff: {},
			testsId: [],
			difficultyModel: "All",
			selectedItems: [],
			selectedTest: null,
			selectedTestIndex: null,
			levels: ["All", "Low", "Medium", "High", "Very High"]
		}
	},
	mounted() {
		this.getTestBoard();
		this.getTests();
	},
	computed: {
		rows() {
			return this.tests.length
		},
	},
	props: {
		home: Boolean,
		headers: Array
	},
	methods: {
		getTestBoard() {
			const currentArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
			TestDataService.getTestBoard(currentArea).then(
				response => {
					if (response.status == 200) {
						this.difficulties = response.data.difficulty_levels
					}
				},
				error => {
					console.log((error.response && error.response.data) ||
						error.message ||
						error.toString())

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		getTests() {
			const currentArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
			TestDataService.findByArea(currentArea).then(
				response => {
					if (response.status == 200) {
						let dataTmp = response.data;

						dataTmp.forEach((d) => {
							this.testsId.push(d.id)
						})

						TestDataService.findQuestionsForTestsId(this.testsId).then(
							response2 => {
								if (response2.status == 200) {
									let tests = response2.data;

									for (var i = 0; i < tests.length; i++) {
										var questions = tests[i].questions
										var averageDifficulty = 0
										var weightSum = 0

										questions.forEach((question) => {
											var questionWeight = 0;
											for (var k = 0; k < this.difficulties.length; k++) {
												if (this.difficulties[k].id == question.id_difficulty_level) {
													questionWeight = this.difficulties[k].level
													break;
												}
											}

											weightSum = weightSum + questionWeight;
										})

										if (questions.length > 0)
											averageDifficulty = weightSum / questions.length;
										else
											averageDifficulty = 0;

										for (var k = 0; k < dataTmp.length; k++) {
											if (dataTmp[k].id == tests[i].test.id) {
												dataTmp[k].averageDifficulty = averageDifficulty.toFixed("2")
												break;
											}
										}

									}

									this.tests = dataTmp

									this.difficulties.forEach((difficulty) => {
										this.colorDiff[difficulty.name] = difficulty.color
									})
								}
							},
							error => {
								console.error((error.response && error.response.data) ||
									error.message ||
									error.toString())

								if (error.response.status === 300) {
									console.log('Logout: Token expired')
									localStorage.removeItem('user');
									localStorage.removeItem('company');
									this.$store.state.auth.user = null
									this.$store.dispatch('auth/logout');
									this.$router.push('/login');
								}
							}
						);
					}

				},
				error => {
					console.error((error.response && error.response.data) ||
						error.message ||
						error.toString())

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		onRowSelected(items) {
			this.selectedItems = items;
		},
		selectAllRows() {
			this.$refs["testsTable"].selectAllRows();
		},
		clearSelected() {
			this.$refs["testsTable"].clearSelected();
		},
		showDeleteModalMultiple() {
			this.$refs['delete-modal'].show();
		},
		deleteTest() {
			let selectedRows = this.selectedItems.map(element => element.id)

			TestDataService.delete(selectedRows).then(
				response => {
					this.message = response.data.message
					if (response.status == 200) {
						this.successfull = true

						this.tests = this.tests.filter(function (value, index) {
							return selectedRows.indexOf(value.id) == -1;
						});
					} else {
						this.successfull = false
					}

					this.$refs['delete-modal'].hide();
				},
				error => {
					console.log((error.response && error.response.data) ||
						error.message ||
						error.toString())

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		filterTests() {
			if (this.difficultyModel == "All") {
				return this.tests;
			} else {
				return this.tests.filter(field => field.difficulty_level == this.difficultyModel);
			}
		},
		close() {
			this.message = "";
		}
	}
};
</script>
