<template>
	<div class="col-sm-12">
		<div class="row secondNavbar">
			<div class="col-7 links-container">
				<router-link :to="{ path: '/evaluation' }" class="btn btn-secondary float-right"><font-awesome-icon
						icon="arrow-left" /> Back to Evaluation modules</router-link>
				<router-link :to="{ path: '/create-videoconference' }"
					class="btn btn-secondary float-right createClass"><font-awesome-icon icon="plus" />CREATE A RECORDED
					VIDEO INTERVIEW</router-link>
				<router-link v-if="currentUser.role.name === 'admin' || currentUser.role.name === 'superadmin'"
					:to="{ path: '/config-videoconferences' }"
					class="btn btn-secondary float-right createClass"><font-awesome-icon icon="cog" /> CONFIGURE
					RECORDED VIDEO INTERVIEWS</router-link>
			</div>
			<div class="col-5 noMargin whiteColor">
				<router-link :to="{ path: '/modules' }" class="breadcumb"> Suite Tools > </router-link>
				<router-link :to="{ path: '/videoconferences' }" class="final-breadcumb"> Recorded Video Interviews
				</router-link>
			</div>
		</div>
		<div class="row col-centered">
			<div class="col-sm-6">
				<coming-meetings-list v-on:onFinishInterview="reloadFinishedInterviews" />
			</div>
			<div class="col-sm-6">
				<finished-meetings-list :key="reloadFinished" />
			</div>
		</div>
	</div>
</template>

<script>
import ComingMeetingsList from "@/components/VideoconferencesComponents/ComingMeetingsList";
import FinishedMeetingsList from "@/components/VideoconferencesComponents/FinishedMeetingsList";

export default {
	name: 'VideoconferencesView',
	data() {
		return {
			reloadFinished: false
		}
	},
	components: {
		ComingMeetingsList,
		FinishedMeetingsList
	},
	computed: {
		currentUser() {
			return this.$store.state.auth.user;
		}
	},
	methods: {
		reloadFinishedInterviews() {
			console.log("Emitting finish interview")
			this.reloadFinished = this.reloadFinished ? false : true;
			this.$forceUpdate();
		},
		close() {
			document.getElementById("message").style.display = "none"
		}
	}
};
</script>

<style scoped>
#app>.bg-white .main-container {
	max-width: 100%;
	float: none;
	padding-left: 0px !important;
	padding-right: 0px !important;
	padding-top: 0px !important;
}

/* .createClass{
    background-color: #FFFF !important;
    background: border-box;
    border-radius: 26px;
    color: black !important;
    padding: inherit;
    margin-top: 0.6%;
	margin-right: 0.6%;
	padding-right: 7px;
    padding-left: 7px;
} */
</style>