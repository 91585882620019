<template>
  <div class="col-sm-12">
    <div class="secondNavbar"> 
      <div  class="links-container">
        <router-link :to="{path: '/scoreboards'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to results menu</router-link>
        <reports-downloader v-if="selectedUser != null" :graphContent="graphContent" :documentTitle="documentTitle" :testListTitle="testListTitle"/>
      </div>
      <div class="whiteColor">Users reports</div>
    </div>

    <div id="message" v-if="message" class="col-md-12 align-item-center alert" :class="successfull ? 'alert-success' : 'alert-danger'">
      {{message}} <b-button @click="close" class="cross-button"><font-awesome-icon  class="cross" icon="times" /> </b-button>
    </div>

    <div class="row user-title" >
      <h5 class="col-1">Select an user: </h5>
      <b-form-select style="width: 15%;" v-model="selectedUser" :options="usersList">
        <b-form-select-option :value="null">User</b-form-select-option>
      </b-form-select>
    </div>

    <div v-if="selectedUser != null">
      <h3 class="user-title">User "{{selectedUser}}"</h3>
      <user-exams-graph-by-marks :user="selectedUserObject" ref="chart1"/>
      <user-answers-graph-by-question :user="selectedUserObject" ref="chart2"/>
      <user-percentage-correct-graph-by-question :user="selectedUserObject" ref="chart3"/>
      <user-bubble-graph-by-question :user="selectedUserObject" ref="chart4"/>
    </div>
  </div>
</template>

<script>
  import UsersDataService from '@/services/UserDataService';
  import UserExamsGraphByMarks from "@/components/ReportsComponents/Results/Users/UserExamsGraphByMarks.vue";
  import UserAnswersGraphByQuestion from "@/components/ReportsComponents/Results/Users/UserAnswersGraphByQuestion.vue";
  import UserPercentageCorrectGraphByQuestion from "@/components/ReportsComponents/Results/Users/UserPercentageCorrectGraphByQuestion.vue";
  import UserBubbleGraphByQuestion from "@/components/ReportsComponents/Results/Users/UserBubbleGraphByQuestion.vue";

  export default {
    name: 'UserResultsView',
    data() {
      return {
        selectedUser: null,
        selectedUserObject: {},
        users: [],
        usersList: [],
        selectedUser: null,
        message: "",
        documentTitle: "user_results_reports.pdf",
        graphContent: [
          {title: "Exams diagram by Marks"},
          {title: "Column diagram with answers distribution"}
        ],
        testListTitle: {
          "chart2": "Column diagram with answers distribution of question ",
          "chart3": "Column diagram with correct/wrong answers distribution of question "
        }
      }
    },
    components: {
      UserExamsGraphByMarks,
      UserAnswersGraphByQuestion,
      UserPercentageCorrectGraphByQuestion,
      UserBubbleGraphByQuestion
    },
    watch: {
      selectedUser: function () {
        for (let i = 0; i < this.users.length; i++) {
          if (this.selectedUser === this.users[i].username) {
            this.selectedUserObject = this.users[i]
          }
        }
      }
    },
    mounted() {
      UsersDataService.getAllUsersFromCompany(this.$store.state.auth.user.id_company).then(
        response => {
          if (response.status == 200) {
            for (let i = 0; i < response.data.length; i++) {
              this.users.push(response.data[i])
              this.usersList.push(response.data[i].username)
            }
          } else {
            console.log('Error: ', response)
            this.message = 'An error ocurred while retrieving users'
          }
        },
        error => {
          console.log((error.response && error.response.data) ||
          error.message ||
          error.toString())

          if (error.response.status === 300) {
            console.log('Logout: Token expired')
            localStorage.removeItem('user');
            localStorage.removeItem('company');
            this.$store.state.auth.user = null
            this.$store.dispatch('auth/logout');
            this.$router.push('/login');
          }
        }
      );
    }
  };
</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}

.user-title {
    margin-top: 2%;
    margin-left: 1%;
}
</style>

