<template>
    <div class="col-md-12 technologiesStyle my-2" style="padding-top: 5% !important;">
        <template v-if="!isDisabled">
            <template v-if="evaluations.length > 0">
                <div class="row">
                    <div class="col-sm-3">
                        <h5>Select a BEI/360 Evaluation:</h5>
                    </div>
                    <div class="col-sm-9">
                        <b-form-select style="float: left !important;" v-model="evaluation"
                            :options="evaluations"></b-form-select>
                    </div>
                </div>
                <div class="row" v-if="evaluation !== null" style="margin-top: 1% !important;">
                    <div class="col-sm-12">
                        <h5>Information of the predefined Skills Map&nbsp;&nbsp;
                            <a @click="skillsMapVisible = false" class="notunderlined" v-if="skillsMapVisible == true">
                                <font-awesome-icon icon="eye-slash" />
                            </a>
                            <a @click="skillsMapVisible = true" class="notunderlined" v-if="skillsMapVisible == false">
                                <font-awesome-icon icon="eye" />
                            </a>
                        </h5>
                    </div>
                    <div class="row" v-if="skillsMapVisible">
                        <div class="container-fluid">
                            <div class="row" v-for="(group, index) in competencesToEvaluate" :key="group.id">
                                <div class="col-12 padding-0">
                                    <div class="row">
                                        <div class="col-1">
                                            <p class="title-groups margin-top-10">Group</p>
                                        </div>
                                        <div class="col-10">
                                            <div class="row">
                                                <div class="col-9">
                                                    <p class="float-left margin-top-1-5">{{ group.name }}</p>
                                                </div>
                                                <div class="col-3">
                                                    <p class="p-weight-evaluation-group-results">Weight:
                                                        &nbsp;&nbsp;&nbsp;{{ group.weight }}%</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-1">
                                            <button class="btn btn-secondary margin-top-10" type="button"
                                                v-b-toggle="'toggleG-' + index.toString()"
                                                @click="changeShowGroupState(index)">
                                                <font-awesome-icon style="display: none;"
                                                    v-bind:id="'iconShowG-' + index.toString()" class="trash-icon"
                                                    v-b-tooltip.hover title="Expand" icon="chevron-down" />
                                                <font-awesome-icon style="display: block;"
                                                    v-bind:id="'iconNotShowG-' + index.toString()" class="trash-icon"
                                                    v-b-tooltip.hover title="Contract" icon="chevron-up" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <b-collapse visible v-bind:id="'toggleG-' + index.toString()">
                                    <div class="row">
                                        <div class="col-10 col-centered">
                                            <div class="row"
                                                v-for="(competence, index_competence) in group.selectedCompetences"
                                                :key="group.id + '-' + competence.id">
                                                <div class="col-12 padding-0">
                                                    <div class="row">
                                                        <div class="col-2">
                                                            <p class="title-groups margin-top-5">Competence</p>
                                                        </div>
                                                        <div class="col-9">
                                                            <div class="row">
                                                                <div class="col-9">
                                                                    <p class="float-left margin-top-1-8">{{
            competence.name
        }}
                                                                    </p>
                                                                </div>
                                                                <div class="col-3">
                                                                    <p class="p-weight-evaluation-competence-results">
                                                                        Weight:
                                                                        &nbsp;&nbsp;&nbsp;{{ competence.weight }}%</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-1">
                                                            <button class="btn btn-secondary margin-top-15"
                                                                type="button"
                                                                v-b-toggle="'toggleC-' + index.toString() + '-' + index_competence.toString()"
                                                                @click="changeShowCompetenceFromGroupState(index, index_competence)">
                                                                <font-awesome-icon style="display: none;"
                                                                    v-bind:id="'iconShowC-' + index.toString() + '-' + index_competence.toString()"
                                                                    class="trash-icon" v-b-tooltip.hover title="Expand"
                                                                    icon="chevron-down" />
                                                                <font-awesome-icon style="display: block;"
                                                                    v-bind:id="'iconNotShowC-' + index.toString() + '-' + index_competence.toString()"
                                                                    class="trash-icon" v-b-tooltip.hover
                                                                    title="Contract" icon="chevron-up" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <b-collapse visible
                                                    v-bind:id="'toggleC-' + index.toString() + '-' + index_competence.toString()">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="row align-items-center">
                                                                <p class="title-min-level">Levels availables</p>
                                                            </div>
                                                            <div class="row">
                                                                <b-form-group
                                                                    :name="'competence-' + index.toString() + '-' + index_competence.toString()"
                                                                    class="form-group-margin">
                                                                    <!-- <b-list-group>
                                                                        <b-list-group-item
                                                                            v-bind:class="competence.min_level - 1 == index_level ? 'radio-button-class active' : 'radio-button-class'"
                                                                            v-for="(level, index_level) in competence.levels"
                                                                            :key="level.id">
                                                                            <p v-if="index_level != 0"
                                                                                style="text-align: left !important;">
                                                                                Level
                                                                                {{ index_level }}:&nbsp;&nbsp;&nbsp;{{ level.name }}
                                                                            </p>
                                                                        </b-list-group-item>
                                                                    </b-list-group> -->

                                                                    <b-list-group>
                                                                        <b-list-group-item v-for="(level, index_level) in competence.levels" :key="level.id" v-bind:class="competence.min_level - 1 == index_level + 1 ? 'radio-button-class active' : 'radio-button-class'">
                                                                            <p
                                                                                style="text-align: left !important;">Level
                                                                                {{ index_level + 1 }}:&nbsp;&nbsp;&nbsp;{{ level.name }}
                                                                            </p>
                                                                        </b-list-group-item>
                                                                    </b-list-group>
                                                                </b-form-group>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </b-collapse>
                                            </div>
                                        </div>
                                    </div>
                                </b-collapse>
                                <hr>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="evaluation !== null" style="margin-top: 1% !important;">
                    <div class="col-sm-12">
                        <h5>Select evaluators</h5>
                    </div>
                    <div class="row mb-2">
                        <div class="col-2">
                            <p class="p-text">External Evaluators: </p>
                        </div>
                        <div class="col-5">
                            <multiselect id="hrtechnician_selector" :options="hrtechniciansToShow" :multiple="true"
                                :close-on-select="false" :preserve-search="true" :taggable="true"
                                :preselect-first="false" placeholder="Search or add an External Evaluator"
                                v-model="selectedHRTechnicians" label="label" track-by="value"
                                @select="selectOptionHRTechnicians" @remove="removeOptionHRTechnicians"></multiselect>
                        </div>
                        <div class="col-5" v-if="hrtechniciansToAdd.length !== 0">
                            <div class="row">
                                <div class="col-3 padding-10">
                                    <p class="p-weight-evaluators">Weight</p>
                                </div>
                                <div class="col-3 padding-0">
                                    <b-input class="input-evaluator-weight" type="number" min="0" max="100"
                                        v-model="hrtechnicianWeight"></b-input>
                                </div>
                                <div class="col-1 padding-0">
                                    <p class="p-percentage-evaluators">%</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-2">
                            <p class="p-text">Responsables: </p>
                        </div>
                        <div class="col-5">
                            <multiselect id="responsable_selector" :options="responsablesToShow" :multiple="true"
                                :close-on-select="false" :preserve-search="true" :taggable="true"
                                :preselect-first="false" placeholder="Search or add a responsable"
                                v-model="selectedResponsables" label="label" track-by="value"
                                @select="selectOptionResponsables" @remove="removeOptionResponsables">
                            </multiselect>
                        </div>
                        <div class="col-5" v-if="responsablesToAdd.length !== 0">
                            <div class="row">
                                <div class="col-3 padding-10">
                                    <p class="p-weight-evaluators">Weight</p>
                                </div>
                                <div class="col-3 padding-0">
                                    <b-input class="input-evaluator-weight" type="number" min="0" max="100"
                                        v-model="responsablesWeight"></b-input>
                                </div>
                                <div class="col-1 padding-0">
                                    <p class="p-percentage-evaluators">%</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-2">
                            <p class="p-text">Pairs: </p>
                        </div>
                        <div class="col-5">
                            <multiselect id="pair_selector" :options="pairsToShow" :multiple="true"
                                :close-on-select="false" :preserve-search="true" :taggable="true"
                                :preselect-first="false" placeholder="Search or add a pair" v-model="selectedPairs"
                                label="label" track-by="value" @select="selectOptionPairs" @remove="removeOptionPairs">
                            </multiselect>
                        </div>
                        <div class="col-5" v-if="pairsToAdd.length !== 0">
                            <div class="row">
                                <div class="col-3 padding-10">
                                    <p class="p-weight-evaluators">Weight</p>
                                </div>
                                <div class="col-3 padding-0">
                                    <b-input class="input-evaluator-weight" type="number" min="0" max="100"
                                        v-model="pairsWeight"></b-input>
                                </div>
                                <div class="col-1 padding-0">
                                    <p class="p-percentage-evaluators">%</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-2 mb-3">
                            <p class="p-text">Colaborators: </p>
                        </div>
                        <div class="col-5 mb-3">
                            <multiselect id="colaborator_selector" :options="colaboratorsToShow" :multiple="true"
                                :close-on-select="false" :preserve-search="true" :taggable="true"
                                :preselect-first="false" placeholder="Search or add a colaborator"
                                v-model="selectedColaborators" label="label" track-by="value"
                                @select="selectOptionColaborators" @remove="removeOptionColaborators">
                            </multiselect>
                        </div>
                        <div class="col-5 mb-3" v-if="colaboratorsToAdd.length !== 0">
                            <div class="row">
                                <div class="col-3 padding-10">
                                    <p class="p-weight-evaluators">Weight</p>
                                </div>
                                <div class="col-3 padding-0">
                                    <b-input class="input-evaluator-weight" type="number" min="0" max="100"
                                        v-model="colaboratorsWeight"></b-input>
                                </div>
                                <div class="col-1 padding-0">
                                    <p class="p-percentage-evaluators">%</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="message" v-if="message != ''" class="alert"
                    :class="successfull ? 'alert-success' : 'alert-danger'">
                    {{ message }} <b-button @click="close" class="cross-button"><font-awesome-icon class="cross"
                            icon="times" />
                    </b-button>
                </div>
            </template>
            <template v-else>
                <h3 class="no-results-msg"><font-awesome-icon icon="times-circle" class="black-color" /> No results
                    found.
                </h3>
            </template>
        </template>
        <template v-else>
            <div class="row">
                <div class="col-sm-12">
                    <h5 style="text-align: center !important;">Module disabled.</h5>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import AuthDataService from "@/services/AuthDataService";
import UserDataService from '@/services/UserDataService';
import SoftskillsDataService from '@/services/SoftskillsDataService';
import ProfileAnalysisDataService from '@/services/ProfileAnalysisDataService';
import Multiselect from 'vue-multiselect'

export default {
    name: 'AddBEI360',
    data() {
        return {
            evaluations: [],
            evaluation: null,
            evaluationSelected: null,
            competencesToEvaluate: [],
            successfull: false,
            message: "",
            perPage: 10,
            pageOptions: this.$paginationOptions,
            currentPage: 1,
            filter: null,
            submitted: false,
            roles: [],
            role: 0,
            skillsMap: [],
            competencesWithoutGroups: [],
            competencesByGroups: [],
            allUsersInCompany: [],
            allUsersInArea: [],
            cvsUploadedByAdmins: [],
            users: [],
            hrtechnicians: [],
            responsables: [],
            pairs: [],
            colaborators: [],
            hrtechniciansToShow: [],
            responsablesToShow: [],
            pairsToShow: [],
            colaboratorsToShow: [],
            hrtechniciansToAdd: [],
            responsablesToAdd: [],
            pairsToAdd: [],
            colaboratorsToAdd: [],
            user: 0,
            userObject: {},
            selectedHRTechnicians: [],
            selectedResponsables: [],
            selectedPairs: [],
            selectedColaborators: [],
            hrtechnicianWeight: 0,
            responsablesWeight: 0,
            pairsWeight: 0,
            colaboratorsWeight: 0,
            groupsWeights: false,
            competencesWeights: false,
            byGroups: false,
            selectedItems: [],
            isDisabled: false,
            skillsMapVisible: false
        }
    },
    watch: {
        evaluation() {
            console.log('evaluation watch: ', this.evaluation)
            this.getCompetencesToEvaluate(this.evaluation)
        },
        hrtechnicianWeight() {
            let weight = parseFloat(0)
            if (this.selectedHRTechnicians.length > 0) weight = weight + parseFloat(this.hrtechnicianWeight)
            if (this.selectedColaborators.length > 0) weight = weight + parseFloat(this.colaboratorsWeight)
            if (this.selectedPairs.length > 0) weight = weight + parseFloat(this.pairsWeight)
            if (this.selectedResponsables.length > 0) weight = weight + parseFloat(this.responsablesWeight)

            if (weight !== 100) this.$emit('can-continue', { value: false });
            else this.$emit('can-continue', { value: true });
        },
        pairsWeight() {
            let weight = 0
            if (this.selectedHRTechnicians.length > 0) weight = weight + parseFloat(this.hrtechnicianWeight)
            if (this.selectedColaborators.length > 0) weight = weight + parseFloat(this.colaboratorsWeight)
            if (this.selectedPairs.length > 0) weight = weight + parseFloat(this.pairsWeight)
            if (this.selectedResponsables.length > 0) weight = weight + parseFloat(this.responsablesWeight)

            if (weight !== 100) this.$emit('can-continue', { value: false });
            else this.$emit('can-continue', { value: true });
        },
        colaboratorsWeight() {
            let weight = 0
            if (this.selectedHRTechnicians.length > 0) weight = weight + parseFloat(this.hrtechnicianWeight)
            if (this.selectedColaborators.length > 0) weight = weight + parseFloat(this.colaboratorsWeight)
            if (this.selectedPairs.length > 0) weight = weight + parseFloat(this.pairsWeight)
            if (this.selectedResponsables.length > 0) weight = weight + parseFloat(this.responsablesWeight)

            if (weight !== 100) this.$emit('can-continue', { value: false });
            else this.$emit('can-continue', { value: true });
        },
        responsablesWeight() {
            let weight = 0
            if (this.selectedHRTechnicians.length > 0) weight = weight + parseFloat(this.hrtechnicianWeight)
            if (this.selectedColaborators.length > 0) weight = weight + parseFloat(this.colaboratorsWeight)
            if (this.selectedPairs.length > 0) weight = weight + parseFloat(this.pairsWeight)
            if (this.selectedResponsables.length > 0) weight = weight + parseFloat(this.responsablesWeight)

            if (weight !== 100) this.$emit('can-continue', { value: false });
            else this.$emit('can-continue', { value: true });
        }
    },
    components: {
        Multiselect
    },
    computed: {
    },
    mounted() {
        this.getEvaluations()
        this.getAllUsersInArea()
        this.isDisabled = localStorage.getItem('disabledBEI')
        if (this.isDisabled) this.$emit('can-continue', { value: true });
        else this.$emit('can-continue', { value: false });

        let weight = 0
        if (this.selectedHRTechnicians.length > 0) weight = weight + parseFloat(this.hrtechnicianWeight)
        if (this.selectedColaborators.length > 0) weight = weight + parseFloat(this.colaboratorsWeight)
        if (this.selectedPairs.length > 0) weight = weight + parseFloat(this.pairsWeight)
        if (this.selectedResponsables.length > 0) weight = weight + parseFloat(this.responsablesWeight)

        if (weight !== 100) this.$emit('can-continue', { value: false });
        else this.$emit('can-continue', { value: true });
        console.log('despues: ', weight)
    },
    methods: {
        getAllUsersInArea() {
            UserDataService.findByArea(this.$store.state.auth.user.active_areas[this.$session.get("tabID")]).then(
                response => {
                    if (200 == response.status) {
                        this.allUsersInArea = response.data;
                        console.log('response.data: ', response.data)
                        this.allUsersInArea = this.allUsersInArea.filter(field => field.role.id != undefined)
                        this.fillEvaluators()
                    } else {
                        this.message = "Error while retrieving users from company";
                        this.successfull = false;
                    }
                },
                error => {
                    console.error((error.response && error.response.data) ||
                        error.message ||
                        error.toString())

                    this.message = "There was an error while retrieving users from company";
                    this.successfull = false;

                    if (error.response.status === 300) {
                        console.error('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );
        },
        getEvaluations() {
            const currentArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
            SoftskillsDataService.findEvaluationsByArea(currentArea).then(
                response => {
                    if (response.status == 200) {
                        response.data.forEach(evaluation => {
                            if (evaluation.total_users == 0 && evaluation.total_evaluators == 0) this.evaluations.push({ value: evaluation.id, text: evaluation.name })
                        })

                        console.log('this.evaluations: ', this.evaluations)
                    }
                },
                error => {
                    console.error((error.response && error.response.data) ||
                        error.message ||
                        error.toString())

                    if (error.response.status === 300) {
                        console.log('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );
        },
        getCompetencesToEvaluate(id) {
            SoftskillsDataService.findEvaluationSummary(id).then(
                response => {
                    this.evaluationSelected = response.data;

                    SoftskillsDataService.findCompanyCompetencesDirectory(this.$store.state.auth.user.id_company).then(
                        response => {
                            this.skillsMap = response.data

                            if (this.skillsMap.length > 0) {
                                this.competencesByGroups = this.skillsMap

                                if (this.skillsMap[0].type === "group") {
                                    this.evaluationSelected.weights.forEach(weight => {
                                        this.competencesByGroups.forEach(group => {
                                            if (group.id === weight.id_soft_skill) {
                                                this.competencesToEvaluate.push(group)
                                            }
                                        })
                                    })

                                    this.competencesToEvaluate.forEach(group => {
                                        group.selectedCompetences = []
                                    })

                                    this.evaluationSelected.weights.forEach(weight => {
                                        this.competencesToEvaluate.forEach(group => {
                                            if (group.id === weight.id_soft_skill) {
                                                group.weight = weight.weight
                                            }

                                            group.competences.forEach(competence => {
                                                if (competence.id === weight.id_soft_skill) {
                                                    competence.weight = weight.weight
                                                    competence.min_level = weight.min_level
                                                    competence.levels.shift()
                                                    group.selectedCompetences.push(competence)
                                                }
                                            })
                                        })
                                    })
                                }
                            }

                            console.log('competences: ', this.competencesToEvaluate)
                        },
                        error => {
                            console.log((error.response && error.response.data) ||
                                error.message ||
                                error.toString())

                            if (error.response.status === 300) {
                                console.log('Logout: Token expired')
                                localStorage.removeItem('user');
                                localStorage.removeItem('company');
                                this.$store.state.auth.user = null
                                this.$store.dispatch('auth/logout');
                                this.$router.push('/login');
                            }
                        }
                    );
                },
                error => {
                    console.log((error.response && error.response.data) ||
                        error.message ||
                        error.toString())

                    if (error.response.status === 300) {
                        console.log('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );
        },
        fillEvaluators() {
            this.hrtechnicians = []
            this.hrtechniciansToShow = []
            this.responsables = []
            this.responsablesToShow = []
            this.pairs = []
            this.pairsToShow = []
            this.colaborators = []
            this.colaboratorsToShow = []
            this.userObject = {}

            let userLoggedIn = localStorage.getItem('user')

            this.allUsersInArea.forEach(user => {
                if (user.id === userLoggedIn) {
                    this.userObject = user
                }
            })

            console.log('userObject: ', this.userObject)

            const userSelected = userLoggedIn

            this.pairs = this.allUsersInArea

            this.pairs.forEach(user => {
                this.pairsToShow.push({ label: user.name + " " + user.surname + " (" + user.role.name.toUpperCase() + ")", value: user.name + "-" + user.id })
            })

            this.allUsersInArea.forEach(user => {
                if (user.id !== userSelected && Object.keys(this.userObject).length !== 0) {
                    if (user.role.id === 9) {
                        this.hrtechnicians.push(user) // Talent manager
                        this.hrtechniciansToShow.push({ label: user.name + " " + user.surname + " (" + user.role.name.toUpperCase() + ")", value: user.name + "-" + user.id })
                    } else if (user.role.id === 1 || user.role.id === 8) {
                        this.responsables.push(user) // Admin, manager
                        this.responsablesToShow.push({ label: user.name + " " + user.surname + " (" + user.role.name.toUpperCase() + ")", value: user.name + "-" + user.id })
                    }

                    if (user.role.id !== 3 && user.role.id !== 9) {
                        this.colaborators.push(user)
                        this.colaboratorsToShow.push({ label: user.name + " " + user.surname + " (" + user.role.name.toUpperCase() + ")", value: user.name + "-" + user.id })
                    }
                } else if (user.id !== userLoggedIn && Object.keys(this.userObject).length === 0) {
                    if (user.role.id === 9) {
                        this.hrtechnicians.push(user) // Talent manager
                        this.hrtechniciansToShow.push({ label: user.name + " " + user.surname + " (" + user.role.name.toUpperCase() + ")", value: user.name + "-" + user.id })
                    } else if (user.role.id === 1 || user.role.id === 8) {
                        this.responsables.push(user) // Admin, manager
                        this.responsablesToShow.push({ label: user.name + " " + user.surname + " (" + user.role.name.toUpperCase() + ")", value: user.name + "-" + user.id })
                    }

                    const rolesNotAllowed = [3, 9] //this.role]

                    if (!rolesNotAllowed.includes(user.role.id)) {
                        this.colaborators.push(user)
                        this.colaboratorsToShow.push({ label: user.name + " " + user.surname + " (" + user.role.name.toUpperCase() + ")", value: user.name + "-" + user.id })
                    }
                }
            })

            let selectedUsers = JSON.parse(localStorage.getItem('selectedUsers'))

            let hrtechniciansTmp = []
            let responsablesTmp = []
            let pairsTmp = []
            let colaboratorsTmp = []

            this.hrtechniciansToShow.forEach(item => {
                let idSplit = item.value.split('-')
                let id = idSplit[1]
                let exists = false

                selectedUsers.forEach(user => {
                    if (id == user.id) exists = true
                })

                if (!exists) hrtechniciansTmp.push(item)
            })

            this.responsablesToShow.forEach(item => {
                let idSplit = item.value.split('-')
                let id = idSplit[1]
                let exists = false

                selectedUsers.forEach(user => {
                    if (id == user.id) exists = true
                })

                if (!exists) responsablesTmp.push(item)
            })

            this.pairsToShow.forEach(item => {
                let idSplit = item.value.split('-')
                let id = idSplit[1]
                let exists = false

                selectedUsers.forEach(user => {
                    if (id == user.id) exists = true
                })

                if (!exists) pairsTmp.push(item)
            })

            this.colaboratorsToShow.forEach(item => {
                let idSplit = item.value.split('-')
                let id = idSplit[1]
                let exists = false

                selectedUsers.forEach(user => {
                    if (id == user.id) exists = true
                })

                if (!exists) colaboratorsTmp.push(item)
            })

            this.hrtechniciansToShow = hrtechniciansTmp
            this.responsablesToShow = responsablesTmp
            this.pairsToShow = pairsTmp
            this.colaboratorsToShow = colaboratorsTmp
        },
        changeShowGroupState(index) {
            if (document.getElementById('iconShowG-' + index.toString()).style.display == "block") {
                document.getElementById('iconShowG-' + index.toString()).style.display = "none"
                document.getElementById('iconNotShowG-' + index.toString()).style.display = "block"
            } else {
                document.getElementById('iconShowG-' + index.toString()).style.display = "block"
                document.getElementById('iconNotShowG-' + index.toString()).style.display = "none"
            }
        },
        changeShowCompetenceFromGroupState(index, index_competence) {
            if (document.getElementById('iconShowC-' + index.toString() + '-' + index_competence.toString()).style.display == "block") {
                document.getElementById('iconShowC-' + index.toString() + '-' + index_competence.toString()).style.display = "none"
                document.getElementById('iconNotShowC-' + index.toString() + '-' + index_competence.toString()).style.display = "block"
            } else {
                document.getElementById('iconShowC-' + index.toString() + '-' + index_competence.toString()).style.display = "block"
                document.getElementById('iconNotShowC-' + index.toString() + '-' + index_competence.toString()).style.display = "none"
            }
        },
        selectOptionHRTechnicians(option) {
            var object = {}

            var parsedOption = JSON.parse(JSON.stringify(option))

            object.label = parsedOption.label
            object.value = parsedOption.value
            var valueSplit = parsedOption.value.split('-')

            this.hrtechniciansToAdd.push(parseInt(valueSplit[1]))
        },
        removeOptionHRTechnicians(option) {
            var parsedOption = JSON.parse(JSON.stringify(option))
            var valueSplit = parsedOption.value.split('-')
            const index = this.hrtechniciansToAdd.indexOf(parseInt(valueSplit[1]));
            this.hrtechniciansToAdd.splice(index, 1)
        },
        selectOptionResponsables(option) {
            var object = {}
            var parsedOption = JSON.parse(JSON.stringify(option))
            object.label = parsedOption.label
            object.value = parsedOption.value
            var valueSplit = parsedOption.value.split('-')
            this.responsablesToAdd.push(parseInt(valueSplit[1]))
        },
        removeOptionResponsables(option) {
            var parsedOption = JSON.parse(JSON.stringify(option))
            var valueSplit = parsedOption.value.split('-')
            const index = this.responsablesToAdd.indexOf(parseInt(valueSplit[1]));
            this.responsablesToAdd.splice(index, 1)
        },
        selectOptionPairs(option) {
            var object = {}
            var parsedOption = JSON.parse(JSON.stringify(option))
            object.label = parsedOption.label
            object.value = parsedOption.value
            var valueSplit = parsedOption.value.split('-')
            this.pairsToAdd.push(parseInt(valueSplit[1]))
        },
        removeOptionPairs(option) {
            var parsedOption = JSON.parse(JSON.stringify(option))
            var valueSplit = parsedOption.value.split('-')
            const index = this.pairsToAdd.indexOf(parseInt(valueSplit[1]));
            this.pairsToAdd.splice(index, 1)
        },
        selectOptionColaborators(option) {
            var object = {}
            var parsedOption = JSON.parse(JSON.stringify(option))
            object.label = parsedOption.label
            object.value = parsedOption.value
            var valueSplit = parsedOption.value.split('-')
            this.colaboratorsToAdd.push(parseInt(valueSplit[1]))
        },
        removeOptionColaborators(option) {
            var parsedOption = JSON.parse(JSON.stringify(option))
            var valueSplit = parsedOption.value.split('-')
            const index = this.colaboratorsToAdd.indexOf(parseInt(valueSplit[1]));
            this.colaboratorsToAdd.splice(index, 1)
        },
    }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>