<template>
	<div class="col-sm-12">
		<div class="secondNavbar">
			<div class="links-container">
				<router-link :to="{ path: '/superadmin' }" class="btn btn-secondary float-right"><font-awesome-icon
						icon="arrow-left" /> Back to Home</router-link>
				<router-link :to="{ path: '/create-company' }"
					class="btn btn-secondary float-right createClass"><font-awesome-icon icon="plus" /> CREATE
					COMPANY</router-link>
			</div>

			<div class="whiteColor">Companies</div>
		</div>

		<div class="col-sm-12 col-centered mt-2">
			<div id="message" v-if="companyRegistered" class="alert alert-success align-item-center">
				Company succesfully registered! <b-button @click="close" class="cross-button"><font-awesome-icon
						class="cross" icon="times" /> </b-button>
			</div>
			<div id="message2" v-if="companyUpdated" class="alert alert-success align-item-center">
				Company succesfully updated! <b-button @click="close2" class="cross-button"><font-awesome-icon
						class="cross" icon="times" /> </b-button>
			</div>
			<div id="message3" v-if="companyCloned" class="alert alert-success align-item-center">
				Company succesfully cloned! <b-button @click="close3" class="cross-button"><font-awesome-icon
						class="cross" icon="times" /> </b-button>
			</div>
			<companies-list :headers="headers" />
		</div>
	</div>
</template>

<script>
import CompaniesList from "@/components/CompaniesComponents/List.vue";

export default {
	name: 'CompaniesView',
	components: {
		CompaniesList
	},
	props: {
		companyRegistered: {
			type: Boolean,
			default: false
		},
		companyUpdated: {
			type: Boolean,
			default: false
		},
		companyCloned: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		headers() {
			return [
				{ key: 'id', label: 'Id', sortable: true },
				{ key: 'name', label: 'Name', sortable: true },
				{ key: 'created_at', label: 'Created at' },
				{ key: 'updated_at', label: 'Updated at' },
				{ key: 'options', label: 'Options', tdClass: 'tdMedium' }
			];
		}
	},
	methods: {
		close() {
			document.getElementById("message").style.display = "none"
		},
		close2() {
			document.getElementById("message2").style.display = "none"
		},
		close3() {
			document.getElementById("message3").style.display = "none"
		}
	}
};
</script>

<style scoped>
#app>.bg-white .main-container {
	max-width: 100%;
	float: none;
	padding-left: 0px !important;
	padding-right: 0px !important;
	padding-top: 0px !important;
}

/* .createClass{
    background-color: #FFFF !important;
    background: border-box;
    border-radius: 26px;
    color: black !important;
    padding: inherit;
    margin-top: 0.2%;
	padding-right: 7px;
    padding-left: 7px;
} */

.cloneClass {
	background-color: #FFFF !important;
	background: border-box;
	border-radius: 26px;
	color: black !important;
	padding: inherit;
	margin-top: 0.2%;
	padding-right: 7px;
	padding-left: 7px;
	margin-right: 0.7%;
}

.nav-link-user-submenu {
	color: #13283B !important;
}
</style>
