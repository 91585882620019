<template>
	<div :id="chartId" ref="chartdiv"></div>
</template>

<script>
	import * as am4core from "@amcharts/amcharts4/core";
	import * as am4charts from "@amcharts/amcharts4/charts";
	import am4themes_animated from "@amcharts/amcharts4/themes/animated";

	export default {
	    name: 'PieChart',
	    data() {
	    	return {
	    		chart: null,
	    	}
   		},
	    props: {
	    	chartId: String,
			chartTitle: String,
	    	data: Array,
	    	category: String,
	    	value: String,
	    	tooltipNoDataMsg : {
	    		type: String,
	    		default: "No data available"
	    	}
	    },
		mounted() {
			let data = []
	    	if (this.data != undefined)
	    		data = JSON.parse(JSON.stringify(this.data))

			this.chart = am4core.create(this.chartId, am4charts.PieChart)
			if (0 == data.length) {
				let label = this.chart.createChild(am4core.Label);
				label.text = this.tooltipNoDataMsg;
				label.fontSize = 20;
				label.align = "center";
				label.isMeasured = false;
				label.x = am4core.percent(50);
				label.y = 100;
				label.horizontalCenter = "middle";
			} else {
				this.chart.data = this.data;

				this.chart.exporting.menu = new am4core.ExportMenu();
				this.chart.exporting.menu.items = [
					{
						"label": "...",
						"menu": [
						{
							"label": "Image",
							"menu": [
							{ "type": "png", "label": "PNG" },
							{ "type": "jpg", "label": "JPG" },
							{ "type": "svg", "label": "SVG" }
							]
						}, {
							"label": "Data",
							"menu": [
							{ "type": "json", "label": "JSON" },
							{ "type": "csv", "label": "CSV" },
							{ "type": "xlsx", "label": "XLSX" },
							{ "type": "html", "label": "HTML" }
							]
						}, {
							"label": "Print", "type": "print"
						}
						]
					}
				]
				this.chart.exporting.filePrefix = this.chartTitle
				this.chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
				
				this.chart.legend = new am4charts.Legend();

				if (this.chartId.includes('chartTestResultsByCompanyDifficultyLevel') || 
					this.chartId.includes('chartUsersGraphCVByLevels') || 
					this.chartId.includes('chartRegisteredCertificationsByAreasProfileLevels')) {

					var colorSet = new am4core.ColorSet();
					let colors = this.data.map(function(e){return e.color});

					colorSet.list = colors.map(function(color) {
						return new am4core.color(color);
					});

					var series = this.chart.series.push(new am4charts.PieSeries())
					series.dataFields.value = this.value
					series.dataFields.category = this.category
					series.colors = colorSet;
				} else {
					let secondColors = [{color: "#13283B"},{ color: "#7AC943"}]
					var colorSet = new am4core.ColorSet();
					let colors = secondColors.map(function(e){return e.color});

					colorSet.list = colors.map(function(color) {
						return new am4core.color(color);
					});
					
					var series = this.chart.series.push(new am4charts.PieSeries())
					series.dataFields.value = this.value
					series.dataFields.category = this.category
					series.colors = colorSet;
				}
			}
		}
	};
</script>