<template>
	<div class="col-sm-12">
		<b-progress class="full-progress-bar-analysis" :max="100">
			<template v-for="(level, index) in levelsData">
				<template v-if="index + 1 < levels">
					<b-progress-bar :value="levelsData[index + 1].min_range - level.min_range" :label="noLabel" :style="level.styleProperties" show-progress animated :key="level.id"></b-progress-bar>
				</template>
				<template v-if="index + 1 === levels">
					<b-progress-bar :value="progress - level.min_range" :label="progress + ' %'" :style="level.styleProperties" show-progress animated :key="level.id"></b-progress-bar>
				</template>
			</template>
		</b-progress>
	</div>
</template>

<script>
	import Progress from "@/components/Others/ProgressCircle";
	export default {
		name: "ProgressTechHeader",
		data() {
			return {
				styleProperties: null,
				noLabel: ' ',
				levels: 0
			}
		},
		components: {
			Progress,
		},
		props: {
			progress: Number,
			levelsData: {
				type: Array,
				required: false,
				default: () => ([])
			}
		},
		mounted () {
			this.levelsData.forEach(level => {
				if (this.progress >= level.min_range) this.levels = this.levels + 1
				level.styleProperties = "text-align: right; padding-right: 7px; color: #000000 !important; background: " + level.color + " !important;"
			})
		}
	};
</script>

