<template>
    <div class="form-container m-3">
        <div id="message" v-if="message.length > 0" class="col-md-11 align-item-center alert" :class="successfull ? 'alert-success' : 'alert-danger'">
            {{message}} <b-button @click="close" class="cross-button"><font-awesome-icon  class="cross" icon="times" /> </b-button>
        </div>

        <div v-if="disableChanges" class="alert alert-info" role="alert" style="width: 98% !important;">
            <font-awesome-icon icon="info-circle" />&nbsp;&nbsp;Levels of Expertise cannot be updated because a profile has been previously configured. In addition, these levels apply to all profiles of the area.
        </div>

        <div class="row col-sm-12 pt-4 px-4">
            <b-table ref="levelsTable"
                id="levelsTable"
                :fields="visibleFields"
                :items="levels"
                responsive="sm"
                v-if="levels.length != 0"
            >
                <template #cell(name)="data">
                    <b-input class="alignTextInput" v-model="data.item.name" :disabled="disableChanges"></b-input>
                </template>
                <template #cell(level)="data" class="text-center">
                    <b-input type="number" class="alignTextInput" step="1" :oninput="data.item.level = Math.abs(data.item.level)" v-model="data.item.level" :disabled="disableChanges"/>
                </template>
                <template #cell(min_range)="data" class="text-center">
                    <b-input type="number" class="alignTextInput" step="1" max="100" min="0" :oninput="data.item.min_range = Math.abs(data.item.min_range)" v-model="data.item.min_range" :disabled="disableChanges"/>
                </template>
                <template #cell(color)="data">
                    <color-picker-selector v-if="data.item.color !== null" :color="data.item.color" v-model="data.item.color" :disableChanges="disableChanges"/>
                    <color-picker-selector v-else :color="data.item.color" v-model="data.item.color" :disableChanges="disableChanges"/>
                </template>
                <template v-if="!disableChanges" #cell(options)="data">
                    <button v-b-tooltip.hover  title="Delete level" class="btn btn-secondary" @click="showDeleteModal(data.item, data.index)" type="button"><font-awesome-icon icon="trash" /></button>
                </template>
            </b-table>
            <div v-else class="alert alert-info ml-4" role="alert">
                <font-awesome-icon icon="info-circle" />&nbsp;&nbsp;No levels defined yet
            </div>
        </div>
        
        <div v-show="!disableChanges" class="form-group row justify-content-center" style="height: 25vh !important;">
			<div class="col-xl-2 col-lg-3" v-if="levels.length > 0">
				<input class="btn btn-primary form-submit" type="button" @click="checkErrors()" value="Save changes" :disabled="disableChanges">
			</div>
            <div class="col-xl-2 col-lg-3" v-else>
				<input class="btn btn-primary form-submit" type="button" @click="checkErrors()" value="Save changes" disabled>
			</div>
			<div class="col-xl-2 col-lg-3">
				<button class="btn btn-secondary btn-cancel" type="button" @click="showCancelModal()"><font-awesome-icon icon="ban" /> Cancel</button>
			</div>
		</div>

        <b-modal id="delete-modal" ref="delete-modal" title="Delete-modal" hide-footer>
			<template #modal-title>
				Delete level
			</template>
			<div>
				<p>Do you really want to delete the selected level? </p>
			</div>
			<input class="btn btn-primary form-submit" type="button" @click="deleteLevel()" value="Yes">
			<input class="btn btn-primary form-submit" style="margin-left: 1%;" type="button" @click="$bvModal.hide('delete-modal')" value="No">
		</b-modal>
	</div>
</template>

<script>
    import ProfileConfigurationDataService from "@/services/ProfileConfigurationDataService";
    import ProfileAnalysisDataService from "@/services/ProfileAnalysisDataService";
    import ColorPickerSelector from '@/components/Others/ColorPicker';

    export default {
        name: 'CreateProfileConfigurationComponent',
        data() {
            return {
                levelsObjects: [],
                levels: [],
                numberCVs: 0,
                headers: [
                    { key: 'name', label: 'Name', visible: true },
                    { key: 'level', sortable: true, label: 'Level', visible: true },
                    { key: 'min_range', sortable: true, label: 'Minimum Range (%)', visible: true },
                    { key: 'color', label: 'Color', visible: true },
                    { key: 'options', label: 'Options', visible: true }
                ],
                selectedItem: null,
                selectedItemIndex: null,
                message: "",
                successfull: false,
                disableChanges: false
            }
        },
        computed: {
            visibleFields() {
                return this.headers.filter(header => header.visible)
            }
        },
        components: {
            ColorPickerSelector
        },
        props: {
            addLevelProp: Boolean
        },
        watch: {
            addLevelProp () {
                console.log('en el watch')
                // this.addLevel()
            }
        },
        mounted() {
            const currentArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
            ProfileConfigurationDataService.findByArea(currentArea).then(
                response => {
                    this.levelsObjects = response.data
                    for (let i = 0; i < response.data.length; i++) {
                        var tmpObj = {id: response.data[i].id, name: response.data[i].name, level: response.data[i].level, min_range:response.data[i].min_range, color: response.data[i].color}
                        this.levels.push(tmpObj)
                    }
                },
                error => {
                    console.log((error.response && error.response.data) ||
                        error.message ||
                        error.toString())
                    
                    if (error.response.status === 300) {
                        console.log('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            )

            ProfileAnalysisDataService.findCVsByArea(currentArea).then(
                response => {
                    this.numberCVs = response.data.cvs.length
                },
                error => {
                    console.log((error.response && error.response.data) ||
                        error.message ||
                        error.toString())
                    
                    if (error.response.status === 300) {
                        console.log('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            )

            ProfileConfigurationDataService.findLevelsConfigurationByArea(currentArea).then(
                response => {
                    if (response.status == 200) {
                        if (response.data.length !== 0) {
                            this.disableChanges = true
                            this.headers[4].visible = false
                        }
                        else this.disableChanges = false
                    }
                },
                error => {
                    console.log((error.response && error.response.data) ||
                        error.message ||
                        error.toString())
                    
                    if (error.response.status === 300) {
                        console.log('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );
        },
        methods: {
            showCancelModal() {
                this.backToSettings()
            },
            backToSettings() {
                this.$router.push({ path: 'settings'})
            },
            addLevel() {
                const newRow = {name: "", level: (this.levels.length+1).toString(), min_range:"", color: "#FF0000"}
                this.levels.push(newRow)
                this.$emit('input', this.levels)
            },
            showDeleteModal(item, index) {
                if (item.level.toString().trim().length === 0 && item.name.toString().trim().length === 0) {
                    this.levels.splice(this.selectedItemIndex, 1)
                } else {
                    this.selectedItem = item
                    this.selectedItemIndex = index
                    this.$refs['delete-modal'].show()
                }
            },
            checkErrors() {
                this.successfull = true

                for (let i = 0; i < this.levels.length; i++) {
                    if (this.levels[i].name.toString().trim().length === 0 || this.levels[i].level.toString().trim().length === 0) {
                        this.successfull = false
                        this.message = 'There are empty fields'
                    } else if (parseInt(this.levels[i].level) <= 0) {
                        this.successfull = false
                        this.message = 'There are levels with a negative or 0 value'
                    } else if (parseInt(this.levels[i].min_range) > 100) {
                        this.successfull = false
                        this.message = 'Ranges must be below 100%'
                    } else {
                        for (let j = 0; j < this.levels.length; j++) {
                            if (i !== j) {
                                if (this.levels[i].name.toString().trim().toLowerCase() === this.levels[j].name.toString().trim().toLowerCase()) {
                                    this.successfull = false
                                    this.message = 'There are repeated level names'
                                }
                            }
                        }
                    }
                }

                if (this.successfull) {
                    for (let i = 0; i < this.levels.length; i++) {
                        var repeated = false
                        var repeatedRanges = false
                        var rangeOrder = false 
                        for (let j = 0; j < this.levels.length; j++) {
                            if (i !== j && parseInt(this.levels[i].level) === parseInt(this.levels[j].level)) {
                                repeated = true
                            }if (i !== j && parseInt(this.levels[i].min_range) === parseInt(this.levels[j].min_range)) {
                                repeatedRanges = true
                            } if (i !== j && parseInt(this.levels[i].level) < parseInt(this.levels[j].level) && parseInt(this.levels[i].min_range) > parseInt(this.levels[j].min_range)) {
                                rangeOrder = true
                            }
                        }

                        if (repeated) {
                            this.successfull = false
                            this.message = 'Levels must have differente value'
                        } else if(repeatedRanges){
                            this.successfull = false
                            this.message = 'Ranges must have differente value'
                        }else if(rangeOrder){
                            this.successfull = false
                            this.message = 'Minimum range must be coherent'
                        }
                    }

                    if (this.successfull) {
                        for (let i = 0; i < this.levels.length; i++) {
                            var existsValue = false

                            for (let j = 0; j < this.levels.length; j++) {
                                if (parseInt(this.levels[j].level) === (i+1)) {
                                    existsValue = true
                                }
                            }

                            if (!existsValue) {
                                this.successfull = false
                                this.message = 'Levels have to be separated by an unit step and start in 1'
                            }
                        }

                        if (this.successfull) {
                            for (let i = 0; i < this.levels.length; i++) {
                                var repeatColor = false

                                for (let j = 0; j < this.levels.length; j++) {
                                    if (i !== j && this.levels[i].color === this.levels[j].color) {
                                        repeatColor = true
                                    }
                                }

                                if (repeatColor) {
                                    this.successfull = false
                                    this.message = 'Colors of the levels must be different from each other'
                                }
                            }
                        }
                    }
                }
                if (this.successfull) {
                    this.message = ""
                    this.handleSave()
                }
            },
            deleteLevel() {
                this.$refs['delete-modal'].hide()
                this.levels.splice(this.selectedItemIndex, 1)

                for (let i = 0; i < this.levels.length; i++) {
                    this.levels[i].level = (i+1).toString()
                }
            },
            async handleSave() {
                for (let i = 0; i < this.levels.length; i++) {
                    var exists = false
                    var idArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
                    var id_level = 0

                    for (let j = 0; j < this.levelsObjects.length; j++) {

                        if (this.levels[i].id === this.levelsObjects[j].id) {
                            exists = true
                            id_level = this.levelsObjects[j].id
                        }
                    }

                    if (exists) {
                        this.levels[i].id = id_level
                        await ProfileConfigurationDataService.update(this.levels[i]).then(
                            response => {
                                console.log('')
                            },
                            error => {
                                this.successfull = false
                                console.log((error.response && error.response.data) ||
                                    error.message ||
                                    error.toString())
                                
                                if (error.response.status === 300) {
                                    console.log('Logout: Token expired')
                                    localStorage.removeItem('user');
                                    localStorage.removeItem('company');
                                    this.$store.state.auth.user = null
                                    this.$store.dispatch('auth/logout');
                                    this.$router.push('/login');
                                }
                            }
                        )
                    } else {
                        this.levels[i].id_area = idArea
                        await ProfileConfigurationDataService.create(this.levels[i]).then(
                            response => {
                                console.log('')
                            },
                            error => {
                                this.successfull = false
                                console.log((error.response && error.response.data) ||
                                    error.message ||
                                    error.toString())
                                
                                if (error.response.status === 300) {
                                    console.log('Logout: Token expired')
                                    localStorage.removeItem('user');
                                    localStorage.removeItem('company');
                                    this.$store.state.auth.user = null
                                    this.$store.dispatch('auth/logout');
                                    this.$router.push('/login');
                                }
                            }
                        )
                    }
                }

                for (let i = 0; i < this.levelsObjects.length; i++) {
                    var exists = false

                    for (let j = 0; j < this.levels.length; j++) {
                        if (this.levels[j].id === this.levelsObjects[i].id) {
                            exists = true
                        }
                    }

                    if(!exists) {
                        await ProfileConfigurationDataService.deleteLevel(this.levelsObjects[i].id).then(
                            response => {
                                console.log('')
                            },
                            error => {
                                this.successfull = false
                                console.log((error.response && error.response.data) ||
                                    error.message ||
                                    error.toString())
                                
                                if (error.response.status === 300) {
                                    console.log('Logout: Token expired')
                                    localStorage.removeItem('user');
                                    localStorage.removeItem('company');
                                    this.$store.state.auth.user = null
                                    this.$store.dispatch('auth/logout');
                                    this.$router.push('/login');
                                }
                            }
                        )
                    }
                }

                if (this.successfull) {
                    this.message = 'Configuration of the Levels of Expertise updated successfully!'
                    const currentArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
                    this.levels = []
                    this.levelsObjects = []
                    await ProfileConfigurationDataService.findByArea(currentArea).then(
                        response => {
                            this.levelsObjects = response.data
                            for (let i = 0; i < response.data.length; i++) {
                                var tmpObj = {id: response.data[i].id, name: response.data[i].name, level: response.data[i].level, min_range:response.data[i].min_range, color: response.data[i].color}
                                this.levels.push(tmpObj)
                            }
                        },
                        error => {
                            console.log((error.response && error.response.data) ||
                                error.message ||
                                error.toString())
                            
                            if (error.response.status === 300) {
                                console.log('Logout: Token expired')
                                localStorage.removeItem('user');
                                localStorage.removeItem('company');
                                this.$store.state.auth.user = null
                                this.$store.dispatch('auth/logout');
                                this.$router.push('/login');
                            }
                        }
                    )
                } else {
                    this.message = 'Error updating the Configuration of the Levels of Expertise!'
                }
            },
            close() {
                document.getElementById("message").style.display = "none"
                this.message = ""
                this.successfull = false
            }
        }
    };
</script>

<style scoped>
.alignCenter {
    margin: 0 auto;
}

.btnStyle {
    float: right;
    width: 16%;
    margin-right: 10%;
    margin-left: auto;
    margin-bottom: 1%;
}

.subtitle {
    margin-top: 25px;
}

.noDisplay {
    display: none !important;
}

.color-picker span.color-picker-container span.current-color-color-picker {
    margin-left: 5px !important;
    margin-top: 8px !important;
}

.current-color-color-picker {
    margin-left: 5px !important;
    margin-top: 8px !important;
}
</style>