<template>
	<div class="col-md-12 technologiesStyle my-2" style="padding-top: 5% !important;">
		<div class="row">
			<div class="col-sm-12">
				<h5>Finish form</h5>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-12">
				<h6>If you agree with the settings you have made, click Finish.</h6>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'FinishForm',
	data() {
		return {
		}
	},
	components: {
	},
	mounted() {
		this.$emit('can-continue', { value: true });
	},
	watch: {
	},
	methods: {
	}
};
</script>

<style type="text/css" scoped></style>