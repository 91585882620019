import { render, staticRenderFns } from "./AddUsersToArea.vue?vue&type=template&id=2ac652f6&scoped=true&"
import script from "./AddUsersToArea.vue?vue&type=script&lang=js&"
export * from "./AddUsersToArea.vue?vue&type=script&lang=js&"
import style0 from "./AddUsersToArea.vue?vue&type=style&index=0&id=2ac652f6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ac652f6",
  null
  
)

export default component.exports