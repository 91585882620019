<template>
	<div class="table-container  technologiesStyle">
		<template v-if="questions.length >0">
			<div class="row" style="margin-top: 1%;">
				<div class="col-xl-3 col-lg-4">
					<b-pagination
						class="pagination"
						v-model="currentPage"
						:total-rows="rows"
						:per-page="perPage"
						aria-controls="questionsList"
					></b-pagination>
				</div>
				<div class="col-xl-6 col-lg-6">
					<b-form-select class="numPerPage paginationSelectorTable" v-model="perPage" :options="pageOptions"></b-form-select>
					<div class="numPerPage paginationSelectorTable mx-3 my-2">Language: </div>
					<b-form-select class="numPerPage paginationSelectorTable" v-model="languageModel" @change="filterQuestions" :options="languages"> </b-form-select>  
					<div class="numPerPage paginationSelectorTable mx-2 my-2">Level of Difficulty: </div>
					<b-form-select class="numPerPage paginationSelectorTable mx-1" v-model="difficultyModel" @change="filterQuestions" :options="difficulties"> </b-form-select>

					<button v-b-tooltip.hover class="btn btn-primary table-selection-button" type="button" @click="selectAllRows"><font-awesome-icon icon="check" class="white-color" /> Select all rows</button>  
					<button v-b-tooltip.hover class="btn btn-primary table-selection-button my-1" type="button" @click="clearSelected"><font-awesome-icon icon="ban" class="white-color" /> Clear All</button>  
					<button v-b-tooltip.hover  v-if="selectedItems.length > 0" class="btn btn-primary table-selection-button delete-button my-1" type="button" @click="showDeleteModalMultiple"><font-awesome-icon icon="trash" class="white-color" /> Delete {{selectedItems.length}}rows</button>   
				</div>
				<div class="col-xl-3 col-lg-3">
					<b-form-input 
						id="filter-input"
						v-model="filter"
						type="search"
						:placeholder="$t('others.search-placeholder-msg')"
					></b-form-input>
				</div>
			</div>
			<br>
			<b-table ref="questionsTable"
				id="questionsList"
				:per-page="perPage"
				:current-page="currentPage"
				:fields="headers"
				:items="filterQuestions()"
				:tbody-tr-class="rowClass"
				:filter="filter"
				select-mode="multi"
				responsive="sm"
				selectable
				@row-selected="onRowSelected">
				<template #cell(selected)="{ rowSelected }">
					<template v-if="rowSelected">
						<span aria-hidden="true">&check;</span>
						<span class="sr-only">Selected</span>
					</template>
					<template v-else>
						<span aria-hidden="true">&nbsp;</span>
						<span class="sr-only">Not Selected</span>
					</template>
				</template>
				<template #cell(question_id)="data">
					<p>{{data.item.id}}</p>
				</template>
				<template #cell(question_title)="data">
					<p class="align-left">{{data.item.question_title}}</p>
				</template>
				<template #cell(question_tags)="data">
					<ul v-if="data.item.tags.length > 0" class="ti-tags small">
						<li class="ti-tag ti-valid" v-for="item in data.item.tags" v-bind:key="item.id">
							<div class="ti-content">
								<div class="ti-tag-center">
									<span>{{item.name}}</span>
								</div>
							</div>
						</li>
					</ul>
					<ul v-else class="no-tags">
						No tags
					</ul>
				</template>
				<template #cell(correct_answered_average)="data"> 
					<p v-if="data.item.correct_answered_average == undefined || data.item.correct_answered_average == null">Question not answered</p>
					<p v-else-if="data.item.correct_answered_average > 30" :style="'background: #CFE8A7'" class="levelStyle">{{ parseFloat(data.item.correct_answered_average).toFixed(2) }} %</p>
					<p v-else-if="data.item.correct_answered_average < 30" :style="'background: #FF8888'" class="levelStyle"> {{ parseFloat(data.item.correct_answered_average).toFixed(2) }} %</p>
				</template>
				<template #cell(difficulty)="data">
					<p>{{data.item.difficulty}}</p>
				</template>
				<template #cell(language)="data">
					<p>{{data.item.language}}</p>
				</template>
				<!--<template #cell(created_at)="data">
					<p>{{ data.item.created_at | formatEnglishDate}}</p>
				</template>-->
				<template #cell(updated_at)="data">
					<p>{{ data.item.updated_at | formatEnglishDateWithoutHour }}</p>
				</template>
				<template #cell(options)="data">
					<button v-b-tooltip.hover title="Show question" @click="showQuestionModal(data.item)" class="btn btn-secondary"><font-awesome-icon icon="eye" /></button>
					<router-link v-b-tooltip.hover title="Edit" :to="{path: '/update-question?id=' + data.item.id}" class="btn btn-secondary"><font-awesome-icon icon="edit" /></router-link>
					<button v-b-tooltip.hover title="Validate" v-show="!data.item.validated" class="btn btn-secondary" v-bind:id="'validate_'+data.index" @click="showValidateModal(data.item.id, data.index)"><font-awesome-icon icon="check" /></button>
					<button v-b-tooltip.hover title="Dismiss" v-show="data.item.validated" class="btn btn-secondary" v-bind:id="'avoid_'+data.index" @click="showAvoidModal(data.item.id, data.index)"><font-awesome-icon icon="ban" /></button>
				</template>
			</b-table>
		</template>
		<template v-else>
			<h3 class="no-results-msg"><font-awesome-icon icon="times-circle" class="black-color"/> No results found.</h3>
		</template>

		<b-modal id="question-modal" size="lg" ref="question-modal">
			<question-modal :modalQuestion="modalQuestion"/>
		</b-modal>

		<b-modal id="delete-modal" ref="delete-modal" title="Delete-modal" hide-footer>
			<template #modal-title>
				Delete question
			</template>
			<div>
				<p v-if="selectedItems.length > 1"> Do you really want to delete {{selectedItems.length}} questions? </p>
				<p v-else> Do you really want to delete this question? </p>
			</div>
			<input class="btn btn-primary form-submit" type="button" @click="deleteQuestion()" value="Yes">
			<input class="btn btn-primary form-submit" style="margin-left: 1%;" type="button" @click="$bvModal.hide('delete-modal')" value="No">
		</b-modal>

		<b-modal id="validate-modal" ref="validate-modal" title="Validate-modal" hide-footer>
			<template #modal-title>
				Validate question
			</template>
			<div>
				<p>Do you really want to validate this question? </p>
			</div>
			<input class="btn btn-primary form-submit" type="button" @click="validateQuestion()" value="Yes">
			<input class="btn btn-primary form-submit" style="margin-left: 1%;" type="button" @click="$bvModal.hide('validate-modal')" value="No">
		</b-modal>

		<b-modal id="avoid-modal" ref="avoid-modal" title="Avoid-modal" hide-footer>
			<template #modal-title>
				Dismiss question
			</template>
			<div>
				<p>Do you really want to dismiss this question? </p>
			</div>
			<input class="btn btn-primary form-submit" type="button" @click="avoidQuestion()" value="Yes">
			<input class="btn btn-primary form-submit" style="margin-left: 1%;" type="button" @click="$bvModal.hide('avoid-modal')" value="No">
		</b-modal>
	</div>
</template>

<script>
	import QuestionDataService from '@/services/QuestionDataService';
	import TagDataService from '@/services/TagDataService';
	import $ from 'jquery'
	import QuestionModal from '@/components/Others/Modals/Question';
	import SmartTagz from '@/components/Others/SmartTagz.vue';
  	import { MultiSelect  } from 'vue-search-select'
	import 'vue-search-select/dist/VueSearchSelect.css'
	
	export default {
		name: 'QuestionsListComponent',
		data() {
			return {
				languages: [],
				difficulties: [],
				message: "",
				filterByLanguage: false,
				filterByLevel: false,
				languageModel: 0,
				difficultyModel: 0,
				successfull: false,
				perPage: 5,
				pageOptions: this.$paginationOptions,
				currentPage: 1,
				filter: null,
				modalQuestion: {},
				clickable: true,
				tags: [],
				items: [],
				selectedItems: [],
				selectedTags: [],
				selectedQuestion: null,
				selectedQuestionIndex: null,
				lastSelectItem: ""
			}
	    },
		components: {
			QuestionModal,
			SmartTagz,
			MultiSelect 
		},
	    computed: {
			rows() {
				return this.questions.length
	      	}
	    },
		mounted() {
			this.getTags();
		    this.getQuestionsBoard();
			this.filterQuestions(); 
			if(this.$store.state.auth.user.table_records_per_page != undefined)
				this.perPage = this.$store.state.auth.user.table_records_per_page
	  	},
	    props: {
	    	headers: Array,
			questions: Array,
	    },
	  	methods: {
			getQuestionsBoard(){
				QuestionDataService.getQuestionBoard(this.$store.state.auth.user.active_areas[this.$session.get("tabID")]).then(
					response => {
						const tempLanguages = response.data.languages; 
						const tempDifficulties = response.data.difficulty_levels;
						
						let initialLanguage = {
							text: "All",
							id: null,
							value: 0
						}

						this.languages.push(initialLanguage);

						for (var i=0; i<tempLanguages.length; i++) {
				      		let object = {
				      			text: tempLanguages[i].name,
				      			id: tempLanguages[i].id,
								value: i+1,
				      		}

				      		this.languages.push(object)
			      		}

						let initialDifficulty = {
							text: "All",
							id: null,
							value: 0,
						}

						this.difficulties.push(initialDifficulty);

						for (var i=0; i<tempDifficulties.length; i++) {
				      		let object = {
				      			text: tempDifficulties[i].name,
				      			id: tempDifficulties[i].id,
								value: i+1,
				      		}
				      		this.difficulties.push(object)
				      	}
					},
					error => {
						console.log((error.response && error.response.data) ||
							error.message ||
							error.toString())

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
			      	}
				);
			},
			getTags(){
				TagDataService.findByArea(this.$store.state.auth.user.active_areas[this.$session.get("tabID")]).then(
					response => {
						let tempTags = response.data;
						for (var i=0; i<tempTags.length; i++) {
							let object = {
								text: tempTags[i].name,
								id: tempTags[i].id
							}
							this.tags.push(object)
						}
					},
					error => {
						console.log((error.response && error.response.data) ||
							error.message ||
							error.toString())
						
						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
			    );
			},
			onRowSelected(items) {
		        this.selectedItems = items;
	      	},
	      	selectAllRows() {
	      		this.$refs["questionsTable"].selectAllRows();
	      	},
	      	clearSelected() {
	      		this.$refs["questionsTable"].clearSelected();
	      	},
	      	rowClass(item, type) {
		        if (!item || type !== 'row') return
		        if (item.validated == 1) return 'validated-question'
	      	},
      		showQuestionModal(record) {
      			this.modalQuestion = record
      			this.$refs['question-modal'].show()
		    },
	      	showDeleteModalMultiple() {
	      		this.$refs['delete-modal'].show();
	      	},
			deleteQuestion(){
				let selectedRows = this.selectedItems.map(element => element.id);

				QuestionDataService.delete(selectedRows).then(
					response => {
						if (response.status == 200) {
				      		this.successfull = true
							this.$emit('deleteQuestion');
				      	} else {
				      		this.successfull = false
				      	}
						
						this.$refs['delete-modal'].hide();
					},
					error => {
						console.log((error.response && error.response.data) ||
							error.message ||
							error.toString())
						
						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
	      	},			
			showValidateModal(id, index) {
				this.selectedQuestion = id
				this.selectedQuestionIndex = index
				this.$refs['validate-modal'].show();
	      	},
			validateQuestion(){
				QuestionDataService.validate(this.selectedQuestion).then(
					response => {
						this.message = response.data.message
						if (response.status == 200) {
							this.successfull = true
							this.$refs.questionsTable.refresh();
							$('#validate_'+this.selectedQuestionIndex).css('display', 'none')
							$('#avoid_'+this.selectedQuestionIndex).css('display', 'inline')
							$('.table tr ').eq(this.selectedQuestionIndex+1).addClass('validated-question')
						} else {
							this.successfull = false
						}
						this.$refs['validate-modal'].hide();
					},
					error => {
						console.log((error.response && error.response.data) ||
							error.message ||
							error.toString())

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
	      	},
			showAvoidModal(id, index) {
				this.selectedQuestion = id
				this.selectedQuestionIndex = index
				this.$refs['avoid-modal'].show();
	      	},
			avoidQuestion(){
				QuestionDataService.avoid(this.selectedQuestion).then(
					response => {
						this.message = response.data.message
						if (response.status == 200) {
							this.successfull = true
							this.$refs.questionsTable.refresh();
							$('#validate_'+this.selectedQuestionIndex).css('display', 'inline')
							$('#avoid_'+this.selectedQuestionIndex).css('display', 'none')
							$('.table tr ').eq(this.selectedQuestionIndex+1).removeClass('validated-question')
						} else {
							this.successfull = false
						}
						this.$refs['avoid-modal'].hide();
					},
					error => {
						console.log((error.response && error.response.data) ||
							error.message ||
							error.toString())
						
						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
	      	},
			filterQuestions(){
				if(this.languageModel == 0){
					if(this.difficultyModel == 0){
						return this.questions;
					}
					else {
						const difficulty = this.difficulties[this.difficultyModel].text;
						return this.questions.filter(field => field.difficulty == difficulty);
					}
				}
				else {
					if(this.difficultyModel == 0){
						const language = this.languages[this.languageModel].text;
						return this.questions.filter(field => field.language == language);
					} else {
						const difficulty = this.difficulties[this.difficultyModel].text; 
						const language = this.languages[this.languageModel].text;
						return this.questions.filter(field => field.difficulty == difficulty && field.language == language);
					}
				}
			},
			close() {
				document.getElementById("messageDelete").style.display = "none"
			}
	    }
	};
</script>

<style scoped>
.pagination-filter {
	display: flex;
	align-items: center;
}

.pagination {
	margin-bottom: 0px !important;
}

.ui.multiple.dropdown {
	z-index: 10;
}

.ui.active.ui.multiple.dropdown{
	z-index: 10;
	overflow: visible !important;
}

.div.menu.visible{
	z-index: 10;
	overflow: visible !important;
}

.responsiveTable {
	z-index: 1 !important; 
}

.numPerPage {
	height: 100%;
}

.margin-on-lg {
	margin-top: 1rem;
}
</style>


