<template>
    <div class="col-sm-12">
        <div class="row secondNavbar">
            <div class="col-5 links-container">
                <router-link :to="{ path: '/business-process' }"
                    class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to Evaluation
                    Process</router-link>
            </div>
            <div class="col-7 noMargin whiteColor">
                <router-link :to="{ path: '/modules' }" class="breadcumb"> Suite Tools > </router-link>
                <router-link :to="{ path: '/business-process' }" class="breadcumb"> Evaluation Process > </router-link>
                <router-link :to="{ path: '/business-process-ranking' }" class="final-breadcumb"> Add Tests
                </router-link>
            </div>
        </div>

        <div class="col-sm-12 col-centered">
            <add-tests />
        </div>
    </div>
</template>

<script>
import AddTests from "@/components/BusinessProcessComponents/AddTests.vue";

export default {
    name: 'AddTestsBusinessProcess',
    components: {
        AddTests,
    }
};
</script>

<style scoped>
#app>.bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
}

/* .createClass {
    background: border-box;
    border-radius: 26px;
    padding: inherit;
    margin-top: 0.6%;
    padding-right: 7px;
    padding-left: 7px;
} */
</style>