<template>
	<div class="col-sm-12">

		<div class="row secondNavbar">
			<div class="col-5 links-container">
				<router-link v-if="isAdmin" :to="{ path: '/admin-configuration' }"
					class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to
					Configuration</router-link>
				<router-link to="/admin-configuration-users-create-user?previousPage=admin-configuration-users"
					class="btn btn-secondary float-right createClass"><font-awesome-icon icon="plus" /> ADD
					USER</router-link>
			</div>
			<div v-if="isAdmin" class="col-7 noMargin whiteColor">
				<router-link :to="{ path: '/admin-configuration' }" class="breadcumb"> Configuration > </router-link>
				<router-link :to="{ path: '/admin-configuration-users' }" class="final-breadcumb"> Users </router-link>
			</div>
		</div>

		<div id="message" v-if="userRegistered" class="alert alert-success col-md-6 align-item-center">
			User registered succesfully! <b-button @click="close" class="cross-button"><font-awesome-icon class="cross"
					icon="times" /> </b-button>
		</div>
		<div id="message2" v-if="userUpdated" class="alert alert-success col-md-6 align-item-center">
			User updated succesfully! <b-button @click="close2" class="cross-button"><font-awesome-icon class="cross"
					icon="times" /> </b-button>
		</div>

		<users-list />
	</div>
</template>

<script>
import UsersList from "@/components/Users/List.vue";

export default {
	name: 'UsersView',
	components: {
		UsersList
	},
	computed: {
		currentUser() {
			return this.$store.state.auth.user;
		},
		isAdmin() {
			if (this.currentUser && this.currentUser.role) {
				if (this.currentUser.role.name === 'admin') {
					return true;
				}
			}
			return false;
		}
	},
	props: {
		userRegistered: {
			type: Boolean,
			default: false
		},
		userUpdated: {
			type: Boolean,
			default: false
		}
	},
	mounted() {
	},
	methods: {
		close() {
			document.getElementById("message").style.display = "none"
		},
		close2() {
			document.getElementById("message2").style.display = "none"
		}
	}
};
</script>

<style scoped>
#app>.bg-white .main-container {
	max-width: 100%;
	float: none;
	padding-left: 0px !important;
	padding-right: 0px !important;
	padding-top: 0px !important;
}

/* .createClass{
    background: border-box;
    border-radius: 26px;
    padding: inherit;
    margin-top: 0.6%;
	padding-right: 7px;
    padding-left: 7px;
} */
</style>