<template>
	<div class="row mt-3">
		<div class="col-md-1">
			<!--<button  v-if="numFilters -1 == filterIndex" class="btn btn-secondary remove-filter-btn" @click="removeFilter()" v-b-tooltip.hover title="Remove filter"><font-awesome-icon icon="trash" /></button>-->
			<button v-if="numFilters > 0" class="btn btn-secondary remove-filter-btn" @click="removeFilter()"
				v-b-tooltip.hover title="Remove filter"><font-awesome-icon icon="trash" /></button>
		</div>

		<div class="form-group-progradum col-md-3">
			<label>Section in CV</label>
			<select class="form-select" v-model="fieldName" @change="onChangeFieldName($event)"
				data-vv-name="type_selector" id="type_selector">
				<option v-for="item in types" v-bind:key="item.name" v-bind:value="item.type">
					{{ item.name }}
				</option>
			</select>
		</div>

		<template v-if="isList"> <!-- Lists -->
			<div v-if="isListOfVariable" class="col-md-7"> <!-- List of variables -->
				<div v-for="(val, index) in listValues" class="row">
					<div class="form-group-progradum col-md-4">
						<label>Operation</label>
						<select class="form-select" v-model="val.operation" @change="onChangeOperation($event)"
							data-vv-name="operation_selector" id="operation_selector">
							<template v-if="!isNumeric">
								<option v-for="op in operations" v-bind:key="op.name" v-bind:value="op.operation">
									{{ op.name }}
								</option>
							</template>
							<template v-else>
								<option value="equals">is</option>
							</template>
						</select>
					</div>
					<div class="form-group-progradum col-md-6">
						<label>Value to search</label>
						<input :type="isNumeric ? 'number' : 'text'" id="value_input" ref="value_input"
							v-model="val.value" />
					</div>
					<div class="col-md-1 plus-minus-filter-btn">
						<button v-if="index + 1 == listValues.length" class="btn btn-secondary"
							@click="addNewListValue()" v-b-tooltip.hover title="Add key filter"><font-awesome-icon
								icon="plus" /></button>
					</div>
					<div class="col-md-1 plus-minus-filter-btn">
						<button v-if="index + 1 == listValues.length && index != 0" class="btn btn-secondary"
							@click="removeNewListValue(index)" v-b-tooltip.hover
							title="Remove key filter"><font-awesome-icon icon="minus" /></button>
					</div>
				</div>
			</div>
			<div v-else class="col-md-7"> <!-- List of objects -->
				<div v-for="(val, index) in listObjValues" class="row">
					<div class="form-group-progradum col-md-4">
						<label>Field in section</label>
						<select class="form-select" v-model="val.key" @change="onChangeKey($event)"
							data-vv-name="field_key_selector" id="field_key_selector">
							<option v-for="field in currentFieldListKeys" v-bind:key="field" v-bind:value="field">
								{{ field }}
							</option>
						</select>
					</div>
					<div class="form-group-progradum col-md-3">
						<label>Operation</label>
						<select class="form-select" v-model="val.operation" @change="onChangeOperation($event)"
							data-vv-name="operation_selector" id="operation_selector">
							<template v-if="!isNumeric || isNumeric == 0">
								<template v-if="val.key === 'duration'">
									<option v-for="op in numberOperations" v-bind:key="op.name"
										v-bind:value="op.operation">
										{{ op.name }}
									</option>
								</template>
								<template v-else>
									<option v-for="op in textOperations" v-bind:key="op.name"
										v-bind:value="op.operation">
										{{ op.name }}
									</option>
								</template>
							</template>
							<template v-else>
								<option value="equals">is completed</option>
							</template>
						</select>
					</div>
					<div class="form-group-progradum col-md-3">
						<label>Value to search</label>
						<input :type="isNumeric ? 'number' : 'text'" class="form-input" ref="value_input"
							v-model="val.value" />
					</div>
					<div class="col-md-1 plus-minus-filter-btn">
						<button v-if="index + 1 == listObjValues.length" class="btn btn-secondary"
							@click="addNewListOfObjValue()" v-b-tooltip.hover title="Add key filter"><font-awesome-icon
								icon="plus" /></button>
					</div>
					<div class="col-md-1 plus-minus-filter-btn">
						<button v-if="index + 1 == listObjValues.length && index != 0" class="btn btn-secondary"
							@click="removeNewListOfObjValue(index)" v-b-tooltip.hover
							title="Remove key filter"><font-awesome-icon icon="minus" /></button>
					</div>
				</div>
				<div class="row" v-if="isDateRangeActivated">
					<div class="form-group-progradum col-md-3">
						<label>From</label>
						<input type="number" class="form-input" ref="value_input" v-model="fromDateExpTime" />
					</div>
					<div class="form-group-progradum col-md-3">
						<label>To</label>
						<input type="number" class="form-input" ref="value_input" v-model="toDateExpTime" />
					</div>
				</div>
			</div>
		</template>
		<template v-else> <!-- Not lists -->
			<template v-if="isObject"> <!-- Object -->
				<template v-if="isDynamicObject"> <!-- Dynamic object (improvement, type, progress) -->
					<div class="col-md-7">
						<div v-for="(val, index) in objValues" class="row">
							<div class="form-group-progradum col-md-3">
								<label>Operation</label>
								<select class="form-select" v-model="val.operation" data-vv-name="operation_selector"
									id="operation_selector">
									<option value="equals">is</option>
								</select>
							</div>
							<div class="form-group-progradum col-md-3">
								<label>Level of Expertise</label>
								<select class="form-select" v-model="val.value" data-vv-name="field_key_selector"
									id="field_key_selector">
									<option v-for="level in levels" v-bind:key="level.id" v-bind:value="level.name">
										{{ level.name.charAt(0).toUpperCase() + level.name.slice(1) }}
									</option>
								</select>
							</div>
							<div class="form-group-progradum col-md-4">
								<label>Field in section</label>
								<select class="form-select" v-model="val.key" data-vv-name="field_key_selector"
									id="field_key_selector">
									<option v-for="field in currentFieldKeys" v-bind:key="field"
										v-bind:value="field.replaceAll('in ', '').toLowerCase()">
										{{ field }}
									</option>
								</select>
							</div>
							<div class="col-md-1 plus-minus-filter-btn">
								<button v-if="index + 1 == objValues.length"
									class="btn btn-secondary plus-minus-filter-btn" @click="addNewObjValue()"
									v-b-tooltip.hover title="Add key filter"><font-awesome-icon icon="plus" /></button>
							</div>
							<div class="col-md-1 plus-minus-filter-btn">
								<button v-if="index + 1 == objValues.length && index != 0" class="btn btn-secondary"
									@click="removeNewObjValue(index)" v-b-tooltip.hover
									title="Add key filter"><font-awesome-icon icon="minus" /></button>
							</div>
						</div>
					</div>
				</template>
				<template v-else>
					<div class="col-md-7">
						<div v-for="(val, index) in objValues" class="row">
							<div class="form-group-progradum col-md-3">
								<label>Operation</label>
								<select class="form-select" v-model="val.operation" @change="onChangeOperation($event)"
									data-vv-name="operation_selector" id="operation_selector">
									<option v-for="op in operations" v-bind:key="op.name" v-bind:value="op.operation">
										{{ op.name }}
									</option>
								</select>
							</div>

							<div class="form-group-progradum col-md-3">
								<label>Value to search</label>
								<input :type="isNumeric ? 'number' : 'text'" class="form-input" ref="value_input"
									v-model="val.value" />

							</div>
							<div class="form-group-progradum col-md-4">
								<label>Field in section</label>
								<select class="form-select" v-model="val.key" @change="onChangeKey($event)"
									data-vv-name="field_key_selector" id="field_key_selector">
									<option v-for="field in currentFieldKeys" v-bind:key="field" v-bind:value="field">
										{{ field }}
									</option>
								</select>
							</div>
							<div class="col-md-1 plus-minus-filter-btn">
								<button v-if="index + 1 == objValues.length"
									class="btn btn-secondary plus-minus-filter-btn" @click="addNewObjValue()"
									v-b-tooltip.hover title="Add key filter"><font-awesome-icon icon="plus" /></button>
							</div>
							<div class="col-md-1 plus-minus-filter-btn">
								<button v-if="index + 1 == objValues.length && index != 0" class="btn btn-secondary"
									@click="removeNewObjValue(index)" v-b-tooltip.hover
									title="Add key filter"><font-awesome-icon icon="minus" /></button>
							</div>
						</div>
					</div>
				</template>
			</template>
			<template v-else>
				<div class="form-group-progradum col-md-2">
					<label>Operation</label>
					<select class="form-select" v-model="operation" @change="onChangeOperation($event)"
						data-vv-name="operation_selector" id="operation_selector">
						<template v-if="isDichotomic">
							<option value="equals">is completed</option>
						</template>
						<template v-else>
							<template v-if="isNumeric">
								<option v-for="op in operations" v-bind:key="op.name" v-bind:value="op.operation">
									{{ op.name }}
								</option>
							</template>
							<template v-else>
								<option v-for="op in operations" v-bind:key="op.name" v-bind:value="op.operation">
									{{ op.name }}
								</option>
							</template>
						</template>
					</select>
				</div>

				<div class="form-group-progradum col-md-4">
					<label>Value to search</label>
					<div v-if="isDichotomic" class="form-check form-switch dichotomic-switch">
						<input v-if="value" class="form-check-input checkbox-required eraseFormGroupStyles"
							type="checkbox" v-model="value" checked>
						<input v-else class="form-check-input checkbox-required eraseFormGroupStyles" type="checkbox"
							v-model="value">
					</div>
					<div v-else>
						<input :type="isNumeric ? 'number' : 'text'" class="form-input" ref="value_input"
							v-model="value" />
					</div>
				</div>
			</template>
		</template>
	</div>
</template>

<script>
import ProfileAnalysisDataService from '@/services/ProfileAnalysisDataService';
import ProfileConfigurationDataService from '@/services/ProfileConfigurationDataService';

export default {
	name: "FilterComponent",
	data() {
		return {
			filterFields: [],
			fieldName: '',
			fieldType: '',
			operation: '',
			value: '',
			types: [],
			listValues: [{ value: '', operation: '' }],
			listObjValues: [{ value: '', key: '', operation: '' }],
			objValues: [{ value: '', key: '', operation: '' }],
			fieldKey: '',
			currentFieldKeys: [],
			currentFieldListKeys: [],
			isDichotomic: false,
			isNumeric: false,
			isList: false,
			isObject: false,
			isListOfVariable: false,
			isDynamicObject: false,
			isDateRangeActivated: false,
			fromDateExpTime: new Date().getFullYear(),
			toDateExpTime: new Date().getFullYear(),
			typxps: [],
			companyProfiles: [],
			companyAvailableSkills: [],
			isLastElement: true,
			currentLevels: ['trainee', 'junior', 'middle', 'senior'],
			levelsObjects: [],
			levels: [],
			textOperations: [{ name: 'includes', operation: 'includes' }],
			numberOperations: [
				{ name: 'equal to', operation: 'equals' },
				{ name: 'less than', operation: 'lower' },
				{ name: 'more than', operation: 'higher' }
			],
			operations: [
				{ name: 'includes', operation: 'includes' },
				{ name: 'equal to', operation: 'equals' },
				{ name: 'less than', operation: 'lower' },
				{ name: 'more than', operation: 'higher' }
			],
			allOperations: [
				{ name: 'includes', operation: 'includes' },
				{ name: 'equal to', operation: 'equals' },
				{ name: 'less than', operation: 'lower' },
				{ name: 'more than', operation: 'higher' }
			]
		}
	},
	props: {
		filterIndex: Number,
		numFilters: Number
	},
	created() {
		console.log("CREADO EL FILTER: ", this.filterIndex)
		this.getFilterFields();
		this.findProfileByArea();
		this.getLevelsOfExpertise();
		this.getProfileSkillsByArea();
	},
	methods: {
		getFilterFields() {
			ProfileAnalysisDataService.getFilterFields().then(
				response => {
					if (response.status == 200) {
						let filterFields = response.data
						this.filterFields = filterFields

						let types = Object.keys(filterFields)

						let name = ""

						for (var i = 0; i < types.length; i++) {
							if ("finished" == types[i]) {
								name = "CV profile"
							} else if ("type" == types[i]) {
								name = "Required Profile Type"
							} else if ("progress" == types[i]) {
								name = "Required Profile Progress"
							} else if ("experience time" == types[i]) {
								name = "Years of experience"
							} else if ("experience jobs" == types[i]) {
								name = "Professional Experience"
							} else {
								name = types[i].charAt(0).toUpperCase() + types[i].slice(1);
								name = name.replace("_", " ")
							}
							this.types.push({ 'type': types[i], 'name': name })
						}
					} else {
						this.$emit('childMessage', "An error ocurred while filtering")
					}

				},
				error => {
					console.log((error.response && error.response.data) ||
						error.message ||
						error.toString())

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		findProfileByArea() {
			const currentArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
			ProfileConfigurationDataService.findProfileByArea(currentArea).then(
				response => {
					if (response.status == 200) {
						let companyProfiles = response.data

						companyProfiles.forEach(profile => {
							this.companyProfiles.push(profile.name.toLowerCase())
						})
					} else {
						this.message = response.data.message
					}
				},
				error => {
					console.log((error.response && error.response.data) ||
						error.message ||
						error.toString())
					this.message = error.response.data.message

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		getLevelsOfExpertise() {
			const currentArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
			ProfileConfigurationDataService.findByArea(currentArea).then(
				response => {
					this.levelsObjects = response.data
					for (let i = 0; i < response.data.length; i++) {
						var tmpObj = { id: response.data[i].id, name: response.data[i].name, level: response.data[i].level, min_range: response.data[i].min_range, color: response.data[i].color }
						this.levels.push(tmpObj)
					}
				},
				error => {
					console.log((error.response && error.response.data) ||
						error.message ||
						error.toString())

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			)
		},
		getProfileSkillsByArea() {
			const currentArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
			ProfileConfigurationDataService.profileSkillsByArea(currentArea).then(
				response => {
					if (response.status == 200) {
						this.companyAvailableSkills = response.data;
						this.companyAvailableSkills = this.companyAvailableSkills.map(function (e) { return e.name.replace("_", " ") })
					}
				},
				error => {
					console.log((error.response && error.response.data) ||
						error.message ||
						error.toString())

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		checkAvailableOperations(fieldName) {
			const stringFields = ["name", "awards", "certifications", "description", "email", "keywords", "languages", "links", "phone_number", "skills"]
			const numberFields = ["experience_time", "birthdate", "progress"]
			const stringOperations = this.allOperations.filter(e => e.operation != 'lower' && e.operation != 'higher')
			const numberOperations = this.allOperations.filter(e => e.operation == 'lower' || e.operation == 'higher' || e.operation == 'equals')

			console.log('numberOperations: ', numberOperations)
			console.log('fieldName: ', fieldName)

			if (stringFields.includes(fieldName)) {
				this.operations = stringOperations
			} else if (numberFields.includes(fieldName)) {
				this.isNumeric = true
				this.operations = numberOperations
			}

		},
		onChangeFieldName(event) {
			const dichotomicFields = ['finished']
			const specificFilters = ['improvement', 'type']
			let filterNameSelected = event.target.value;
			let field = this.filterFields[filterNameSelected];

			this.checkAvailableOperations(filterNameSelected)

			if ('experience_jobs' == filterNameSelected)
				this.isDateRangeActivated = true;
			else
				this.isDateRangeActivated = false;

			this.fieldType = field.type

			if ("variable" == field.type) {
				this.isList = false
				this.isListOfVariable = false
				this.isObject = false
				this.isDynamicObject = false

				if (dichotomicFields.includes(filterNameSelected)) {
					this.isDichotomic = true
				} else {
					this.isDichotomic = false
					if (field.is_numeric)
						this.isNumeric = true
					else
						this.isNumeric = false
				}
			} else if ("object" == field.type) {
				this.isList = false
				this.isListOfVariable = false
				this.isObject = true
				this.isDynamicObject = false

				if (specificFilters.includes(filterNameSelected)) {
					this.currentFieldKeys = this.companyProfiles.map(function (e) { return 'in ' + e.charAt(0).toUpperCase() + e.slice(1) })
					this.isDynamicObject = true
				} else if ("skills" == filterNameSelected) {
					this.currentFieldKeys = this.companyAvailableSkills.map(function (e) { return 'in ' + e.charAt(0).toUpperCase() + e.slice(1) })
					this.isDynamicObject = false
				} else if ("progress" == filterNameSelected) {
					this.currentFieldKeys = this.companyProfiles.map(function (e) { return 'in ' + e.charAt(0).toUpperCase() + e.slice(1) })
				} else {
					if (field.keys.length > 0)
						this.currentFieldKeys = field.keys.map(function (e) { return e.key })
				}
			} else if ("array" == field.type) {
				this.isList = true
				this.isObject = false
				this.isDynamicObject = false

				if (0 == field.level) {
					this.isListOfVariable = true

					if (field.is_numeric)
						this.isNumeric = true
					else
						this.isNumeric = false
				} else if (1 == field.level) {
					this.isListOfVariable = false

					if (field.keys.length > 0)
						this.currentFieldListKeys = field.keys.map(function (e) { return e.key })

				}

				console.log('this.currentFieldListKeys: ', this.currentFieldListKeys)
				console.log('field.keys: ', field.keys)
			}
		},
		addNewListValue() {
			this.listValues.push({ value: '' })
		},
		addNewObjValue() {
			let currentFieldFilter = this.filterFields[this.fieldName];
			let numKeys = currentFieldFilter.keys.length

			if (numKeys > this.objValues.length) {
				this.objValues.push({ value: '', key: '', operation: '' })
			} else {
				this.$emit('childMessage', "You cannot add more conditions to the keys available")
			}
		},
		addNewListOfObjValue() {
			let currentFieldFilter = this.filterFields[this.fieldName];
			let numKeys = currentFieldFilter.keys.length

			if (numKeys > this.listObjValues.length) {
				this.listObjValues.push({ value: '', key: '', operation: '' })
			} else {
				this.$emit('childMessage', "You cannot add more conditions to the keys available")
			}
		},
		removeNewListValue(index) {
			if (index > 0)
				this.listValues.splice(index, 1)
		},
		removeNewObjValue(index) {
			if (index > 0)
				this.objValues.splice(index, 1)
		},
		removeNewListOfObjValue(index) {
			if (index > 0)
				this.listObjValues.splice(index, 1)
		},
		onChangeOperation(event) {
			let operationSelected = event.target.value;
		},
		onChangeKey(event) {
			const stringOperations = this.allOperations.filter(e => e.operation != 'lower' && e.operation != 'higher')
			const numberOperations = this.allOperations.filter(e => e.operation == 'lower' || e.operation == 'higher')
			let filterKeySelected = event.target.value;

			let currentFieldFilter = this.filterFields[this.fieldName];

			currentFieldFilter.keys.forEach((item) => {
				if (item.key == filterKeySelected) {
					this.isNumeric = item.is_numeric == 1 ? true : false
					if (0 == item.is_numeric)
						this.operations = stringOperations
					else
						this.operations = numberOperations
				}
			})
		},
		removeFilter() {
			console.log("VOY A BORRAR EL FILTER: ", this.filterIndex)
			this.$emit('filterRemoveIndex', this.filterIndex)
		},
	},
	watch: {
	}
}
</script>

<style type="text/css" scoped>
.plus-minus-filter-btn {
	margin-top: 6%;
}

.remove-filter-btn {
	margin-top: 23%;
}

.dichotomic-switch {
	margin-top: 10%;
}
</style>