<template>
	<form action ref="form" class="form" style="padding-top: 5% !important;"> <!--@submit.prevent="handleCreate"-->
		<div id="message" v-if="message != ''" class="alert alert-danger">
			{{ message }} <b-button @click="close" class="cross-button"><font-awesome-icon class="cross" icon="times" />
			</b-button>
		</div>

		<div class="form-container technologiesStyle">
			<div class="row">
				<div class="form-group col-12">
					<label>Name*<b-button class="btn btn-info" v-b-tooltip.hover
							title="Name of the evaluation process"><font-awesome-icon
								icon="info-circle" /></b-button></label>
					<input class="form-input" type="text" id="name" v-validate="'required'" data-vv-name="name"
						v-model="bprocess.name">
				</div>
			</div>

			<div class="row mt-4">
				<div class="col-sm-6">
					<div class="row form-group">
						<label>Start date*</label>
						<input class="form-input" type="date" data-vv-name="start_date" v-model="bprocess.start_date"
							v-validate="'required'">
					</div>
				</div>
				<div class="col-sm-6">
					<div class="row form-group">
						<label>End date*</label>
						<input class="form-input" type="date" data-vv-name="end_date" v-model="bprocess.end_date"
							v-validate="'required'" :min="bprocess.start_date">
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<div class="row mt-4">
						<div class="col-sm-5 form-group-progradum">
							<label>
								CV Results Enabled*
								<b-button class="btn btn-info" v-b-tooltip.hover title="Activate cv results module">
									<font-awesome-icon icon="info-circle" />
								</b-button>
							</label>
						</div>
						<div class="col-sm-3 form-group-progradum">
							<div class="row">
								<div class="col-sm-6">
									<label>
										Min Score:
									</label>
								</div>
								<div class="col-sm-6">
									<input class="form-input" v-validate="'required'" max="100" min="0" type="number"
										oninput="validity.valid||(value=0);" v-model="cvExtractorMinScore"
										:disabled="bprocess.cv_extractor_enabled ? false : true">
								</div>
							</div>
						</div>
						<div class="col-sm-3 form-group-progradum">
							<div class="row">
								<div class="col-sm-6">
									<label>
										Weight (%):
									</label>
								</div>
								<div class="col-sm-6">
									<input class="form-input" v-validate="'required'" max="100000" min="0" type="number"
										oninput="validity.valid||(value=0);" v-model="bprocess.cv_extractor_weight"
										:disabled="bprocess.cv_extractor_enabled ? false : true">
								</div>
							</div>
						</div>
						<div class="col-sm-1 form-group-progradum">
							<div class="form-check form-switch dichotomic-switch config-switch">
								<input class="form-check-input checkbox-required" type="checkbox"
									v-model="bprocess.cv_extractor_enabled" disabled>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="form-group-progradum col-sm-5">
							<label>
								Hard Skills Enabled*
								<b-button class="btn btn-info" v-b-tooltip.hover title="Activate hard skills module">
									<font-awesome-icon icon="info-circle" />
								</b-button>
							</label>
						</div>

						<div class="form-group-progradum col-sm-3">
							<div class="row">
								<div class="col-sm-6">
									<label>
										Min Score:
									</label>
								</div>
								<div class="col-sm-6">
									<input class="form-input" v-validate="'required'" max="100" min="0" type="number"
										oninput="validity.valid||(value=0);" v-model="bprocess.hard_skills_min_score"
										:disabled="bprocess.hard_skills_enabled ? false : true">
								</div>
							</div>
						</div>
						<div class="form-group-progradum col-sm-3">
							<div class="row">
								<div class="col-sm-6">
									<label>
										Weight (%):
									</label>
								</div>
								<div class="col-sm-6">
									<input class="form-input" v-validate="'required'" max="100000" min="0" type="number"
										oninput="validity.valid||(value=0);" v-model="bprocess.hard_skills_weight"
										:disabled="bprocess.hard_skills_enabled ? false : true">
								</div>
							</div>
						</div>
						<div class="form-group-progradum col-sm-1">
							<div class="form-check form-switch dichotomic-switch config-switch">
								<input class="form-check-input checkbox-required" type="checkbox"
									v-model="bprocess.hard_skills_enabled">
							</div>
						</div>
					</div>

					<div class="row mt-4">
						<div class="form-group-progradum col-sm-5">
							<label>
								BEI/360 Evaluation Enabled*
								<b-button class="btn btn-info" v-b-tooltip.hover
									title="Activate BEI/360 Evaluation module">
									<font-awesome-icon icon="info-circle" />
								</b-button>
							</label>
						</div>
						<div class="form-group-progradum col-sm-3">
							<div class="row">
								<div class="col-sm-6">
									<label>
										Min Score:
									</label>
								</div>
								<div class="col-sm-6">
									<input v-if="!bprocess.evaluation_360_enabled || typeOfProcess === 'candidate'"
										class="form-input" v-validate="'required'" max="100" min="0" type="number"
										oninput="validity.valid||(value=0);" v-model="bprocess.evaluation_360_min_score"
										disabled>
									<input v-else class="form-input" v-validate="'required'" max="100" min="0"
										type="number" oninput="validity.valid||(value=0);"
										v-model="bprocess.evaluation_360_min_score">
								</div>
							</div>
						</div>
						<div class="form-group-progradum col-sm-3">
							<div class="row">
								<div class="col-sm-6">
									<label>
										Weight (%):
									</label>
								</div>
								<div class="col-sm-6">
									<input v-if="!bprocess.evaluation_360_enabled || typeOfProcess === 'candidate'"
										class="form-input" v-validate="'required'" max="100000" min="0" type="number"
										oninput="validity.valid||(value=0);" v-model="bprocess.evaluation_360_weight"
										disabled>

									<input v-else class="form-input" v-validate="'required'" max="100000" min="0"
										type="number" oninput="validity.valid||(value=0);"
										v-model="bprocess.evaluation_360_weight">
								</div>
							</div>
						</div>
						<div class="form-group-progradum col-sm-1">
							<div class="form-check form-switch dichotomic-switch config-switch">
								<input v-if="typeOfProcess === 'candidate'" class="form-check-input checkbox-required"
									type="checkbox" v-model="bprocess.evaluation_360_enabled" disabled>
								<input v-else class="form-check-input checkbox-required" type="checkbox"
									v-model="bprocess.evaluation_360_enabled">
							</div>
						</div>
					</div>

					<div class="row mt-4">
						<div class="form-group-progradum col-sm-5">
							<label>
								Soft Skills Enabled*
								<b-button class="btn btn-info" v-b-tooltip.hover title="Activate soft skills module">
									<font-awesome-icon icon="info-circle" />
								</b-button>
							</label>
						</div>
						<div class="form-group-progradum col-sm-3">
							<div class="row">
								<div class="col-sm-6">
									<label>
										Min Score:
									</label>
								</div>
								<div class="col-sm-6">
									<input class="form-input" v-validate="'required'" max="100" min="0" type="number"
										oninput="validity.valid||(value=0);" v-model="bprocess.soft_skills_min_score"
										:disabled="bprocess.soft_skills_enabled ? false : true">
								</div>
							</div>
						</div>
						<div class="form-group-progradum col-sm-3">
							<div class="row">
								<div class="col-sm-6">
									<label>
										Weight (%):
									</label>
								</div>
								<div class="col-sm-6">
									<input class="form-input" v-validate="'required'" max="100000" min="0" type="number"
										oninput="validity.valid||(value=0);" v-model="bprocess.soft_skills_weight"
										:disabled="bprocess.soft_skills_enabled ? false : true">
								</div>
							</div>
						</div>
						<div class="form-group-progradum col-sm-1">
							<div class="form-check form-switch dichotomic-switch config-switch">
								<input class="form-check-input checkbox-required" type="checkbox"
									v-model="bprocess.soft_skills_enabled" disabled>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="row mt-4">
				<div class="form-group col-12">
					<div class="row">
						<div class="col-sm-auto">
							<label>Profile*<b-button class="btn btn-info" v-b-tooltip.hover
									title="Profile associated to the process"><font-awesome-icon
										icon="info-circle" /></b-button></label>
						</div>
						<div class="col-sm-10">
							<multiselect id="profile_selector" :options="profiles" :close-on-select="true"
								:preserve-search="true" :taggable="true" :preselect-first="false" :option-width="140"
								placeholder="Search or add a profile to the evaluation process" v-model="profile">
								<template slot="singleLabel" slot-scope="props">
									<img class="option__image selector-img" :src="$awsBucketRoute + props.option.logo">
									<span class="option__desc"><span class="option__title">{{ props.option.name
											}}</span></span>
								</template>
								<template slot="option" slot-scope="props">
									<img class="option__image selector-img" :src="$awsBucketRoute + props.option.logo">
									<span class="option__desc"><span class="option__title">{{ props.option.name
											}}</span></span>
								</template>
							</multiselect>
						</div>
					</div>
				</div>
			</div>

			<div class="row mt-4">
				<div class="col-sm-2">
					<div class="row">
						<strong class="radio-button-title float-left" style="width: auto;">Type of process*</strong>
					</div>
					<div class="row ml-4">
						<div class="form-check">
							<input class="form-check-input radio-button-input"
								style="margin-left: 0 !important; width: 16px !important;" type="radio"
								name="exampleRadios1" id="exampleRadios1" value="user" v-model="typeOfProcess">
							<label class="form-check-label radio-button-label"
								style="float: left; margin-left: 3% !important;" for="exampleRadios1">
								Internal Evaluation Process
							</label>
						</div>
						<div class="form-check">
							<input class="form-check-input radio-button-input"
								style="margin-left: 0 !important; width: 16px !important;" type="radio"
								name="exampleRadios2" id="exampleRadios2" value="candidate" v-model="typeOfProcess">
							<label class="form-check-label radio-button-label"
								style="float: left; margin-left: 3% !important;" for="exampleRadios2">
								External Evaluation Process
							</label>
						</div>
					</div>
				</div>
			</div>

			<div class="row mt-4" v-if="typeOfProcess === 'candidate'">
				<div class="col-sm-12">
					<p class="alert alert-primary alert-profile alert-interview">
						The BEI/360 Evaluation module is only available for internal selection processes and has
						therefore been
						deactivated.
					</p>
				</div>
			</div>

			<div class="row justify-content-center mt-4" v-if="typeOfProcess !== null">
				<div class="form-group col-12">
					<div class="row">
						<div class="col-sm-4">
							<label v-if="typeOfProcess === 'user'">Users to add to the process*<b-button
									class="btn btn-info" v-b-tooltip.hover
									:title="'Users from ' + currentAreaName + ' to include. If the user has no associated CV, it will not be shown as an option to choose.'"><font-awesome-icon
										icon="info-circle" /></b-button></label>
							<label v-else>Candidates to add to the process*<b-button class="btn btn-info"
									v-b-tooltip.hover
									:title="'External candidates to include. If the candidate has no associated CV, it will not be shown as an option to choose.'"><font-awesome-icon
										icon="info-circle" /></b-button></label>
						</div>
						<div class="col-sm-3">
							<label style="float: right !important;">Sort by:</label>
						</div>
						<div class="col-sm-2">
							<div class="form-check"
								style="justify-content: center !important; display: flex !important;">
								<input class="form-check-input radio-button-input"
									style="margin-left: 0 !important; width: 16px !important;" type="radio"
									name="exampleRadios3" id="exampleRadios3" value="top" v-model="sortBy">
								<label class="form-check-label radio-button-label"
									style="float: left; margin-left: 3% !important;" for="exampleRadios3">
									Top Profile
								</label>
							</div>
						</div>
						<div class="col-sm-3" v-if="profile !== undefined && profile !== null && profile !== ''">
							<div class="form-check">
								<input class="form-check-input radio-button-input"
									style="margin-left: 0 !important; width: 16px !important;" type="radio"
									name="exampleRadios4" id="exampleRadios4" value="specified" v-model="sortBy">
								<label class="form-check-label radio-button-label"
									style="float: left; margin-left: 3% !important;" for="exampleRadios4">
									Selected profile ({{ profile.name }})
								</label>
							</div>
						</div>
					</div>
					<div class="row mb-2">
						<div class="col-sm-7">
							<label style="float: right !important;">Select top:</label>
						</div>
						<div class="col-sm-2">
							<div class="form-check"
								style="justify-content: center !important; display: flex !important;">
								<input class="form-check-input radio-button-input"
									style="margin-left: 0 !important; width: 65px !important;" type="number"
									name="exampleInput" id="exampleInput" v-model="selectTop">
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-12">
							<multiselect id="user_selector" v-if="typeOfProcess === 'user'" :options="userList"
								:multiple="true" :close-on-select="false" :preserve-search="true" :taggable="true"
								:preselect-first="false" :group-select="true"
								placeholder="Search or add a user to the evaluation process" v-model="selectedUsers"
								label="label" track-by="value" group-values="sub_options" group-label="option"
								@select="selectOptionUser" @remove="removeOptionUser"></multiselect>

							<multiselect id="candidate_selector" v-else :options="candidateList" :multiple="true"
								:close-on-select="false" :preserve-search="true" :taggable="true"
								:preselect-first="false" :group-select="true"
								placeholder="Search or add a candidate to the evaluation process"
								v-model="selectedCandidates" label="label" track-by="value" group-values="sub_options"
								group-label="option" @select="selectOptionCandidate" @remove="removeOptionCandidate">
							</multiselect>
						</div>
					</div>
				</div>

				<div class="form-group col-12">
					<b-table ref="usersTable" id="usersList" v-if="typeOfProcess === 'user'" :fields="headers"
						:items="tableUsers" select-mode="multi" responsive="sm">
						<template #cell(image)="data">
							<img v-if="data.item.data.image == null" :src="userImage" height="30em"
								class="analysis-card-avatar--circle" />
							<img v-else-if="data.item.data.image.includes('null')" :src="userImage" height="30em"
								class="analysis-card-avatar--circle" />
							<img v-else-if="data.item.data.image.includes($awsBucketRoute)"
								v-bind:src="data.item.data.image" height="30em" class="analysis-card-avatar--circle" />
							<img v-else v-bind:src="$awsBucketRoute + data.item.data.image" height="30em"
								class="analysis-card-avatar--circle" />
						</template>
						<template #cell(name)="data">
							{{ data.item.data.name }}
						</template>
						<template #cell(top_profile)="data">
							{{ data.item.top_profile.toUpperCase() }} ({{ data.item.top_profile_score }}%)
						</template>
						<template #cell(business_process_profile)="data">
							<template v-if="data.item.profile_name !== null && data.item.profile_score !== null">
								{{ data.item.profile_name.toUpperCase() }} ({{ data.item.profile_score }}%)
							</template>
							<template v-else>
								No selection process profile selected
							</template>
						</template>
					</b-table>

					<b-table ref="candidatesTable" id="candidatesList" v-else :fields="headers" :items="tableCandidates"
						select-mode="multi" responsive="sm">
						<template #cell(image)="data">
							<img :src="userImage" height="30em" class="analysis-card-avatar--circle" />
						</template>
						<template #cell(name)="data">
							{{ data.item.data.name }}
						</template>
						<template #cell(top_profile)="data">
							{{ data.item.top_profile.toUpperCase() }} ({{ data.item.top_profile_score }}%)
						</template>
						<template #cell(business_process_profile)="data">
							<template v-if="data.item.profile_name !== null && data.item.profile_score !== null">
								{{ data.item.profile_name.toUpperCase() }} ({{ data.item.profile_score }}%)
							</template>
							<template v-else>
								No selection process profile selected
							</template>
						</template>
					</b-table>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
import Vue from "vue";
import ProfileConfigurationDataService from '@/services/ProfileConfigurationDataService';
import AreasDataService from '@/services/AreasDataService';
import BusinessProcess from '@/models/business_process';
import Multiselect from 'vue-multiselect';

export default {
	name: 'Create',
	data() {
		return {
			bprocess: new BusinessProcess('', '', '', [], [], true, true, true, 0),
			currentAreaName: "",
			headers: [
				{ key: 'image', label: '', tdClass: 'tdSmall' },
				{ key: 'name', sortable: true, label: 'Name' },
				{ key: 'top_profile', sortable: true, label: 'Top Profile' },
				{ key: 'business_process_profile', sortable: true, label: 'Evaluation Process Profile' },
			],
			message: "",
			successful: false,
			candidatesToShow: [],
			usersToShow: [],
			usersToAdd: [],
			candidatesToAdd: [],
			tableUsers: [],
			tableCandidates: [],
			userList: [],
			selectedUsers: [],
			candidateList: [],
			selectedCandidates: [],
			logos: [],
			allAvailable: [],
			users: [],
			candidates: [],
			profiles: [],
			userImage: "http://" + location.host + '/images/icons/user.png',
			typeOfProcess: null,
			sortBy: 'top',
			profile: null,
			selectTop: 0,
			cvExtractorMinScore: 20
		}
	},
	components: {
		Multiselect
	},
	mounted() {
		this.$emit('can-continue', { value: true });
		// if (!this.$validator.validate()) {
		// 	this.$emit('can-continue', { value: true });
		// } else {
		// 	this.$emit('can-continue', { value: false });
		// }
		this.currentAreaName = this.$store.state.auth.user.areas.filter(area => area.id == this.$store.state.auth.user.active_areas[this.$session.get("tabID")]).map(function (e) { return e.name })[0];
		this.getAvailableCandidates();
		this.getAreaProfiles();
	},
	watch: {
		bprocess: {
			handler: function (val) {
				console.log('en el watch de bprocess')
				this.message = "";
				this.$validator.validate().then(isValid => {
					if (!isValid) {
						this.$emit('can-continue', { value: false });
						return;
					}

					if (!this.bprocess.cv_extractor_enabled && !this.bprocess.evaluation_360_enabled && !this.bprocess.hard_skills_enabled) {
						this.$emit('can-continue', { value: false });
						return;
					}

					if (!this.bprocess.cv_extractor_enabled) {
						this.bprocess.cv_extractor_weight = 0;
					}

					if (!this.bprocess.evaluation_360_enabled) {
						this.bprocess.evaluation_360_weight = 0;
					}

					if (!this.bprocess.hard_skills_enabled) {
						this.bprocess.hard_skills_weight = 0;
					}

					this.bprocess.cv_extractor_min_score = parseFloat(this.cvExtractorMinScore)

					const sumTotal = parseInt(this.bprocess.cv_extractor_weight) + parseInt(this.bprocess.evaluation_360_weight) + parseInt(this.bprocess.hard_skills_weight);

					if (sumTotal != 100) {
						this.message = "Total modules weight must sum 100.";
						this.$emit('can-continue', { value: false });
						return;
					}

					if (this.bprocess.id_profile == undefined || this.bprocess.id_profile == null || this.bprocess.id_profile == 0) {
						this.$emit('can-continue', { value: false });
						return;
					}

					if (this.bprocess.type == undefined || this.bprocess.type == null || (this.bprocess.type != 'internal' && this.bprocess.type != 'external')) {
						this.$emit('can-continue', { value: false });
						return;
					}

					if (this.bprocess.type == 'internal') {
						if (this.bprocess.users == null || this.bprocess.users == undefined || this.bprocess.users.length == 0) {
							this.$emit('can-continue', { value: false });
							return;
						}
					} else {
						if (this.bprocess.candidates == null || this.bprocess.candidates == undefined || this.bprocess.candidates.length == 0) {
							this.$emit('can-continue', { value: false });
							return;
						}
					}

					this.$emit('can-continue', { value: true });
				});
			},
			deep: true
		},
		typeOfProcess() {
			this.selectedCandidates = []
			this.selectedUsers = []
			this.tableCandidates = []
			this.tableUsers = []
			this.selectTop = 0

			if (this.typeOfProcess === 'user') {
				this.bprocess.type = "internal"
				this.bprocess.evaluation_360_enabled = true
			} else {
				this.bprocess.type = "external"
				this.bprocess.evaluation_360_enabled = false
			}
		},
		profile() {
			let tmpCandidates = [...this.tableCandidates]
			this.tableCandidates = []
			this.selectedCandidates = []
			let tmpUsers = [...this.tableUsers]
			this.tableUsers = []
			this.selectedUsers = []
			this.selectTop = 0

			this.users.forEach(user => {
				let keys = Object.keys(user.progress)
				for (let i = 0; i < keys.length; i++) {
					if (keys[i].toString().toLowerCase() === this.profile.name.toString().toLowerCase()) {
						user.profile_score = Object.values(user.progress)[i]
						user.profile_name = Object.keys(user.progress)[i]
					}
				}
			})
			this.userList[0].sub_options.forEach(user => {
				let keys = Object.keys(user.progress)
				for (let i = 0; i < keys.length; i++) {
					if (keys[i].toString().toLowerCase() === this.profile.name.toString().toLowerCase()) {
						user.profile_score = Object.values(user.progress)[i]
						user.profile_name = Object.keys(user.progress)[i]

						if (this.sortBy === 'specified') {
							user.label = user.name + ' - Selected profile: ' + user.profile_name.toUpperCase() + ' (' + user.profile_score + ')'
						}
					}
				}
			})

			this.candidates.forEach(candidate => {
				let keys = Object.keys(candidate.progress)
				for (let i = 0; i < keys.length; i++) {
					if (keys[i].toString().toLowerCase() === this.profile.name.toString().toLowerCase()) {
						candidate.profile_score = Object.values(candidate.progress)[i]
						candidate.profile_name = Object.keys(candidate.progress)[i]
					}
				}
			})

			this.candidateList[0].sub_options.forEach(candidate => {
				let keys = Object.keys(candidate.progress)
				for (let i = 0; i < keys.length; i++) {
					if (keys[i].toString().toLowerCase() === this.profile.name.toString().toLowerCase()) {
						candidate.profile_score = Object.values(candidate.progress)[i]
						candidate.profile_name = Object.keys(candidate.progress)[i]

						if (this.sortBy === 'specified') {
							candidate.label = candidate.name + ' - Selected profile: ' + candidate.profile_name.toUpperCase() + ' (' + candidate.profile_score + ')'
						}
					}
				}
			})

			this.allAvailable.forEach(all => {
				let keys = Object.keys(all.progress)
				for (let i = 0; i < keys.length; i++) {
					if (keys[i].toString().toLowerCase() === this.profile.name.toString().toLowerCase()) {
						all.profile_score = Object.values(all.progress)[i]
						all.profile_name = Object.keys(all.progress)[i]
					}
				}
			})

			if (this.cvExtractorMinScore !== null && this.cvExtractorMinScore !== undefined && this.cvExtractorMinScore !== '') {
				console.log('')
			}

			if (this.sortBy === 'specified') {
				this.users.sort((a, b) => a.profile_score < b.profile_score ? 1 : -1)
				this.userList[0].sub_options.sort((a, b) => a.profile_score < b.profile_score ? 1 : -1)
				this.candidates.sort((a, b) => a.profile_score < b.profile_score ? 1 : -1)
				this.candidateList[0].sub_options.sort((a, b) => a.profile_score < b.profile_score ? 1 : -1)
				this.allAvailable.sort((a, b) => a.profile_score < b.profile_score ? 1 : -1)
			}

			this.tableCandidates = tmpCandidates
			this.tableUsers = tmpUsers


			this.bprocess.id_profile = this.profile.id;
		},
		sortBy() {
			let tmpCandidates = [...this.tableCandidates]
			this.tableCandidates = []
			let tmpUsers = [...this.tableUsers]
			this.tableUsers = []

			this.users.forEach(user => {
				let keys = Object.keys(user.progress)
				for (let i = 0; i < keys.length; i++) {
					if (keys[i].toString().toLowerCase() === this.profile.name.toString().toLowerCase()) {
						user.profile_score = Object.values(user.progress)[i]
						user.profile_name = Object.keys(user.progress)[i]
					}
				}
			})
			this.userList[0].sub_options.forEach(user => {
				let keys = Object.keys(user.progress)
				for (let i = 0; i < keys.length; i++) {
					if (keys[i].toString().toLowerCase() === this.profile.name.toString().toLowerCase()) {
						user.profile_score = Object.values(user.progress)[i]
						user.profile_name = Object.keys(user.progress)[i]

						if (this.sortBy === 'specified') {
							user.label = user.name + ' - Selected profile: ' + user.profile_name.toUpperCase() + ' (' + user.profile_score + ')'
						} else {
							user.label = user.name + ' - Top profile: ' + user.top_profile.toUpperCase() + ' (' + user.top_profile_score + ')'
						}
					}
				}
			})

			this.candidates.forEach(candidate => {
				let keys = Object.keys(candidate.progress)
				for (let i = 0; i < keys.length; i++) {
					if (keys[i].toString().toLowerCase() === this.profile.name.toString().toLowerCase()) {
						candidate.profile_score = Object.values(candidate.progress)[i]
						candidate.profile_name = Object.keys(candidate.progress)[i]
					}
				}
			})

			this.candidateList[0].sub_options.forEach(candidate => {
				let keys = Object.keys(candidate.progress)
				for (let i = 0; i < keys.length; i++) {
					if (keys[i].toString().toLowerCase() === this.profile.name.toString().toLowerCase()) {
						candidate.profile_score = Object.values(candidate.progress)[i]
						candidate.profile_name = Object.keys(candidate.progress)[i]

						if (this.sortBy === 'specified') {
							candidate.label = candidate.name + ' - Selected profile: ' + candidate.profile_name.toUpperCase() + ' (' + candidate.profile_score + ')'
						} else {
							candidate.label = candidate.name + ' - Top profile: ' + candidate.top_profile.toUpperCase() + ' (' + candidate.top_profile_score + ')'
						}
					}
				}
			})

			this.allAvailable.forEach(all => {
				let keys = Object.keys(all.progress)
				for (let i = 0; i < keys.length; i++) {
					if (keys[i].toString().toLowerCase() === this.profile.name.toString().toLowerCase()) {
						all.profile_score = Object.values(all.progress)[i]
						all.profile_name = Object.keys(all.progress)[i]
					}
				}
			})

			if (this.sortBy === 'top') {
				this.users.sort((a, b) => a.top_profile_score < b.top_profile_score ? 1 : -1)
				this.userList[0].sub_options.sort((a, b) => a.top_profile_score < b.top_profile_score ? 1 : -1)
				this.candidates.sort((a, b) => a.top_profile_score < b.top_profile_score ? 1 : -1)
				this.candidateList[0].sub_options.sort((a, b) => a.top_profile_score < b.top_profile_score ? 1 : -1)
				this.allAvailable.sort((a, b) => a.top_profile_score < b.top_profile_score ? 1 : -1)

			} else if (this.sortBy === 'specified') {
				this.users.sort((a, b) => a.profile_score < b.profile_score ? 1 : -1)
				this.userList[0].sub_options.sort((a, b) => a.profile_score < b.profile_score ? 1 : -1)
				this.candidates.sort((a, b) => a.profile_score < b.profile_score ? 1 : -1)
				this.candidateList[0].sub_options.sort((a, b) => a.profile_score < b.profile_score ? 1 : -1)
				this.allAvailable.sort((a, b) => a.profile_score < b.profile_score ? 1 : -1)
			}

			this.tableCandidates = tmpCandidates
			this.tableUsers = tmpUsers

			if (this.sortBy === 'top') {
				this.tableUsers.sort((a, b) => b.top_profile_score - a.top_profile_score);
			} else {
				this.tableUsers.sort((a, b) => b.progress[this.profile.name.toString().toLowerCase()] - a.progress[this.profile.name.toString().toLowerCase()]);
			}
		},
		selectTop() {
			if (parseFloat(this.selectTop) > 0) {
				this.tableUsers = []
				this.usersToAdd = []
				this.selectOptionUser(this.userList[0].sub_options.slice(0, parseFloat(this.selectTop)))
				this.selectedUsers = this.userList[0].sub_options.slice(0, parseFloat(this.selectTop))

				this.tableCandidates = []
				this.candidatesToAdd = []
				this.selectOptionCandidate(this.candidateList[0].sub_options.slice(0, parseFloat(this.selectTop)))
				this.selectedCandidates = this.candidateList[0].sub_options.slice(0, parseFloat(this.selectTop))
			}
		},
		cvExtractorMinScore() {
			if (this.profile !== undefined && this.profile !== null && this.profile !== '' && this.cvExtractorMinScore !== null && this.cvExtractorMinScore !== undefined && this.cvExtractorMinScore !== '') {
				this.selectedCandidates = []
				this.selectedUsers = []
				this.tableCandidates = []
				this.tableUsers = []
				this.selectTop = 0

				this.userList[0].sub_options = this.usersToShow.filter(user => user.profile_score >= parseFloat(this.cvExtractorMinScore));
				this.candidateList[0].sub_options = this.candidatesToShow.filter(candidate => candidate.profile_score >= parseFloat(this.cvExtractorMinScore));
			}

			this.bprocess.cv_extractor_min_score = parseFloat(this.cvExtractorMinScore)
		},
		candidatesToAdd() {
			Vue.set(this.bprocess, 'candidates', this.candidatesToAdd);
		},
		usersToAdd() {
			Vue.set(this.bprocess, 'users', this.usersToAdd);
		},
		clickedNext(val) {
			if (val === true) {
				this.$form.$touch();
			}
		}
	},
	methods: {
		getAvailableCandidates() {
			AreasDataService.findUsersAndCandidatesForBusinessProcess(this.$store.state.auth.user.active_areas[this.$session.get("tabID")]).then(
				response => {
					if (response.status == 200) {
						this.allAvailable = response.data

						this.users = this.allAvailable.filter(one => one.type == 'user')
						this.users.forEach((user) => {
							this.usersToShow.push({
								label: user.data.name + ' - Top profile: ' + user.top_profile.toUpperCase() + ' (' + user.top_profile_score + ')',
								value: user.data.name + '-' + user.data.id,
								name: user.data.name,
								id: user.data.id,
								email: user.data.email,
								progress: user.progress,
								top_profile: user.top_profile,
								top_profile_score: user.top_profile_score,
								profile_score: null,
								profile_name: null
							})
						});

						let userListTmp = [{ option: "Select all", sub_options: [] }]
						userListTmp[0].sub_options = this.usersToShow
						this.userList = userListTmp;
						this.selectedUsers = []

						this.candidates = this.allAvailable.filter(one => one.type == 'candidate')
						this.candidates.forEach((candidate) => {
							this.candidatesToShow.push({
								label: candidate.data.name + ' - Top profile: ' + candidate.top_profile.toUpperCase() + ' (' + candidate.top_profile_score + ')',
								value: candidate.data.name + '-' + candidate.data.id,
								name: candidate.data.name,
								id: candidate.data.id,
								email: candidate.data.email,
								progress: candidate.progress,
								top_profile: candidate.top_profile,
								top_profile_score: candidate.top_profile_score,
								profile_score: null,
								profile_name: null
							})
						});

						let candidateListTmp = [{ option: "Select all", sub_options: [] }]
						candidateListTmp[0].sub_options = this.candidatesToShow
						this.candidateList = candidateListTmp;
						this.selectedCandidates = []

						this.users.forEach(user => {
							user.profile_score = null
							user.profile_name = null
						})

						this.candidates.forEach(candidate => {
							candidate.profile_score = null
							candidate.profile_name = null
						})

						this.allAvailable.forEach(all => {
							all.profile_score = null
							all.profile_name = null
						})

						this.users.sort((a, b) => a.top_profile_score < b.top_profile_score ? 1 : -1)
						this.candidates.sort((a, b) => a.top_profile_score < b.top_profile_score ? 1 : -1)
						this.allAvailable.sort((a, b) => a.top_profile_score < b.top_profile_score ? 1 : -1)
					} else {
						this.message = response.data.message;
						this.successful = false;
					}
				},
				error => {
					console.error((error.response && error.response.data) ||
						error.message ||
						error.toString())
					this.message = (error.response && error.response.data) ||
						error.message ||
						error.toString();
					this.successful = false;

					if (error.response.status === 300) {
						console.error('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		getAreaProfiles() {
			const currentArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
			ProfileConfigurationDataService.findLevelsConfigurationByArea(currentArea).then(
				response => {
					if (response.status == 200) {
						this.profiles = response.data
						this.profiles.forEach((tech) => {
							this.logos[tech.name.toLowerCase()] = tech.logo.includes(this.$awsBucketRoute) ? tech.logo : this.$awsBucketRoute + tech.logo
						});
					}
				},
				error => {
					console.error((error.response && error.response.data) ||
						error.message ||
						error.toString())

					if (error.response.status === 300) {
						console.error('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		selectOptionUser(option) {
			var parsedOption = JSON.parse(JSON.stringify(option))

			if (parsedOption.length === undefined) {
				let object = {}
				object.label = parsedOption.label
				object.value = parsedOption.value
				var valueSplit = parsedOption.value.split('-')

				this.usersToAdd.push(parseInt(valueSplit[1]))

				let userToAdd = this.users.filter(user => parseInt(valueSplit[1]) == user.data.id)[0]
				this.tableUsers.push(userToAdd)
			} else {
				parsedOption.forEach(option => {
					let object = {}

					object.label = option.label
					object.value = option.value
					var valueSplit = option.value.split('-')

					this.usersToAdd.push(parseInt(valueSplit[1]))

					let userToAdd = this.users.filter(user => parseInt(valueSplit[1]) == user.data.id)[0]
					this.tableUsers.push(userToAdd)
				})
			}

			if (this.sortBy === 'top') {
				this.tableUsers.sort((a, b) => b.top_profile_score - a.top_profile_score);
			} else {
				this.tableUsers.sort((a, b) => b.progress[this.profile.name.toString().toLowerCase()] - a.progress[this.profile.name.toString().toLowerCase()]);
			}
		},
		removeOptionUser(option) {
			var parsedOption = JSON.parse(JSON.stringify(option))

			if (parsedOption.length === undefined) {
				var valueSplit = parsedOption.value.split('-')
				const index = this.usersToAdd.indexOf(parseInt(valueSplit[1]));
				this.usersToAdd.splice(index, 1)

				this.tableUsers = this.tableUsers.filter(user => user.data.id != parseInt(valueSplit[1]))
			} else {
				parsedOption.forEach(option => {
					var valueSplit = option.value.split('-')
					const index = this.usersToAdd.indexOf(parseInt(valueSplit[1]));
					this.usersToAdd.splice(index, 1)

					this.tableUsers = this.tableUsers.filter(user => user.data.id != parseInt(valueSplit[1]))
				})
			}
		},
		selectOptionCandidate(option) {
			var parsedOption = JSON.parse(JSON.stringify(option))

			if (parsedOption.length === undefined) {
				let object = {}
				object.label = parsedOption.label
				object.value = parsedOption.value
				var valueSplit = parsedOption.value.split('-')

				this.candidatesToAdd.push(parseInt(valueSplit[1]))

				let candidateToAdd = this.candidates.filter(candidate => parseInt(valueSplit[1]) == candidate.data.id)[0]
				this.tableCandidates.push(candidateToAdd)
			} else {
				parsedOption.forEach(option => {
					let object = {}

					object.label = option.label
					object.value = option.value
					var valueSplit = option.value.split('-')

					this.candidatesToAdd.push(parseInt(valueSplit[1]))

					let candidateToAdd = this.candidates.filter(candidate => parseInt(valueSplit[1]) == candidate.data.id)[0]
					this.tableCandidates.push(candidateToAdd)
				})
			}
		},
		removeOptionCandidate(option) {
			var parsedOption = JSON.parse(JSON.stringify(option))

			if (parsedOption.length === undefined) {
				var valueSplit = parsedOption.value.split('-')
				const index = this.candidatesToAdd.indexOf(parseInt(valueSplit[1]));
				this.candidatesToAdd.splice(index, 1)

				this.tableCandidates = this.tableCandidates.filter(candidate => candidate.data.id != parseInt(valueSplit[1]))
			} else {
				parsedOption.forEach(option => {
					var valueSplit = option.value.split('-')
					const index = this.candidatesToAdd.indexOf(parseInt(valueSplit[1]));
					this.candidatesToAdd.splice(index, 1)

					this.tableCandidates = this.tableCandidates.filter(candidate => candidate.data.id != parseInt(valueSplit[1]))
				})
			}
		},
		backToRepository() {
			this.$router.push({ path: 'business-process' })
		},
		close() {
			this.message = "";
		}
	}
};
</script>

<style type="text/css" scoped>
.selector-img {
	width: 8% !important;
}

.alert-success {
	color: black !important;
	background-color: #4FC0E8 !important;
}
</style>