import { Doughnut, mixins } from "vue-chartjs";
export default {
  extends: Doughnut,
  props: ["data", "options"],
  mounted() {
    this.renderPieChart();
  },
  computed: {
    chartData: function() {
      return this.data;
    }
  },
  methods: {
    renderPieChart: function() {
    this.renderChart(this.chartData,
      { responsive: true, maintainAspectRatio: false });    
    }
  },
  watch: {
    data: function() {
      this.$data._chart.destroy();
      this.renderPieChart();
    }
  }
};
