<template>
    <div class="form-container">
        <horizontal-stepper ref="stepper" :key="reloadComponent" :steps="formSteps" :top-buttons="true" :keep-alive="true"
            @completed-step="completeStep" @clicking-back="completeStep" @stepper-finished="finishForm">
        </horizontal-stepper>
    </div>
</template>

<script>
import BusinessProcessService from '@/services/BusinessProcessService';
import SoftskillsDataService from '@/services/SoftskillsDataService';
import HorizontalStepper from 'vue-stepper';
import Create from "@/components/BusinessProcessComponents/Create.vue";
import AddTests from "@/components/BusinessProcessComponents/AddTests.vue";
import AddBEI360 from "@/components/BusinessProcessComponents/AddBEI360.vue";
import FinishForm from "@/components/BusinessProcessComponents/FinishForm.vue";

export default {
    name: "CreateBusinessProcessComponent",
    components: {
        HorizontalStepper,
    },
    data() {
        return {
            title: "Create Evaluation Process",
            reloadComponent: false,
            formSteps: [
                {
                    icon: 'person',
                    name: 'first',
                    title: 'Set Configuration',
                    subtitle: 'Basic information about the Evaluation process',
                    component: Create,
                    top_buttons: true,
                    completed: true
                },
                {
                    icon: 'person',
                    name: 'second',
                    title: 'Select Tests',
                    subtitle: 'Select the tests which will be part of the Evaluation process',
                    component: AddTests,
                    top_buttons: true,
                    completed: true
                },
                {
                    icon: 'person',
                    name: 'third',
                    title: 'Add BEI/360 Evaluation',
                    subtitle: 'Add the BEI/360 Evaluation which will be part of the Evaluation process',
                    component: AddBEI360,
                    top_buttons: true,
                    completed: true
                },
                {
                    icon: 'person',
                    name: 'fourth',
                    title: 'Finish form',
                    subtitle: 'Confirm the configuration of the Evaluation Process',
                    component: FinishForm,
                    top_buttons: true,
                    completed: true
                },
            ],
            businessProcessId: 0,
        }
    },
    methods: {
        completeStep(payload) {
            this.formSteps.forEach((step) => {
                if (step.name === payload.name) {
                    step.completed = true;

                    if (step.name == 'first') {
                        if (this.$refs.stepper) {
                            localStorage.setItem('selectedUsers', JSON.stringify(this.$refs.stepper["$children"][0]._data.selectedUsers))

                            const bprocess = this.$refs.stepper["$children"][0]._data.bprocess

                            if (!bprocess.hard_skills_enabled) localStorage.setItem('disabledTests', true)
                            if (!bprocess.evaluation_360_enabled) localStorage.setItem('disabledBEI', true)
                        }
                    }
                }
            })
        },
        finishForm(payload) {
            console.log('finishForm')
            if (this.$refs.stepper) {
                let bprocess = this.$refs.stepper["$children"][0]._data.bprocess;
                bprocess.id_area = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];

                let tests = []
                let testsIds = []
                if (bprocess.hard_skills_enabled) {
                    tests = this.$refs.stepper["$children"][1]._data.selectedItems
                    tests.forEach(test => {
                        testsIds.push(test.id);
                    })
                }
                console.log('tests: ', tests)

                let evaluation360 = null
                let selectedHRTechnicians = null
                let hrtechnicianWeight = null
                let selectedResponsables = null
                let responsablesWeight = null
                let selectedPairs = null
                let pairsWeight = null
                let selectedColaborators = null
                let colaboratorsWeight = null

                if (bprocess.evaluation_360_enabled) {
                    evaluation360 = this.$refs.stepper["$children"][2]._data.evaluation
                    selectedHRTechnicians = this.$refs.stepper["$children"][2]._data.selectedHRTechnicians
                    hrtechnicianWeight = this.$refs.stepper["$children"][2]._data.hrtechnicianWeight
                    selectedResponsables = this.$refs.stepper["$children"][2]._data.selectedResponsables
                    responsablesWeight = this.$refs.stepper["$children"][2]._data.responsablesWeight
                    selectedPairs = this.$refs.stepper["$children"][2]._data.selectedPairs
                    pairsWeight = this.$refs.stepper["$children"][2]._data.pairsWeight
                    selectedColaborators = this.$refs.stepper["$children"][2]._data.selectedColaborators
                    colaboratorsWeight = this.$refs.stepper["$children"][2]._data.colaboratorsWeight

                    bprocess.id_evaluation_360 = evaluation360
                }

                console.log('evaluation360: ', evaluation360)
                console.log('selectedHRTechnicians: ', selectedHRTechnicians)
                console.log('hrtechnicianWeight: ', hrtechnicianWeight)
                console.log('selectedResponsables: ', selectedResponsables)
                console.log('responsablesWeight: ', responsablesWeight)
                console.log('selectedPairs: ', selectedPairs)
                console.log('pairsWeight: ', pairsWeight)
                console.log('selectedColaborators: ', selectedColaborators)
                console.log('colaboratorsWeight: ', colaboratorsWeight)

                console.log('bprocessToAdd: ', bprocess)

                BusinessProcessService.create(bprocess).then(
                    async response => {
                        this.businessProcessId = response.data.id;

                        const tests = [];
                        this.$refs.stepper["$children"][1]._data.selectedItems.forEach((item) => {
                            tests.push(item.id);
                        });

                        if (testsIds.length > 0) { // El modulo está habilitado
                            await BusinessProcessService.addTestsToBusinessProcess(this.businessProcessId, testsIds).then(
                                () => {
                                },
                                error => {
                                    console.log((error.response && error.response.data) ||
                                        error.message ||
                                        error.toString())

                                    this.message = (error.response && error.response.data) ||
                                        error.message ||
                                        error.toString();
                                    this.successfull = false;

                                    if (error.response.status === 300) {
                                        console.log('Logout: Token expired')
                                        localStorage.removeItem('user');
                                        localStorage.removeItem('company');
                                        this.$store.state.auth.user = null
                                        this.$store.dispatch('auth/logout');
                                        this.$router.push('/login');
                                    }
                                }
                            );
                        }

                        if (bprocess.type === 'internal') {
                            await BusinessProcessService.addUsersToBusinessProcess(response.data.id, bprocess.users).then(
                                () => {
                                    this.$router.push('/business-process');
                                },
                                error => {
                                    console.log((error.response && error.response.data) ||
                                        error.message ||
                                        error.toString())

                                    if (error.response.status === 300) {
                                        console.log('Logout: Token expired')
                                        localStorage.removeItem('user');
                                        localStorage.removeItem('company');
                                        this.$store.state.auth.user = null
                                        this.$store.dispatch('auth/logout');
                                        this.$router.push('/login');
                                    }
                                }
                            );
                        } else {
                            await BusinessProcessService.addCandidatesToBusinessProcess(response.data.id, bprocess.candidates).then(
                                () => {
                                    this.$router.push('/business-process');
                                },
                                error => {
                                    console.log((error.response && error.response.data) ||
                                        error.message ||
                                        error.toString())

                                    if (error.response.status === 300) {
                                        console.log('Logout: Token expired')
                                        localStorage.removeItem('user');
                                        localStorage.removeItem('company');
                                        this.$store.state.auth.user = null
                                        this.$store.dispatch('auth/logout');
                                        this.$router.push('/login');
                                    }
                                }
                            );
                        }

                        console.log('evaluation360 antes de añadir usuarios: ', evaluation360)

                        if (evaluation360 !== null) {
                            // TODO: Añadir usuarios
                            let usersToAdd = []
                            let usersAdded = JSON.parse(localStorage.getItem('selectedUsers'))
                            usersAdded.forEach(user => {
                                let userToAdd = {
                                    id_user: user.id,
                                }

                                usersToAdd.push(userToAdd)
                            });

                            console.log('usersToAdd: ', usersToAdd)

                            SoftskillsDataService.addUsersToEvaluation(usersToAdd, evaluation360, this.businessProcessId).then(
                                response => {
                                    if (200 == response.status) {
                                        console.log('usuarios añadidos')
                                        // TODO: Añadir evaluadores
                                        let evaluatorsToAdd = [];

                                        selectedHRTechnicians.forEach(hrtechnician => {
                                            let hrtechnicianValueSplitted = hrtechnician.value.split('-')
                                            let evaluator = {
                                                id_evaluator: parseInt(hrtechnicianValueSplitted[1]),
                                                role: 'hr_technician',
                                                id_evaluation: evaluation360,
                                                weight: parseFloat(hrtechnicianWeight / selectedHRTechnicians.length),
                                                done: 0
                                            }

                                            evaluatorsToAdd.push(evaluator)
                                        })

                                        selectedResponsables.forEach(responsable => {
                                            let responsableValueSplitted = responsable.value.split('-')
                                            let evaluator = {
                                                id_evaluator: parseInt(responsableValueSplitted[1]),
                                                role: 'responsible',
                                                id_evaluation: evaluation360,
                                                weight: parseFloat(responsablesWeight / selectedResponsables.length),
                                                done: 0
                                            }

                                            evaluatorsToAdd.push(evaluator)
                                        })

                                        selectedPairs.forEach(pair => {
                                            let pairValueSplitted = pair.value.split('-')
                                            let evaluator = {
                                                id_evaluator: parseInt(pairValueSplitted[1]),
                                                role: 'pair',
                                                id_evaluation: evaluation360,
                                                weight: parseFloat(pairsWeight / selectedPairs.length),
                                                done: 0
                                            }

                                            evaluatorsToAdd.push(evaluator)
                                        })

                                        selectedColaborators.forEach(colaborator => {
                                            let colaboratorValueSplitted = colaborator.value.split('-')
                                            let evaluator = {
                                                id_evaluator: parseInt(colaboratorValueSplitted[1]),
                                                role: 'collaborator',
                                                id_evaluation: evaluation360,
                                                weight: parseFloat(colaboratorsWeight / selectedColaborators.length),
                                                done: 0
                                            }

                                            evaluatorsToAdd.push(evaluator)
                                        })

                                        console.log('evaluatorsToAdd antes de añadir evaluadores: ', evaluatorsToAdd)
                                        
                                        SoftskillsDataService.addEvaluatorsToEvaluation(evaluatorsToAdd, evaluation360, this.businessProcessId).then(
                                            response => {
                                                if (200 == response.status) {
                                                    console.log('evaluadores añadidos')
                                                    this.$router.push({ name: "business-process dashboard" });
                                                } else {
                                                    this.successful = false
                                                    this.message = 'Error while adding evaluators to the evaluation'
                                                }
                                            },
                                            error => {
                                                console.error((error.response && error.response.data) ||
                                                    error.message ||
                                                    error.toString())

                                                this.successful = false
                                                this.message = 'There was an error while adding evaluators to the evaluation'

                                                if (error.response.status === 300) {
                                                    console.error('Logout: Token expired')
                                                    localStorage.removeItem('user');
                                                    localStorage.removeItem('company');
                                                    this.$store.state.auth.user = null
                                                    this.$store.dispatch('auth/logout');
                                                    this.$router.push('/login');
                                                } else {
                                                    return [];
                                                }
                                            }
                                        );
                                    } else {
                                        this.message = 'Error while adding users to the evaluation'
                                        this.successful = false
                                    }
                                },
                                error => {
                                    console.error((error.response && error.response.data) ||
                                        error.message ||
                                        error.toString())

                                    this.message = 'There was an error while adding weights to the evaluation'
                                    this.successful = false

                                    if (error.response.status === 300) {
                                        console.error('Logout: Token expired')
                                        localStorage.removeItem('user');
                                        localStorage.removeItem('company');
                                        this.$store.state.auth.user = null
                                        this.$store.dispatch('auth/logout');
                                        this.$router.push('/login');
                                    }
                                }
                            );
                        }
                    },
                    error => {
                        console.log((error.response && error.response.data) ||
                            error.message ||
                            error.toString())

                        this.message = error.response.data.message.replace("Bad Request.", "");

                        if (error.response.status === 300) {
                            console.log('Logout: Token expired')
                            localStorage.removeItem('user');
                            localStorage.removeItem('company');
                            this.$store.state.auth.user = null
                            this.$store.dispatch('auth/logout');
                            this.$router.push('/login');
                        }
                    }
                );
            }
        },
    }
};
</script>