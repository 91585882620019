<template>
    <form action class="form" @submit.prevent="handleCreate">
        <div class="row technology-skills-div mt-2">
            <div class="row mb-3 h4-div-class">
                <h4 class="h4-class">Main information</h4>
            </div>
            <div class="col-sm-12">
                <div class="col-md-12 align-item-center">
                    <div class="row">
                        <div class="form-group col-4">
                            <label>Name*<b-button class="btn btn-info" v-b-tooltip.hover
                                    title="Name of the evaluation"><font-awesome-icon
                                        icon="info-circle" /></b-button></label>
                            <input class="form-input" type="text" id="name" v-validate="'required'" data-vv-name="name"
                                v-model="evaluation.name">
                            <div v-if="submitted && veeErrors.has('name')" class="alert alert-danger">
                                {{ veeErrors.first('name') }}
                            </div>
                        </div>
                        <div class="form-group col-8">
                            <label>Description*<b-button class="btn btn-info" v-b-tooltip.hover
                                    title="Descriptive text of the evaluation"><font-awesome-icon
                                        icon="info-circle" /></b-button></label>
                            <input class="form-input" type="text" id="description" v-validate="'required'"
                                data-vv-name="acronym" v-model="evaluation.description">
                            <div v-if="submitted && veeErrors.has('description')" class="alert alert-danger">
                                {{ veeErrors.first('description') }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-3 h4-div-class">
                <h4 class="h4-class">Select users to evaluate</h4>
            </div>
            <div class="row mb-3">
                <div class="row mt-1">
                    <div class="col-sm-2">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                            aria-controls="evaluatedUsers" limit="3"></b-pagination>
                    </div>
                    <div class="col-sm-7">
                        <b-form-select class="numPerPage paginationSelectorTable" v-model="perPage"
                            :options="pageOptions"></b-form-select>
                    </div>
                    <div class="col-sm-3">
                        <b-form-input id="filter-input" v-model="filter" type="search"
                            :placeholder="$t('others.search-placeholder-msg')"></b-form-input>
                    </div>
                </div>
                <div class="col-sm-12 noMargin mb-3">
                    <b-table ref="usersTable" id="evaluatedUsers" :fields="headers" :items="allUsersInArea"
                        :filter="filter" :per-page="perPage" :current-page="currentPage" responsive="sm"
                        select-mode="multi" selectable @row-selected="onRowSelected">
                        <template #cell(username)="data">
                            <p>{{ data.item.username }}</p>
                        </template>
                        <template #cell(name)="data">
                            <p>{{ data.item.name }}</p>
                        </template>
                        <template #cell(surname)="data">
                            <p>{{ data.item.surname }}</p>
                        </template>
                        <template #cell(email)="data">
                            <p>{{ data.item.email }}</p>
                        </template>
                        <template #cell(role)="data">
                            <p v-if="data.item.role.name != null">{{ data.item.role.name.toUpperCase() }}</p>
                        </template>
                    </b-table>
                </div>
            </div>
            <div>
                <div class="row mb-3 h4-div-class">
                    <h4 class="h4-class">Select Evaluators<b-button class="btn btn-info" v-b-tooltip.hover
                            title="Select at least one user before choosing evaluators."><font-awesome-icon
                                icon="info-circle" /></b-button></h4>
                </div>
                <div class="row mb-2">
                    <div class="col-2">
                        <p class="p-text">External Evaluators: </p>
                    </div>
                    <div class="col-5">
                        <multiselect id="hrtechnician_selector" :options="hrtechniciansToShow" :multiple="true"
                            :close-on-select="false" :preserve-search="true" :taggable="true" :preselect-first="false"
                            placeholder="Search or add an External Evaluator" v-model="selectedHRTechnicians"
                            label="label" track-by="value" @select="selectOptionHRTechnicians"
                            @remove="removeOptionHRTechnicians"></multiselect>
                    </div>
                    <div class="col-5" v-if="hrtechniciansToAdd.length !== 0">
                        <div class="row">
                            <div class="col-3 padding-10">
                                <p class="p-weight-evaluators">Weight</p>
                            </div>
                            <div class="col-3 padding-0">
                                <b-input class="input-evaluator-weight" type="number" min="0" max="100"
                                    v-model="hrtechnicianWeight"></b-input>
                            </div>
                            <div class="col-1 padding-0">
                                <p class="p-percentage-evaluators">%</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-2">
                        <p class="p-text">Responsables: </p>
                    </div>
                    <div class="col-5">
                        <multiselect id="responsable_selector" :options="responsablesToShow" :multiple="true"
                            :close-on-select="false" :preserve-search="true" :taggable="true" :preselect-first="false"
                            placeholder="Search or add a responsable" v-model="selectedResponsables" label="label"
                            track-by="value" @select="selectOptionResponsables" @remove="removeOptionResponsables">
                        </multiselect>
                    </div>
                    <div class="col-5" v-if="responsablesToAdd.length !== 0">
                        <div class="row">
                            <div class="col-3 padding-10">
                                <p class="p-weight-evaluators">Weight</p>
                            </div>
                            <div class="col-3 padding-0">
                                <b-input class="input-evaluator-weight" type="number" min="0" max="100"
                                    v-model="responsablesWeight"></b-input>
                            </div>
                            <div class="col-1 padding-0">
                                <p class="p-percentage-evaluators">%</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-2">
                        <p class="p-text">Pairs: </p>
                    </div>
                    <div class="col-5">
                        <multiselect id="pair_selector" :options="pairsToShow" :multiple="true" :close-on-select="false"
                            :preserve-search="true" :taggable="true" :preselect-first="false"
                            placeholder="Search or add a pair" v-model="selectedPairs" label="label" track-by="value"
                            @select="selectOptionPairs" @remove="removeOptionPairs"></multiselect>
                    </div>
                    <div class="col-5" v-if="pairsToAdd.length !== 0">
                        <div class="row">
                            <div class="col-3 padding-10">
                                <p class="p-weight-evaluators">Weight</p>
                            </div>
                            <div class="col-3 padding-0">
                                <b-input class="input-evaluator-weight" type="number" min="0" max="100"
                                    v-model="pairsWeight"></b-input>
                            </div>
                            <div class="col-1 padding-0">
                                <p class="p-percentage-evaluators">%</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-2 mb-3">
                        <p class="p-text">Colaborators: </p>
                    </div>
                    <div class="col-5 mb-3">
                        <multiselect id="colaborator_selector" :options="colaboratorsToShow" :multiple="true"
                            :close-on-select="false" :preserve-search="true" :taggable="true" :preselect-first="false"
                            placeholder="Search or add a colaborator" v-model="selectedColaborators" label="label"
                            track-by="value" @select="selectOptionColaborators" @remove="removeOptionColaborators">
                        </multiselect>
                    </div>
                    <div class="col-5 mb-3" v-if="colaboratorsToAdd.length !== 0">
                        <div class="row">
                            <div class="col-3 padding-10">
                                <p class="p-weight-evaluators">Weight</p>
                            </div>
                            <div class="col-3 padding-0">
                                <b-input class="input-evaluator-weight" type="number" min="0" max="100"
                                    v-model="colaboratorsWeight"></b-input>
                            </div>
                            <div class="col-1 padding-0">
                                <p class="p-percentage-evaluators">%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div v-if="showSkillsMap()"> -->
            <div>
                <div class="row mb-3 h4-div-class">
                    <h4 class="h4-class">Select Skills Map</h4>
                </div>
                <div class="row container-fluid div-configuration">
                    <div class="col-2" v-if="byGroups">
                        <h6 class="order-title">Groups weights</h6>
                        <div class="form-check form-switch dichotomic-switch div-dichotomic"><input
                                id="groupsWeightsCheckbox" class="form-check-input checkbox-required" type="checkbox"
                                v-model="groupsWeights"></div>
                    </div>
                    <div class="col-3">
                        <h6 class="order-title">Weight per competence</h6>
                        <div class="form-check form-switch dichotomic-switch div-dichotomic"><input
                                id="competencesWeightsCheckbox" class="form-check-input checkbox-required"
                                type="checkbox" v-model="competencesWeights"></div>
                    </div>
                </div>

                <div class="row container-fluid" v-if="!byGroups">
                    <div class="row div-competences" v-for="(competence, index_competence) in competencesWithoutGroups"
                        :key="competence.id">
                        <div class="row">
                            <div class="col-sm-auto">
                                <input :id="'competence-' + index_competence" :ref="'competence-' + index_competence"
                                    class="form-check-input margin-top-45" type="checkbox" v-model="competence.required"
                                    @change="changedInputCompetenceSelected($event, competence)">
                            </div>
                            <div class="col-11 padding-0" v-if="competencesWeights && competence.required === true">
                                <div class="row">
                                    <div class="col-3">
                                        <p class="title-groups margin-top-3">Competence {{ index_competence + 1 }}</p>
                                    </div>
                                    <div class="col-8">
                                        <div class="row">
                                            <div class="col-6">
                                                <p class="float-left margin-top-3">{{ competence.name }}</p>
                                            </div>
                                            <div class="col-2">
                                                <p class="p-weight-evaluation-competence">Weight</p>
                                            </div>
                                            <div class="col-3">
                                                <b-input class="input-evaluator-weight" type="number" min="0" max="100"
                                                    v-model="competence.weight"></b-input>
                                            </div>
                                            <div class="col-1">
                                                <p class="p-percentage-competence">%</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-1">
                                        <button class="btn btn-secondary margin-top-15" type="button"
                                            v-b-toggle="'toggleC-' + index_competence.toString()"
                                            @click="changeShowCompetenceWithoutGroupState(index_competence)">
                                            <font-awesome-icon style="display: none;"
                                                v-bind:id="'iconShowC-' + index_competence.toString()"
                                                class="trash-icon" v-b-tooltip.hover title="Expand"
                                                icon="chevron-down" />
                                            <font-awesome-icon style="display: block;"
                                                v-bind:id="'iconNotShowC-' + index_competence.toString()"
                                                class="trash-icon" v-b-tooltip.hover title="Contract"
                                                icon="chevron-up" />
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="col-11 padding-0" v-else>
                                <div class="row">
                                    <div class="col-3">
                                        <p class="title-groups margin-top-3">Competence {{ index_competence + 1 }}</p>
                                    </div>
                                    <div class="col-8">
                                        <p class="float-left margin-top-1-5">{{ competence.name }}</p>
                                    </div>
                                    <div class="col-1">
                                        <button class="btn btn-secondary margin-top-15" type="button"
                                            v-b-toggle="'toggleC-' + index_competence.toString()"
                                            @click="changeShowCompetenceWithoutGroupState(index_competence)">
                                            <font-awesome-icon style="display: none;"
                                                v-bind:id="'iconShowC-' + index_competence.toString()"
                                                class="trash-icon" v-b-tooltip.hover title="Expand"
                                                icon="chevron-down" />
                                            <font-awesome-icon style="display: block;"
                                                v-bind:id="'iconNotShowC-' + index_competence.toString()"
                                                class="trash-icon" v-b-tooltip.hover title="Contract"
                                                icon="chevron-up" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <b-collapse visible v-bind:id="'toggleC-' + index_competence.toString()">
                            <div class="row">
                                <div class="col-10">
                                    <div class="row align-items-center">
                                        <p class="title-min-level">Min Level Required</p>
                                    </div>
                                    <div class="row">
                                        <b-form-group :name="'competence-' + index_competence.toString()"
                                            class="form-group-margin" v-slot="{ ariaDescribedby }">
                                            <template v-for="(level, index_level) in competence.levels">
                                                <b-form-radio class="radio-button-class test-options-display" :key="level.id" v-if="index_level !== 0"
                                                    :id="'level-' + index_competence + '-' + index_level + '-' + level.id_competence"
                                                    :ref="'level-' + index_competence + '-' + index_level + '-' + level.id_competence"
                                                    v-model="competence.minLevel" :aria-describedby="ariaDescribedby"
                                                    :name="'competence-' + index_competence.toString()"
                                                    :value="parseInt(index_level)" :disabled="!competence.required">
                                                    <p v-if="index_level !== 0"> Level
                                                        {{ index_level }}:&nbsp;&nbsp;&nbsp;{{ level.name }}
                                                    </p>
                                                </b-form-radio>
                                            </template>
                                        </b-form-group>
                                    </div>
                                </div>
                            </div>
                        </b-collapse>
                        <hr v-if="index_competence != (competencesWithoutGroups.length - 1)">
                    </div>
                </div>

                <div class="row container-fluid" v-if="byGroups">
                    <div class="row div-competences">
                        <div class="row" v-for="(group, index) in competencesByGroups"
                            :key="group.id + '-' + group.required.toString()">
                            <div class="row">
                                <div class="col-sm-auto">
                                    <input id="groupsRequiredCheckbox" class="form-check-input margin-top-45"
                                        type="checkbox" v-model="group.required"
                                        @change="changedInputGroupSelected($event, group)">
                                </div>

                                <div class="col-10 padding-0" v-if="groupsWeights && group.required === true">
                                    <div class="row">
                                        <div class="col-2">
                                            <p class="title-groups margin-top-4">Group {{ index + 1 }}</p>
                                        </div>
                                        <div class="col-9">
                                            <div class="row">
                                                <div class="col-7">
                                                    <p class="float-left margin-top-2">{{ group.name }}</p>
                                                </div>
                                                <div class="col-2">
                                                    <p class="p-weight-evaluation-group">Weight</p>
                                                </div>
                                                <div class="col-2">
                                                    <b-input class="input-evaluator-weight form-control" type="number"
                                                        min="0" max="100" v-model="group.weight"></b-input>
                                                </div>
                                                <div class="col-1">
                                                    <p class="p-percentage-group">%</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-1">
                                            <button class="btn btn-secondary margin-top-10" type="button"
                                                v-b-toggle="'toggleG-' + index.toString()"
                                                @click="changeShowGroupState(index)">
                                                <font-awesome-icon style="display: none;"
                                                    v-bind:id="'iconShowG-' + index.toString()" class="trash-icon"
                                                    v-b-tooltip.hover title="Expand" icon="chevron-down" />
                                                <font-awesome-icon style="display: block;"
                                                    v-bind:id="'iconNotShowG-' + index.toString()" class="trash-icon"
                                                    v-b-tooltip.hover title="Contract" icon="chevron-up" />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-10 padding-0" v-else>
                                    <div class="row">
                                        <div class="col-2">
                                            <p class="title-groups margin-top-4">Group {{ index + 1 }}</p>
                                        </div>
                                        <div class="col-9">
                                            <p class="float-left margin-top-1">{{ group.name }}</p>
                                        </div>
                                        <div class="col-1">
                                            <button class="btn btn-secondary margin-top-10" type="button"
                                                v-b-toggle="'toggleG-' + index.toString()"
                                                @click="changeShowGroupState(index)">
                                                <font-awesome-icon style="display: none;"
                                                    v-bind:id="'iconShowG-' + index.toString()" class="trash-icon"
                                                    v-b-tooltip.hover title="Expand" icon="chevron-down" />
                                                <font-awesome-icon style="display: block;"
                                                    v-bind:id="'iconNotShowG-' + index.toString()" class="trash-icon"
                                                    v-b-tooltip.hover title="Contract" icon="chevron-up" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <b-collapse visible v-bind:id="'toggleG-' + index.toString()">
                                <div class="row">
                                    <div class="col-10">
                                        <div class="row align-items-center">
                                            <p class="title-competences-evaluation">Competences</p>
                                        </div>
                                        <div class="row margin-left-10"
                                            v-for="(competence, index_competence) in group.competences"
                                            :key="competence.id">
                                            <div class="row">
                                                <div class="col-sm-auto">
                                                    <input
                                                        :id="'competence-' + index_competence + '-' + competence.id_group"
                                                        :ref="'competence-' + index_competence + '-' + competence.id_group"
                                                        class="form-check-input margin-top-45" type="checkbox"
                                                        v-model="competence.required" :disabled="!group.required"
                                                        @change="changedInputCompetenceSelected($event, competence)">
                                                </div>
                                                <div class="col-11 padding-0"
                                                    v-if="competencesWeights && competence.required === true">
                                                    <div class="row">
                                                        <div class="col-3">
                                                            <p class="title-groups margin-top-3">Competence
                                                                {{ index_competence + 1 }}
                                                            </p>
                                                        </div>
                                                        <div class="col-8">
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <p class="float-left margin-top-3">
                                                                        {{ competence.name }}</p>
                                                                </div>
                                                                <div class="col-2">
                                                                    <p class="p-weight-evaluation-competence">Weight</p>
                                                                </div>
                                                                <div class="col-3">
                                                                    <b-input class="input-evaluator-weight"
                                                                        type="number" min="0" max="100"
                                                                        v-model="competence.weight"></b-input>
                                                                </div>
                                                                <div class="col-1">
                                                                    <p class="p-percentage-competence">%</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-1">
                                                            <button class="btn btn-secondary margin-top-15"
                                                                type="button"
                                                                v-b-toggle="'toggleC-' + index.toString() + '-' + index_competence.toString()"
                                                                @click="changeShowCompetenceFromGroupState(index, index_competence)">
                                                                <font-awesome-icon style="display: none;"
                                                                    v-bind:id="'iconShowC-' + index.toString() + '-' + index_competence.toString()"
                                                                    class="trash-icon" v-b-tooltip.hover title="Expand"
                                                                    icon="chevron-down" />
                                                                <font-awesome-icon style="display: block;"
                                                                    v-bind:id="'iconNotShowC-' + index.toString() + '-' + index_competence.toString()"
                                                                    class="trash-icon" v-b-tooltip.hover
                                                                    title="Contract" icon="chevron-up" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-11 padding-0" v-else>
                                                    <div class="row">
                                                        <div class="col-3">
                                                            <p class="title-groups margin-top-3">Competence
                                                                {{ index_competence + 1 }}
                                                            </p>
                                                        </div>
                                                        <div class="col-8">
                                                            <p class="float-left margin-top-1-5">{{ competence.name }}
                                                            </p>
                                                        </div>
                                                        <div class="col-1">
                                                            <button class="btn btn-secondary margin-top-15"
                                                                type="button"
                                                                v-b-toggle="'toggleC-' + index.toString() + '-' + index_competence.toString()"
                                                                @click="changeShowCompetenceFromGroupState(index, index_competence)">
                                                                <font-awesome-icon style="display: none;"
                                                                    v-bind:id="'iconShowC-' + index.toString() + '-' + index_competence.toString()"
                                                                    class="trash-icon" v-b-tooltip.hover title="Expand"
                                                                    icon="chevron-down" />
                                                                <font-awesome-icon style="display: block;"
                                                                    v-bind:id="'iconNotShowC-' + index.toString() + '-' + index_competence.toString()"
                                                                    class="trash-icon" v-b-tooltip.hover
                                                                    title="Contract" icon="chevron-up" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <b-collapse visible
                                                v-bind:id="'toggleC-' + index.toString() + '-' + index_competence.toString()">
                                                <div class="row">
                                                    <div class="col-10">
                                                        <div class="row align-items-center">
                                                            <p class="title-min-level">Min Level Required</p>
                                                        </div>
                                                        <div class="row">
                                                            <b-form-group
                                                                :name="'competence-' + index.toString() + '-' + index_competence.toString()"
                                                                class="form-group-margin" v-slot="{ ariaDescribedby }">
                                                                <template v-for="(level, index_level) in competence.levels">
                                                                    <b-form-radio class="radio-button-class test-options-display" :key="level.id" v-if="index_level !== 0"
                                                                        :id="'level-' + index_competence + '-' + index_level + '-' + level.id_competence"
                                                                        :ref="'level-' + index_competence + '-' + index_level + '-' + level.id_competence"
                                                                        v-model="competence.minLevel" :aria-describedby="ariaDescribedby"
                                                                        :name="'competence-' + index_competence.toString()"
                                                                        :value="parseInt(index_level)" :disabled="!competence.required">
                                                                        <p v-if="index_level !== 0"> Level
                                                                            {{ index_level }}:&nbsp;&nbsp;&nbsp;{{ level.name }}
                                                                        </p>
                                                                    </b-form-radio>
                                                                </template>
                                                            </b-form-group>
                                                        </div>
                                                    </div>
                                                </div>
                                            </b-collapse>
                                        </div>
                                    </div>
                                </div>
                            </b-collapse>
                            <hr v-if="index != (competencesByGroups.length - 1)">
                        </div>
                    </div>
                </div>
            </div>

            <div id="message" v-if="message" class="alert"
                :class="successful ? 'message alert alert-success' : 'message alert alert-danger'"
                style="margin-top: 0% !important; margin-bottom: 0% !important;">
                {{ message }} <b-button @click="close" class="cross-button"><font-awesome-icon class="cross"
                        icon="times" />
                </b-button>
            </div>

            <div class="form-group col-lg-4 align-item-center">
                <div class="row">
                    <div class="col-sm-6">
                        <input class="btn btn-primary form-submit" type="submit" value="Create BEI/360 Evaluation">
                    </div>
                    <div class="col-sm-6">
                        <button class="btn btn-secondary btn-cancel" type="button"
                            @click="backToSoftskillsConfiguration()"><font-awesome-icon icon="ban" /> Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import AuthDataService from "@/services/AuthDataService";
import UserDataService from '@/services/UserDataService';
import SoftskillsDataService from '@/services/SoftskillsDataService';
import ProfileAnalysisDataService from '@/services/ProfileAnalysisDataService';
import Multiselect from 'vue-multiselect'

export default {
    name: 'CreateEvaluationComponent',
    data() {
        return {
            evaluation: {
                name: "",
                description: "",
            },
            submitted: false,
            roles: [],
            role: 0,
            skillsMap: [],
            competencesWithoutGroups: [],
            competencesByGroups: [],
            allUsersInCompany: [],
            allUsersInArea: [],
            cvsUploadedByAdmins: [],
            users: [],
            hrtechnicians: [],
            responsables: [],
            pairs: [],
            colaborators: [],
            hrtechniciansToShow: [],
            responsablesToShow: [],
            pairsToShow: [],
            colaboratorsToShow: [],
            hrtechniciansToAdd: [],
            responsablesToAdd: [],
            pairsToAdd: [],
            colaboratorsToAdd: [],
            user: 0,
            userObject: {},
            selectedHRTechnicians: [],
            selectedResponsables: [],
            selectedPairs: [],
            selectedColaborators: [],
            hrtechnicianWeight: 0,
            responsablesWeight: 0,
            pairsWeight: 0,
            colaboratorsWeight: 0,
            groupsWeights: false,
            competencesWeights: false,
            successful: false,
            byGroups: false,
            selectedItems: [],
            message: "",
            perPage: 10,
            currentPage: 1,
            filter: null,
            pageOptions: this.$paginationOptions,
        }
    },
    watch: {
        selectedItems(val) {
            if (this.selectedItems.length == 0) {
                return;
            }

            this.hrtechnicians = []
            this.hrtechniciansToShow = []
            this.responsables = []
            this.responsablesToShow = []
            this.pairs = []
            this.pairsToShow = []
            this.colaborators = []
            this.colaboratorsToShow = []
            this.userObject = {}

            this.allUsersInArea.forEach(user => {
                if (user.id === val) {
                    this.userObject = user
                }
            })

            const userSelected = val[0];

            this.pairs = this.allUsersInArea

            this.pairs.forEach(user => {
                this.pairsToShow.push({ label: user.name + " " + user.surname + " (" + user.role.name.toUpperCase() + ")", value: user.name + "-" + user.id })
            })

            this.allUsersInArea.forEach(user => {
                if (user.id !== userSelected && Object.keys(this.userObject).length !== 0) {
                    if (user.role.id === 9) {
                        this.hrtechnicians.push(user) // Talent manager
                        this.hrtechniciansToShow.push({ label: user.name + " " + user.surname + " (" + user.role.name.toUpperCase() + ")", value: user.name + "-" + user.id })
                    } else if (user.role.id === 1 || user.role.id === 8) {
                        this.responsables.push(user) // Admin, manager
                        this.responsablesToShow.push({ label: user.name + " " + user.surname + " (" + user.role.name.toUpperCase() + ")", value: user.name + "-" + user.id })
                    }

                    if (user.role.id !== 3 && user.role.id !== 9) {
                        this.colaborators.push(user)
                        this.colaboratorsToShow.push({ label: user.name + " " + user.surname + " (" + user.role.name.toUpperCase() + ")", value: user.name + "-" + user.id })
                    }
                } else if (user.id !== val && Object.keys(this.userObject).length === 0) {
                    if (user.role.id === 9) {
                        this.hrtechnicians.push(user) // Talent manager
                        this.hrtechniciansToShow.push({ label: user.name + " " + user.surname + " (" + user.role.name.toUpperCase() + ")", value: user.name + "-" + user.id })
                    } else if (user.role.id === 1 || user.role.id === 8) {
                        this.responsables.push(user) // Admin, manager
                        this.responsablesToShow.push({ label: user.name + " " + user.surname + " (" + user.role.name.toUpperCase() + ")", value: user.name + "-" + user.id })
                    }

                    const rolesNotAllowed = [3, 9] //this.role]

                    if (!rolesNotAllowed.includes(user.role.id)) {
                        this.colaborators.push(user)
                        this.colaboratorsToShow.push({ label: user.name + " " + user.surname + " (" + user.role.name.toUpperCase() + ")", value: user.name + "-" + user.id })
                    }
                }
            })
        },
        responsablesToAdd(val) {
            this.colaboratorsToShow = []

            this.colaborators.forEach(colaborator => {
                let exists = false

                this.responsablesToAdd.forEach(responsable => {

                    if (colaborator.id === responsable) exists = true
                })

                this.pairsToAdd.forEach(pair => {

                    if (colaborator.id === pair) exists = true
                })

                if (exists === false) this.colaboratorsToShow.push({ label: colaborator.name + " " + colaborator.surname + " (" + colaborator.role.name.toUpperCase() + ")", value: colaborator.name + "-" + colaborator.id })
            })

            this.responsablesToAdd.forEach(responsable => {
                this.selectedColaborators.forEach(colaborator => {
                    var valueSplit = colaborator.value.split('-')

                    if (responsable === parseInt(valueSplit[1])) {
                        const indexColaboratorsToAdd = this.colaboratorsToAdd.indexOf(parseInt(valueSplit[1]));
                        if (indexColaboratorsToAdd !== -1) this.colaboratorsToAdd.splice(indexColaboratorsToAdd, 1)

                        const indexSelectedColaborators = this.selectedColaborators.map(object => object.value).indexOf(colaborator.value);
                        this.selectedColaborators.splice(indexSelectedColaborators, 1)
                    }
                })
            })
        },
        pairsToAdd(val) {
            this.colaboratorsToShow = []

            this.colaborators.forEach(colaborator => {
                let exists = false

                this.responsablesToAdd.forEach(responsable => {

                    if (colaborator.id === responsable) exists = true
                })

                this.pairsToAdd.forEach(pair => {

                    if (colaborator.id === pair) exists = true
                })

                if (exists === false) this.colaboratorsToShow.push({ label: colaborator.name + " " + colaborator.surname + " (" + colaborator.role.name.toUpperCase() + ")", value: colaborator.name + "-" + colaborator.id })
            })

            this.pairsToAdd.forEach(pair => {
                this.selectedColaborators.forEach(colaborator => {
                    var valueSplit = colaborator.value.split('-')

                    if (pair === parseInt(valueSplit[1])) {
                        const indexColaboratorsToAdd = this.colaboratorsToAdd.indexOf(parseInt(valueSplit[1]));
                        if (indexColaboratorsToAdd !== -1) this.colaboratorsToAdd.splice(indexColaboratorsToAdd, 1)

                        const indexSelectedColaborators = this.selectedColaborators.map(object => object.value).indexOf(colaborator.value);
                        this.selectedColaborators.splice(indexSelectedColaborators, 1)
                    }
                })
            })
        }
    },
    components: {
        Multiselect
    },
    computed: {
        rows() {
            return this.allUsersInArea.length
        },
        headers() {
            return [
                { key: 'username', sortable: true, label: 'Username' },
                { key: 'name', sortable: true, label: 'Name' },
                { key: 'surname', sortable: true, label: 'Surname' },
                { key: 'email', sortable: true, label: 'Email' },
                { key: 'role', sortable: true, label: "Role" }
            ];
        },
    },
    mounted() {
        this.loadRoles();
        this.getAllUsersInCompany();
        this.getDirectorySkillsByCompany();
    },
    methods: {
        getAllUsersInCompany() {
            UserDataService.findByCompany(this.$store.state.auth.user.id_company).then(
                response => {
                    if (200 == response.status) {
                        this.allUsersInCompany = response.data
                        this.getAllUsersInArea();
                    } else {
                        this.message = "Error while retrieving users from company";
                        this.successfull = false;
                    }
                },
                error => {
                    console.error((error.response && error.response.data) ||
                        error.message ||
                        error.toString())

                    this.message = "There was an error while retrieving users from company";
                    this.successfull = false;

                    if (error.response.status === 300) {
                        console.error('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );
        },
        getAllUsersInArea() {
            UserDataService.findByArea(this.$store.state.auth.user.active_areas[this.$session.get("tabID")]).then(
                response => {
                    if (200 == response.status) {
                        this.allUsersInArea = response.data;
                        this.allUsersInArea = this.allUsersInArea.filter(field => field.role.id != undefined)

                        this.getCVsByArea();
                    } else {
                        this.message = "Error while retrieving users from company";
                        this.successfull = false;
                    }
                },
                error => {
                    console.error((error.response && error.response.data) ||
                        error.message ||
                        error.toString())

                    this.message = "There was an error while retrieving users from company";
                    this.successfull = false;

                    if (error.response.status === 300) {
                        console.error('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );
        },
        getCVsByArea() {
            ProfileAnalysisDataService.findCVsByArea(this.$store.state.auth.user.active_areas[this.$session.get("tabID")]).then(
                response => {
                    if (200 == response.status) {
                        response.data.cvs.forEach(cv => {
                            this.allUsersInArea.forEach(user => {
                                if (cv.id_user === user.id && user.role.id !== 1) {
                                    this.cvsUploadedByAdmins.push({
                                        id: cv.id,
                                        name: cv.name,
                                        email: cv.email,
                                        role: 'candidate'
                                    })
                                }
                            })
                        })

                        Array.prototype.push.apply(this.users, this.allUsersInArea);
                    } else {
                        this.message = "Error while retrieving cvs from area";
                        this.successfull = false;
                    }
                },
                error => {
                    console.error((error.response && error.response.data) ||
                        error.message ||
                        error.toString())

                    this.message = "There was an error while retrieving cvs from area";
                    this.successfull = false;

                    if (error.response.status === 300) {
                        console.error('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );
        },
        getDirectorySkillsByCompany() {
            SoftskillsDataService.findCompanyCompetencesDirectory(this.$store.state.auth.user.id_company).then(
                response => {
                    if (200 == response.status) {
                        this.skillsMap = response.data;

                        if (this.skillsMap.length > 0) {
                            if (this.skillsMap[0].type === "group") {
                                this.byGroups = true

                                this.skillsMap.forEach(group => {
                                    group.competences = group.competences.filter(function (item) {
                                        return item.is_active === true
                                    })
                                })

                                this.skillsMap.forEach(group => {
                                    group.competences.forEach(competence => {
                                        competence.levels = competence.levels.filter(function (item) {
                                            return item.is_active === true
                                        })
                                    })
                                })

                                this.competencesByGroups = this.skillsMap

                                this.competencesByGroups.forEach(group => {
                                    group.weight = 0
                                    group.required = false

                                    group.competences.forEach(competence => {
                                        competence.weight = 0
                                        competence.required = false
                                        competence.minLevel = 0

                                        competence.levels.forEach(level => {
                                            level.required = false
                                        })
                                    })
                                })
                            } else if (this.skillsMap[0].type === 'competence') {
                                this.byGroups = false

                                this.skillsMap.forEach(competence => {
                                    competence.levels = competence.levels.filter(function (item) {
                                        return item.is_active === true
                                    })
                                })

                                this.competencesWithoutGroups = this.skillsMap

                                this.competencesWithoutGroups.forEach(competence => {
                                    competence.weight = 0
                                    competence.required = false
                                    competence.minLevel = 0

                                    competence.levels.forEach(level => {
                                        level.required = false
                                    })
                                })
                            }
                        } else {
                            this.successful = false
                            this.message = 'No competences found for this company'
                        }
                    } else {
                        this.message = 'Error while retrieving the competence directory available for the company'
                        this.successful = false
                    }
                },
                error => {
                    console.error((error.response && error.response.data) ||
                        error.message ||
                        error.toString())

                    this.successful = false
                    this.message = 'There was an error while finding company competence directory'

                    if (error.response.status === 300) {
                        console.error('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );
        },
        showSkillsMap() {
            if (this.pairsToAdd.length != 0 || this.responsablesToAdd.length != 0 || this.hrtechniciansToAdd.length != 0 || this.colaboratorsToAdd != 0) {
                return true;
            }
            return false;
        },
        changeShowGroupState(index) {
            if (document.getElementById('iconShowG-' + index.toString()).style.display == "block") {
                document.getElementById('iconShowG-' + index.toString()).style.display = "none"
                document.getElementById('iconNotShowG-' + index.toString()).style.display = "block"
            } else {
                document.getElementById('iconShowG-' + index.toString()).style.display = "block"
                document.getElementById('iconNotShowG-' + index.toString()).style.display = "none"
            }
        },
        changeShowCompetenceState(index) {
            if (document.getElementById('iconShowC-' + index.toString()).style.display == "block") {
                document.getElementById('iconShowC-' + index.toString()).style.display = "none"
                document.getElementById('iconNotShowC-' + index.toString()).style.display = "block"
            } else {
                document.getElementById('iconShowC-' + index.toString()).style.display = "block"
                document.getElementById('iconNotShowC-' + index.toString()).style.display = "none"
            }
        },
        changeShowCompetenceFromGroupState(index, index_competence) {
            if (document.getElementById('iconShowC-' + index.toString() + '-' + index_competence.toString()).style.display == "block") {
                document.getElementById('iconShowC-' + index.toString() + '-' + index_competence.toString()).style.display = "none"
                document.getElementById('iconNotShowC-' + index.toString() + '-' + index_competence.toString()).style.display = "block"
            } else {
                document.getElementById('iconShowC-' + index.toString() + '-' + index_competence.toString()).style.display = "block"
                document.getElementById('iconNotShowC-' + index.toString() + '-' + index_competence.toString()).style.display = "none"
            }
        },
        changeShowCompetenceWithoutGroupState(index_competence) {
            if (document.getElementById('iconShowC-' + index_competence.toString()).style.display == "block") {
                document.getElementById('iconShowC-' + index_competence.toString()).style.display = "none"
                document.getElementById('iconNotShowC-' + index_competence.toString()).style.display = "block"
            } else {
                document.getElementById('iconShowC-' + index_competence.toString()).style.display = "block"
                document.getElementById('iconNotShowC-' + index_competence.toString()).style.display = "none"
            }
        },
        selectOptionHRTechnicians(option) {
            var object = {}

            var parsedOption = JSON.parse(JSON.stringify(option))

            object.label = parsedOption.label
            object.value = parsedOption.value
            var valueSplit = parsedOption.value.split('-')

            this.hrtechniciansToAdd.push(parseInt(valueSplit[1]))
        },
        removeOptionHRTechnicians(option) {
            var parsedOption = JSON.parse(JSON.stringify(option))
            var valueSplit = parsedOption.value.split('-')
            const index = this.hrtechniciansToAdd.indexOf(parseInt(valueSplit[1]));
            this.hrtechniciansToAdd.splice(index, 1)
        },
        selectOptionResponsables(option) {
            var object = {}
            var parsedOption = JSON.parse(JSON.stringify(option))
            object.label = parsedOption.label
            object.value = parsedOption.value
            var valueSplit = parsedOption.value.split('-')
            this.responsablesToAdd.push(parseInt(valueSplit[1]))
        },
        removeOptionResponsables(option) {
            var parsedOption = JSON.parse(JSON.stringify(option))
            var valueSplit = parsedOption.value.split('-')
            const index = this.responsablesToAdd.indexOf(parseInt(valueSplit[1]));
            this.responsablesToAdd.splice(index, 1)
        },
        selectOptionPairs(option) {
            var object = {}
            var parsedOption = JSON.parse(JSON.stringify(option))
            object.label = parsedOption.label
            object.value = parsedOption.value
            var valueSplit = parsedOption.value.split('-')
            this.pairsToAdd.push(parseInt(valueSplit[1]))
        },
        removeOptionPairs(option) {
            var parsedOption = JSON.parse(JSON.stringify(option))
            var valueSplit = parsedOption.value.split('-')
            const index = this.pairsToAdd.indexOf(parseInt(valueSplit[1]));
            this.pairsToAdd.splice(index, 1)
        },
        selectOptionColaborators(option) {
            var object = {}
            var parsedOption = JSON.parse(JSON.stringify(option))
            object.label = parsedOption.label
            object.value = parsedOption.value
            var valueSplit = parsedOption.value.split('-')
            this.colaboratorsToAdd.push(parseInt(valueSplit[1]))
        },
        removeOptionColaborators(option) {
            var parsedOption = JSON.parse(JSON.stringify(option))
            var valueSplit = parsedOption.value.split('-')
            const index = this.colaboratorsToAdd.indexOf(parseInt(valueSplit[1]));
            this.colaboratorsToAdd.splice(index, 1)
        },
        backToSoftskillsConfiguration() {
            this.$router.push({ path: 'softskills-configuration' })
        },
        loadRoles() {
            AuthDataService.registerBoard().then(
                response => {
                    if (200 == response.status) {
                        this.roles = response.data;
                    } else {
                        this.message = 'Error while retrieving the roles available in the platform'
                        this.successful = false
                    }
                },
                error => {
                    console.error((error.response && error.response.data) ||
                        error.message ||
                        error.toString())

                    this.message = 'There was an error while retrieving the roles available in the platform'
                    this.successful = false

                    if (error.response.status === 300) {
                        console.error('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );
        },
        handleCreate() {
            this.successful = true;

            this.$validator.validate().then(isValid => {
                if (!isValid) {
                    this.successful = false;
                    this.message = "Please, fill all the fields correctlly";
                    return;
                }

                if (this.selectedItems.length > 0 && !this.showSkillsMap()) {
                    this.successful = false
                    this.message = 'You must assign at least one evaluator';
                    return;
                }

                if (this.selectedItems.length == 0 && this.showSkillsMap()) {
                    this.successful = false
                    this.message = 'You must assign at least one user to evaluate';
                    return;
                }

                let totalWeights = 0

                if (this.hrtechniciansToAdd.length !== 0) totalWeights += parseFloat(this.hrtechnicianWeight)
                if (this.responsablesToAdd.length !== 0) totalWeights += parseFloat(this.responsablesWeight)
                if (this.pairsToAdd.length !== 0) totalWeights += parseFloat(this.pairsWeight)
                if (this.colaboratorsToAdd.length !== 0) totalWeights += parseFloat(this.colaboratorsWeight)

                if (parseFloat(totalWeights) === 100.0 || totalWeights === 0) {
                    if (this.byGroups) {
                        let groupsRequired = false

                        this.competencesByGroups.forEach(group => {
                            if (group.required) {
                                let competencesRequired = false

                                groupsRequired = true

                                group.competences.forEach(competence => {
                                    if (competence.required) competencesRequired = true
                                })

                                if (!competencesRequired) {
                                    this.successful = false
                                    this.message = 'You must select at least one competence per selected group'
                                }
                            }
                        })

                        if (groupsRequired) {
                            if (this.groupsWeights) {
                                let totalGroupsWeights = 0
                                this.competencesByGroups.forEach(group => {
                                    if (group.required) totalGroupsWeights += parseFloat(group.weight)
                                })

                                if (totalGroupsWeights === 100) {
                                    if (this.competencesWeights) {
                                        this.competencesByGroups.forEach(group => {
                                            if (group.required) {
                                                let totalCompetencesWeightsByGroups = 0

                                                group.competences.forEach(competence => {
                                                    totalCompetencesWeightsByGroups += parseFloat(competence.weight)
                                                })

                                                if (totalCompetencesWeightsByGroups !== 100) {
                                                    this.successful = false
                                                    this.message = 'The sum of the weights of the competences of some group is not 100% (currently: ' + totalCompetencesWeightsByGroups + '%)'
                                                }
                                            }
                                        })

                                        if (this.successful) this.createEvaluations()
                                    } else {
                                        if (this.successful) this.createEvaluations()
                                    }
                                } else {
                                    this.successful = false
                                    this.message = 'The sum of the weights of the groups is not 100% (currently: ' + totalGroupsWeights + '%)'
                                }
                            } else {
                                if (this.competencesWeights) {
                                    this.competencesByGroups.forEach(group => {
                                        if (group.required) {
                                            let totalCompetencesWeightsByGroups = 0

                                            group.competences.forEach(competence => {
                                                totalCompetencesWeightsByGroups += parseFloat(competence.weight)
                                            })

                                            if (totalCompetencesWeightsByGroups === 100) {
                                                console.log('')
                                            } else {
                                                this.successful = false
                                                this.message = 'The sum of the weights of the competences of some group is not 100% (currently: ' + totalCompetencesWeightsByGroups + '%)'
                                            }
                                        }
                                    })

                                    if (this.successful) this.createEvaluations()
                                } else {
                                    if (this.successful) this.createEvaluations()
                                }
                            }

                        } else {
                            this.successful = false
                            this.message = 'You must select at least one group'
                        }
                    } else {
                        let competencesRequired = false

                        this.competencesWithoutGroups.forEach(competence => {
                            if (competence.required) {
                                competencesRequired = true
                            }
                        })

                        if (competencesRequired) {
                            if (this.competencesWeights) {
                                let totalCompetencesWeightsWithoutGroups = 0

                                this.competencesWithoutGroups.forEach(competence => {
                                    if (competence.required) {
                                        totalCompetencesWeightsWithoutGroups += parseFloat(competence.weight)
                                    }
                                })

                                if (totalCompetencesWeightsWithoutGroups === 100) {
                                    if (this.successful) this.createEvaluations()
                                } else {
                                    this.successful = false
                                    this.message = 'The sum of the weights of the competences is not 100% (currently: ' + totalCompetencesWeightsWithoutGroups + '%)'
                                }

                            } else {
                                if (this.successful) this.createEvaluations()
                            }
                        } else {
                            this.successful = false
                            this.message = 'You must select at least one competence'
                        }
                    }
                } else {
                    this.successful = false
                    this.message = 'The sum of the weights of the evaluators is not 100% (currently: ' + totalWeights + '%)'
                }
            });
        },
        createEvaluations() {
            let isReusable = false

            if (this.selectedItems.length == 0) isReusable = true
            
            if (this.byGroups) {
                let evaluation = {
                    id_area: this.$store.state.auth.user.active_areas[this.$session.get("tabID")],
                    name: this.evaluation.name,
                    description: this.evaluation.description,
                    is_reusable: isReusable
                }

                this.createEvaluationWithGroups(evaluation);
            } else {
                let evaluation = {
                    id_area: this.$store.state.auth.user.active_areas[this.$session.get("tabID")],
                    name: this.evaluation.name,
                    description: this.evaluation.description,
                    is_reusable: isReusable
                }

                this.createEvaluationWithoutGroups(evaluation);
            }
        },
        createEvaluationWithGroups(evaluation) {
            SoftskillsDataService.createEvaluation(evaluation).then(
                response => {
                    if (200 == response.status) {
                        let evaluationCreated = response.data

                        // Añadir usuarios
                        let weightsToAdd = [];
                        let numGroups = 0;

                        this.competencesByGroups.forEach(group => {
                            if (group.required) {
                                numGroups += 1
                            }
                        })

                        this.competencesByGroups.forEach(group => {
                            if (group.required) {
                                let groupWeightToAdd = {}

                                if (this.groupsWeights) {
                                    groupWeightToAdd = {
                                        id_evaluation: evaluationCreated.id,
                                        id_soft_skill: group.id,
                                        weight: parseFloat(group.weight),
                                        min_level: null
                                    }
                                } else {
                                    groupWeightToAdd = {
                                        id_evaluation: evaluationCreated.id,
                                        id_soft_skill: group.id,
                                        weight: parseFloat(100 / numGroups),
                                        min_level: null
                                    }
                                }

                                weightsToAdd.push(groupWeightToAdd)

                                
                                let numCompetences = 0

                                group.competences.forEach(competence => {
                                    if (competence.required) {
                                        numCompetences += 1
                                    }
                                })

                                group.competences.forEach(competence => {

                                    if (competence.required) {
                                        let competenceWeightToAdd = {}

                                        if (this.competencesWeights) {
                                            competenceWeightToAdd = {
                                                id_evaluation: evaluationCreated.id,
                                                id_soft_skill: competence.id,
                                                weight: parseFloat(competence.weight),
                                                min_level: parseInt(competence.minLevel + 1)
                                            }
                                        } else {
                                            competenceWeightToAdd = {
                                                id_evaluation: evaluationCreated.id,
                                                id_soft_skill: competence.id,
                                                weight: parseFloat(100 / numCompetences),
                                                min_level: parseInt(competence.minLevel + 1)
                                            }
                                        }

                                        weightsToAdd.push(competenceWeightToAdd)
                                    }
                                })
                            }
                        })

                        this.addWeightsToEvaluation(weightsToAdd, evaluationCreated);
                    } else {
                        this.successful = false
                        this.message = 'There was an error while creating the evaluation'
                    }
                },
                error => {
                    console.error((error.response && error.response.data) ||
                        error.message ||
                        error.toString())

                    this.message = 'There was an error while creating the evaluation'
                    this.successful = false

                    if (error.response.status === 300) {
                        console.error('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );
        },
        addEvaluators(evaluationCreated) {
            let evaluatorsToAdd = [];

            this.hrtechniciansToAdd.forEach(hrtechnician => {
                let evaluator = {
                    id_evaluator: hrtechnician,
                    role: 'hr_technician',
                    id_evaluation: evaluationCreated.id,
                    weight: parseFloat(this.hrtechnicianWeight / this.hrtechniciansToAdd.length),
                    done: 0
                }

                evaluatorsToAdd.push(evaluator)
            })

            this.responsablesToAdd.forEach(responsable => {
                let evaluator = {
                    id_evaluator: responsable,
                    role: 'responsible',
                    id_evaluation: evaluationCreated.id,
                    weight: parseFloat(this.responsablesWeight / this.responsablesToAdd.length),
                    done: 0
                }

                evaluatorsToAdd.push(evaluator)
            })

            this.pairsToAdd.forEach(pair => {
                let evaluator = {
                    id_evaluator: pair,
                    role: 'pair',
                    id_evaluation: evaluationCreated.id,
                    weight: parseFloat(this.pairsWeight / this.pairsToAdd.length),
                    done: 0
                }

                evaluatorsToAdd.push(evaluator)
            })

            this.colaboratorsToAdd.forEach(colaborator => {
                let evaluator = {
                    id_evaluator: colaborator,
                    role: 'collaborator',
                    id_evaluation: evaluationCreated.id,
                    weight: parseFloat(this.colaboratorsWeight / this.colaboratorsToAdd.length),
                    done: 0
                }

                evaluatorsToAdd.push(evaluator)
            })

            return evaluatorsToAdd;
        },
        addEvaluatorsToEvaluationWithGroups(evaluatorsToAdd, evaluationCreated) {
            SoftskillsDataService.addEvaluatorsToEvaluation(evaluatorsToAdd, evaluationCreated.id).then(
                response => {
                    if (200 == response.status) {
                        this.$router.push({ name: "softskills-configuration" });
                    } else {
                        this.successful = false
                        this.message = 'Error while adding evaluators to the evaluation'
                    }
                },
                error => {
                    console.error((error.response && error.response.data) ||
                        error.message ||
                        error.toString())

                    this.successful = false
                    this.message = 'There was an error while adding evaluators to the evaluation'

                    if (error.response.status === 300) {
                        console.error('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    } else {
                        return [];
                    }
                }
            );
        },
        createEvaluationWithoutGroups(evaluation) {
            SoftskillsDataService.createEvaluation(evaluation).then(
                response => {
                    if (200 == response.status) {
                        let evaluationCreated = response.data

                        let weightsToAdd = []
                        let numCompetences = 0

                        this.competencesWithoutGroups.forEach(competence => {
                            if (competence.required) {
                                numCompetences += 1
                            }
                        })

                        this.competencesWithoutGroups.forEach(competence => {
                            if (competence.required) {
                                let competenceWeightToAdd = {}

                                if (this.competencesWeights) {
                                    competenceWeightToAdd = {
                                        id_evaluation: evaluationCreated.id,
                                        id_soft_skill: competence.id,
                                        weight: parseFloat(competence.weight),
                                        min_level: parseInt(competence.minLevel + 1)
                                    }
                                } else {
                                    competenceWeightToAdd = {
                                        id_evaluation: evaluationCreated.id,
                                        id_soft_skill: competence.id,
                                        weight: parseFloat(100 / numCompetences),
                                        min_level: parseInt(competence.minLevel + 1)
                                    }
                                }

                                weightsToAdd.push(competenceWeightToAdd)
                            }
                        })

                        this.addWeightsToEvaluation(weightsToAdd, evaluationCreated);
                    } else {
                        this.successful = false
                        this.message = 'Error while creating evaluation without groups'
                    }
                },
                error => {
                    console.error((error.response && error.response.data) ||
                        error.message ||
                        error.toString())

                    this.message = 'There was an error while creating evaluation without groups'
                    this.successful = false

                    if (error.response.status === 300) {
                        console.error('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );
        },
        addEvaluatorsToEvaluationWithoutGroups(evaluatorsToAdd, evaluationCreated) {
            SoftskillsDataService.addEvaluatorsToEvaluation(evaluatorsToAdd, evaluationCreated.id).then(
                response => {
                    if (200 == response.status) {
                        this.$router.push({ name: "softskills-configuration" });
                    } else {
                        this.message = 'Error while adding evaluators to the evaluation without groups'
                        this.successful = false
                    }
                },
                error => {
                    console.error((error.response && error.response.data) ||
                        error.message ||
                        error.toString())

                    this.message = 'There was an error while adding evaluators to the evaluation without groups'
                    this.successful = false

                    if (error.response.status === 300) {
                        console.error('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );
        },
        addWeightsToEvaluation(weightsToAdd, evaluationCreated) {
            SoftskillsDataService.addWeightsToEvaluation(weightsToAdd, evaluationCreated.id).then(
                response => {
                    if (200 == response.status) {
                        this.addUsersToEvaluation(evaluationCreated);
                    } else {
                        this.message = 'Error while adding weights to the evaluation'
                        this.successful = false
                    }
                },
                error => {
                    console.error((error.response && error.response.data) ||
                        error.message ||
                        error.toString())

                    this.message = 'There was an error while adding weights to the evaluation'
                    this.successful = false

                    if (error.response.status === 300) {
                        console.error('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );
        },
        addUsersToEvaluation(evaluationCreated) {
            const usersToAdd = [];
            if (this.selectedItems.length > 0) {
                this.selectedItems.forEach(user => {
                    let userToAdd = {
                        id_user: user.id,
                    }

                    usersToAdd.push(userToAdd)
                });

                SoftskillsDataService.addUsersToEvaluation(usersToAdd, evaluationCreated.id).then(
                    response => {
                        if (200 == response.status) {
                            // Añadir evaluadores
                            let evaluatorsToAdd = this.addEvaluators(evaluationCreated);

                            if (evaluatorsToAdd.length > 0) {
                                if (this.byGroups)
                                    this.addEvaluatorsToEvaluationWithGroups(evaluatorsToAdd, evaluationCreated);
                                else
                                    this.addEvaluatorsToEvaluationWithoutGroups(evaluatorsToAdd, evaluationCreated);
                            } else {

                                this.$router.push({ name: "softskills-configuration" });
                            }
                        } else {
                            this.message = 'Error while adding users to the evaluation'
                            this.successful = false
                        }
                    },
                    error => {
                        console.error((error.response && error.response.data) ||
                            error.message ||
                            error.toString())

                        this.message = 'There was an error while adding weights to the evaluation'
                        this.successful = false

                        if (error.response.status === 300) {
                            console.error('Logout: Token expired')
                            localStorage.removeItem('user');
                            localStorage.removeItem('company');
                            this.$store.state.auth.user = null
                            this.$store.dispatch('auth/logout');
                            this.$router.push('/login');
                        }
                    }
                );
            } else {
                // Añadir evaluadores sin usuarios
                let evaluatorsToAdd = this.addEvaluators(evaluationCreated);

                if (evaluatorsToAdd.length > 0) {
                    if (this.byGroups)
                        this.addEvaluatorsToEvaluationWithGroups(evaluatorsToAdd, evaluationCreated);
                    else
                        this.addEvaluatorsToEvaluationWithoutGroups(evaluatorsToAdd, evaluationCreated);
                } else {
                    this.$router.push({ name: "softskills-configuration" });
                }
            }
        },
        close() {
            this.message = "";
        },
        changedInputGroupSelected(event, group) {
            this.competencesByGroups.forEach(gr => {
                if (gr.id === group.id) {
                    if (gr.required) {
                        gr.competences.forEach(c => {
                            c.required = true
                        })
                    } else {
                        gr.competences.forEach(c => {
                            c.required = false
                        })
                    }
                }
            })

            this.$forceUpdate()
        },
        changedInputCompetenceSelected(event, competence) {
            if (!competence.required) competence.minLevel = 0
            this.$forceUpdate()
        },
        onRowSelected(items) {
            this.selectedItems = items;
        },
    }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>