<template>
	<div class="technologiesStyle my-3">
		<div id="message" v-if="message" class="alert" :class="successfull ? 'alert-success' : 'alert-danger'">
			{{message}} <b-button @click="close" class="cross-button"><font-awesome-icon  class="cross" icon="times" /> </b-button>
		</div>

		<template v-if="technologies.length >0">
			<div class="row" v-for="(technology, index) in technologies"  :key="technology.id">
				<div class="d-flex align-items-center" style="margin-bottom: 15px;">
					<img v-bind:src="logos[technology.name.toLowerCase()]" height="60em" class="analysis-card-avatar--circle" />
					<h5 class="profile-name-title">{{technology.name.toUpperCase()}}</h5>
					<button class="btn btn-secondary expand-button" type="button" v-b-toggle="'toggleT-' + index.toString()" @click="changeShowState(index)">
						<font-awesome-icon style="display: block;" v-bind:id="'iconShow-' + index.toString()" class="trash-icon" v-b-tooltip.hover title="Expand" icon="chevron-down" />
						<font-awesome-icon style="display: none;" v-bind:id="'iconNotShow-' + index.toString()" class="trash-icon" v-b-tooltip.hover title="Contract" icon="chevron-up"/>
					</button>
					<router-link v-b-tooltip.hover :title="'Ranking for ' + technology.name + ' profiles'" :to="{path: '/profile-ranking?id_profile=' + technology.id}" class="btn btn-secondary">
						<font-awesome-icon icon="trophy" />
					</router-link>
					<router-link v-b-tooltip.hover :title="'Upskilling and reskilling for ' + technology.name + ' profile'" :to="{path: '/professionalUpdating?id_profile=' + technology.id}" class="btn btn-secondary">
						<font-awesome-icon icon="cog" />
					</router-link>
					<b-dropdown id="dropdown-1"  
						variant="link"
						size="lg"
						toggle-class="text-decoration-none"
						no-caret
						right>
						<template slot="button-content">
							<font-awesome-icon class="trash-icon" icon="ellipsis-v" />
						</template>

						<b-dropdown-item @click="goToEditProfile(technology.id)"><font-awesome-icon icon="edit" /> Edit profile</b-dropdown-item>
						<!--<b-dropdown-item @click="gotoAfinityProfile(technology.id)"><font-awesome-icon icon="cog" /> Upskilling and reskilling</b-dropdown-item>-->
						<b-dropdown-item @click="showDeleteModal(technology.id, index)"><font-awesome-icon icon="trash" /> Delete profile</b-dropdown-item>
					</b-dropdown>
				</div>
				<b-collapse v-bind:id="'toggleT-' + index.toString()">
					<div class="row" v-for="(profileItem, index2) in technology.profile_levels" :key="profileItem.id">
						<div class="row profile-name-div">
							<div class="col-6 left-div">
								<h5 class="profile-name">{{profileItem.name}} Required Profile</h5>
							</div>
							<div class="col-6">
								<input class="btn btn-primary edit-button" type="button" value="Edit" @click="goToEdit(profileItem.level, technology.id)">
								<input class="btn btn-primary edit-button mx-2" type="button" value="COPY" @click="openCopyModal(technology, profileItem)">
							</div>
						</div>
						<hr>
						<div class="col-6">
							<h6 class="title">Certifications</h6>
							<div class="divItems" v-if="profileItem.certifications.length != 0">
								<div class="added-certifications" v-for="certificationsItem in profileItem.certifications" :key="certificationsItem.id" >
									<CertificationsCapsule :name="certificationsItem.certification.name" :item="certificationsItem" :del="del"/>
								</div>
							</div>
							<div class="divItems" v-else>
								<p>No Specific Certifications Requested.</p>
							</div>
							<h6 class="title">Education</h6>
							<div class="divItems" v-if="profileItem.profile_level_configuration.education.length != 0 && profileItem.profile_level_configuration.education.length != undefined">
								<div class="added-education" v-for="educationItem in profileItem.profile_level_configuration.education" :key="educationItem.id" >
									<EducationCapsule :name="educationItem.level" :item="educationItem" :del="del"/>
								</div>
							</div>
							<div class="divItems" v-else>
								<p>No education defined.</p>
							</div>
							<h6>Years of Experience</h6>
							<div class="divItems">
								<p v-if="profileItem.profile_level_configuration.experience_time_min_years.is_required" style="font-weight: bold; font-size: 17px;">Min. years: {{profileItem.profile_level_configuration.experience_time_min_years.value}}</p>
								<p v-else>Min. years: {{profileItem.profile_level_configuration.experience_time_min_years.value}}</p>

								<p v-if="profileItem.profile_level_configuration.experience_time_max_years.value != undefined && profileItem.profile_level_configuration.experience_time_max_years.value != null && profileItem.profile_level_configuration.experience_time_max_years.is_required" style="font-weight: bold; font-size: 17px;">Max. years: {{profileItem.profile_level_configuration.experience_time_max_years.value}}</p>
								<p v-if="profileItem.profile_level_configuration.experience_time_max_years.value != undefined && profileItem.profile_level_configuration.experience_time_max_years.value != null && !profileItem.profile_level_configuration.experience_time_max_years.is_required">Max. years: {{profileItem.profile_level_configuration.experience_time_max_years.value}}</p>

								<p v-if="profileItem.profile_level_configuration.experience_time_max_years.value == undefined || profileItem.profile_level_configuration.experience_time_max_years.value == null">Max. years: No limit</p>
							</div>
						</div>

						<div class="col-6 align-item-left">
							<h6>Technical Skills</h6>
							<template v-for="skill in profileItem.profile_level_configuration.skills">
								<div :key="skill.id" v-if="skill.is_tech == 1">
									<h6 class="subtitle title">{{capitalizarPrimeraLetra(skill.skill.replaceAll('_', ' '))}}</h6>
									<div class="divItems" v-if="skill.items.length != 0">
										<div class="added-frameworks" v-for="(item, index) in skill.items" :key="item.item + '-' + index" >
											<SkillsCapsule :name="item.item" :item="item" :del="del"/>
										</div>
									</div>
									<div class="divItems" v-else>
										<p>No {{capitalizarPrimeraLetra(skill.skill.replaceAll('_', ' '))}} defined.</p>
									</div> 
								</div>
							</template>
							<div class="divItems" v-if="!profileItem.hayTechnologySkills">
								<p>No Technical Skills defined.</p>
							</div>
							<h6>Non-Technical Skills</h6>
							<template v-for="skill in profileItem.profile_level_configuration.skills">
								<div :key="skill.id" v-if="skill.is_tech == 0">
									<h6 class="subtitle title">{{capitalizarPrimeraLetra(skill.skill.replaceAll('_', ' '))}}</h6>
									<div class="divItems" v-if="skill.items.length != 0">
										<div class="added-frameworks" v-for="(item, index) in skill.items" :key="item.item + '-' + index" >
											<SkillsCapsule :name="item.item" :item="item" :del="del"/>
										</div>
									</div>
									<div class="divItems" v-else>
										<p>No {{capitalizarPrimeraLetra(skill.skill.replaceAll('_', ' '))}} defined.</p>
									</div>
								</div>
							</template>
							<div class="divItems" v-if="!profileItem.hayNonTechnologySkills">
								<p>No Non-Technical Skills defined.</p>
							</div>
						</div>
						<hr v-if="index2 != (technology.profile_levels.length-1)">
					</div>
				</b-collapse>
				<hr class="thicker">
			</div>
		</template>
		<template v-else>
			<h3 class="no-results-msg"><font-awesome-icon icon="times-circle" class="black-color"/> No results found.</h3>
		</template>		

		<b-modal id="delete-modal" ref="delete-modal" title="Delete-modal" hide-footer>
			<template #modal-title>
				Delete profile
			</template>
			<div>
				<p>Do you really want to delete the selected profile? </p>
			</div>
			<input class="btn btn-primary form-submit" style="margin-right: 1%;" type="button" @click="$bvModal.hide('delete-modal')" value="No">
			<input class="btn btn-primary form-submit" type="button" @click="deleteItem()" value="Yes">
		</b-modal>


		<b-modal id="duplicate-modal" ref="duplicate-modal" title="Duplicate-modal" hide-footer>
			<template #modal-title>
				Duplicate
			</template>
			<div>
				<p>Where do you want to copy {{originProfileToCopy.name}} Required Profile? </p>
			</div>
			<div class="divItems full-width">
				<div class="buttons-to-copy" v-for="(profileName) in technologySelected.profile_levels" :key="profileName.id">
					<b-button v-if="profileName.name != originProfileToCopy.name" @click="copyProfileTo(technologySelected.id, originProfileToCopy.id, profileName.id)">{{profileName.name}}</b-button>
				</div>
			</div>
			<input class="btn btn-primary cancel-button w-25" type="button" value="Cancel" @click="$bvModal.hide('duplicate-modal')">
		</b-modal>
	</div>
</template>

<script>
	import {
		VsaList,
		VsaItem,
		VsaHeading,
		VsaContent,
		VsaIcon
	} from 'vue-simple-accordion'
	import 'vue-simple-accordion/dist/vue-simple-accordion.css'
	import ProfileConfigurationDataService from '@/services/ProfileConfigurationDataService';
	import ProfileAnalysisDataService from "@/services/ProfileAnalysisDataService";
	import FilterOptions from '@/components/Others/FilterOptions'; 
	import EducationCapsule from "@/components/Others/EducationCapsule.vue"
	import ProgrammingSkillsCapsule from "@/components/Others/ProgrammingSkillsCapsule.vue"
	import CertificationsCapsule from "@/components/Others/CertificationsCapsule.vue"
	import FrameworksCapsule from "@/components/Others/FrameworksCapsule.vue"
	import ToolsCapsule from "@/components/Others/ToolsCapsule.vue"
	import OthersCapsule from "@/components/Others/OthersCapsule.vue"
	import SkillsCapsule from "@/components/Others/SkillsCapsule.vue"

	export default {
		name: 'CompanyConfigurationComponent',
		data() {
			return {
				headers: [
					{ key: 'technology', label: 'Technology' },{ key: 'options', label: 'Options' }, 
				],
				technologies: [],
				profiles: [],
				message: "",
				perPage: 10,
				pageOptions: this.$paginationOptions,
				currentPage: 1,
				filter: null,
				successfull: false,
				del: false,
				selectedItem: null,
				selectedItemIndex: null,
				originProfileToCopy: [],
				destinyProfileToCopy: "",
				technologySelected: [],
				logos: {}
			}
	    },
	    components: {
	    	FilterOptions,
			EducationCapsule,
			FrameworksCapsule,
			ProgrammingSkillsCapsule,
			ToolsCapsule,
			OthersCapsule,
			CertificationsCapsule,
			SkillsCapsule,
			VsaList,
			VsaItem,
			VsaHeading,
			VsaContent,
			VsaIcon
	    },
	    props: {
	    },
		mounted() {
		   this.getData()
	  	},
	    computed: {
			rows() {
				return this.technologies.length
			}
	    },
	  	methods: {
			getData(){
				const currentArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
				ProfileConfigurationDataService.findLevelsConfigurationByArea(currentArea).then(
					response => {
						if (response.status == 200) {
							this.technologies = response.data
							this.technologies.forEach((tech) => {
								if (tech.logo !== undefined && tech.logo !== null) this.logos[tech.name.toLowerCase()] = tech.logo.includes(this.$awsBucketRoute) ? tech.logo : this.$awsBucketRoute + tech.logo
								else this.logos[tech.name.toLowerCase()] = ''
							});

							for (var i=0; i<this.technologies.length; i++ ){
								for (var j=0; j<this.technologies[i].profile_levels.length; j++ ){
									this.technologies[i].profile_levels[j].detailsShowing = false
									this.profiles.push(this.technologies[i].profile_levels[j])
									this.profiles[i*this.technologies[i].profile_levels.length + j].technology = this.technologies[i].name
									this.profiles[i*this.technologies[i].profile_levels.length + j].technology_id = this.technologies[i].id
								}
							}

							for (var k = 0; k < this.technologies.length; k++) {
								for(var x = 0; x < this.technologies[k].profile_levels.length; x++) {
									var skillsForTechnologyAndProfile = this.technologies[k].profile_levels[x].profile_level_configuration.skills
									var hayTechSkills = false
									var hayNonTechSkills = false

									for (var j = 0; j < skillsForTechnologyAndProfile.length; j++) {
										if(skillsForTechnologyAndProfile[j].is_tech == 1) {
											hayTechSkills = true
										} else {
											hayNonTechSkills = true
										}
									}

									if(hayTechSkills) {
										this.technologies[k].profile_levels[x].hayTechnologySkills = true
									} else {
										this.technologies[k].profile_levels[x].hayTechnologySkills = false
									}

									if(hayNonTechSkills) {
										this.technologies[k].profile_levels[x].hayNonTechnologySkills = true
									} else {
										this.technologies[k].profile_levels[x].hayNonTechnologySkills = false
									}
								}
							}
						}
					},
					error => {
						console.log((error.response && error.response.data) ||
							error.message ||
							error.toString())
						
						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
			    );
			},
			goToEdit(idProfileLevel, idProfile) {
				this.$router.push('/edit-config-profile?idProfile='+idProfile + '&idProfileLevel=' + idProfileLevel)
			},
			gotoAfinityProfile(idProfile){
				this.$router.push('/professionalUpdating?idProfile='+idProfile)
			},
			goToEditProfile(idProfile) {
				this.$router.push('/edit-profile?idProfile=' + idProfile)
			},
			close() {
				document.getElementById("message").style.display = "none"
			},
			capitalizarPrimeraLetra(str) {
				return str.charAt(0).toUpperCase() + str.slice(1)
			},
			showDeleteModal(itemId, index) {
				this.selectedItem = itemId
				this.selectedItemIndex = index
				this.$refs['delete-modal'].show();
			},
			changeShowState(index) {
				if(document.getElementById('iconShow-' + index.toString()).style.display == "block") {
					document.getElementById('iconShow-' + index.toString()).style.display = "none"
					document.getElementById('iconNotShow-' + index.toString()).style.display = "block"
				} else {
					document.getElementById('iconShow-' + index.toString()).style.display = "block"
					document.getElementById('iconNotShow-' + index.toString()).style.display = "none"
				}
			},
			deleteItem() {
				ProfileConfigurationDataService.delete(this.selectedItem).then(
					response => {
						this.$refs['delete-modal'].hide()
						this.technologies.splice(this.selectedItemIndex, 1)
						const currentArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
                        ProfileAnalysisDataService.recalculateProfiles(currentArea).then(
                            response => {
								console.log('')
                            },
                            error => {
                                console.log((error.response && error.response.data) ||
                                error.message ||
                                error.toString())
                                    
                                if (error.response.status === 300) {
                                    console.error('Logout: Token expired')
                                    localStorage.removeItem('user');
									localStorage.removeItem('company');
                                    this.$store.state.auth.user = null
                                    this.$store.dispatch('auth/logout');
                                    this.$router.push('/login');
                                }
                            }
                        );
					},
					error => {
						console.error((error.response && error.response.data) ||
							error.message ||
							error.toString())
						
						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				)
			},
			openCopyModal(idProfile, idOrigin){
				this.technologySelected = idProfile; 
				this.originProfileToCopy = idOrigin; 
				this.$refs['duplicate-modal'].show();
			},
			generateReport(){
			},
			copyProfileTo(idProfile, idOrigin, idDestiny){
				 ProfileConfigurationDataService.cloneProfileLevel(idProfile, idOrigin, idDestiny).then(
				 	response => {
						this.getData()
						this.$refs['duplicate-modal'].hide()
				 	},
				 	error => {
						this.$refs['duplicate-modal'].hide();
				 		console.log((error.response && error.response.data) ||
				 			error.message ||
				 			error.toString())

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
				 	}
				)
			}
	    }
	};
</script>

<style scoped>
.marginsTech{
	width: 75% !important;
	margin-left: 10%;
}

.thicker {
    border: none;
    height: 2px !important;
    color: black;
    background-color: black;
}

.caret-off::before {
    display: none !important;
}

.caret-off::after {
    display: none !important;
}

.technology-name {
	margin-bottom: 15px !important;
}

.profile-name-div {
	margin-bottom: 5px !important;
}

.left-div {
	position: relative;
}

.profile-name-title {
	margin-left: 2%;
	font-weight: bolder;
	font-size: 24px;
}

.profile-name {
	position: absolute;
	bottom: 0;
	margin-bottom: 0px !important;
}

.edit-button {
	float: right;
}

.cancel-button {
	float: center;
	margin-left: 5%;
}

.icon-profile {
	padding-right: 0px !important;
}

.title {
	margin-bottom: 0px !important;
}

.subtitle {
	margin-left: 15px;
}

.added-education, .added-frameworks, .added-programming-skills, .added-other-skills, .added-tools, .added-certifications{
	margin-top: 1em;
	display: inline-block;
}

.divItems {
    text-align: left;
    margin-left: 25px;
    margin-right: 25px;
	margin-bottom: 1em;
}

.vsa-list {
	--vsa-border-color: white !important;
	--vsa-highlight-color: #4FC0E8 !important;
}

.expand-button {
	margin-left: auto;
 }


.btn-link {
    font-weight: 600 !important;
    color: #FFFF !important;
}

.cancelColor{
	color: rgb(73, 71, 71) !important;
}

.buttons-to-copy {
  float: left;
}
</style>