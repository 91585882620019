<template>
	<div class="col-sm-12">
		<div class="row secondNavbar">
			<div class="col-5 links-container">
				<router-link :to="{ path: '/settings-profiles' }" class="btn btn-secondary float-right"><font-awesome-icon
						icon="arrow-left" /> Back to profiles settings</router-link>
				<router-link to="/create-profile" class="btn btn-secondary float-right createClass"><font-awesome-icon
						icon="plus" /> CREATE PROFILE</router-link>
			</div>
			<div class="col-7 noMargin whiteColor">
				<router-link :to="{ path: '/settings' }" class="breadcumb"> Settings > </router-link>
				<router-link :to="{ path: '/settings-profiles' }" class="breadcumb"> Profiles settings > </router-link>
				<router-link :to="{ path: '/config-profile' }" class="final-breadcumb"> Configuration of Profiles
				</router-link>
			</div>
		</div>

		<div class="col-sm-12 col-centered">
			<div id="message" v-if="updateMessage"
				:class="updateFlag ? 'message alert alert-success' : 'message alert alert-danger'">
				{{ updateMessage }} <b-button @click="close" class="cross-button"><font-awesome-icon class="cross"
						icon="times" /> </b-button>
			</div>
			<div id="message" v-if="createMessage"
				:class="createFlag ? 'message alert alert-success' : 'message alert alert-danger'">
				{{ createMessage }} <b-button @click="close" class="cross-button"><font-awesome-icon class="cross"
						icon="times" /> </b-button>
			</div>
			<company-configuration />
		</div>
	</div>
</template>

<script>
import CompanyConfiguration from "@/components/AnalysisComponents/CompanyConfiguration.vue";

export default {
	name: 'ProfileConfiguration',
	data() {
		return {
			title: "Settings > Configuration of Profile"
		}
	},
	components: {
		CompanyConfiguration
	},
	props: {
		updateMessage: {
			type: String,
			default: ""
		},
		updateFlag: {
			type: Boolean,
			default: false
		},
		createMessage: {
			type: String,
			default: ""
		},
		createFlag: {
			type: Boolean,
			default: false
		}
	},
	methods: {
	}
};
</script>

<style scoped>
#app>.bg-white .main-container {
	max-width: 100%;
	float: none;
	padding-left: 0px !important;
	padding-right: 0px !important;
	padding-top: 0px !important;
}

/* .createClass{
    background: border-box;
    border-radius: 26px;
    padding: inherit;
    margin-top: 0.6%;
	padding-right: 7px;
    padding-left: 7px;
} */
</style>